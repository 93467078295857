import React from "react";
import IntegrationLayout from "../layout/SecurityLayout";

const SecurityOne = () => {
  return (
    <IntegrationLayout
      title="Cloud environment"
      description="Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna."
    />
  );
};

export default SecurityOne;
