import React from "react";
import "../../../../../resources/styles/components/success-stories-components/startup-nights-video.css";
import MediaComp from "../../common/media-comp-video/MediaComp";

const StartupNightsVideo = () => {
  return (
    <div className="startup-nights-video-container">
      <div className="startup-nights-video-wrapper">
        <MediaComp className="startup-nights-video" />
      </div>
    </div>
  );
};

export default StartupNightsVideo;
