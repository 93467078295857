import React, { useState } from "react";
import "./style.css";

const TextForm = () => {
  const [firma, setFirma] = useState("");

  const handleChange = (e: any) => {
    setFirma(e.target.value);
  };

  return (
    <form className="test-form">
      <div className="form-group">
        <div className="input-container">
          <span className={`placeholder ${firma ? "active" : ""}`}>Company</span>
          <input type="text" className="contact-form-firma inp" name="firma" placeholder="Company" value={firma} onChange={handleChange} />
        </div>
      </div>
    </form>
  );
};

export default TextForm;
