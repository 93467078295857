import React from "react";
import AppLayout from "../../layout/AppLayout";
import images from "../../../../../../resources/images";

const VotingOptThree = () => {
  const title = "Eigene Votings erstellen";
  const description =
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.";

  return (
    <div className="voting-opt-three-container">
      <div className="voting-opt-three-wrapper">
        <div className="voting-opt-three-img-wrapper">
          <p>Change content here</p>
          <img src={images.changeContImg} alt="voting-opt-three" />
        </div>
        <div className="voting-opt-three-text-wrapper">
          <AppLayout title={title} description={description} />
        </div>
      </div>
    </div>
  );
};

export default VotingOptThree;
