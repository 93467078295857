import React from "react";
import "../../../../resources/styles/components/button.css";

interface CommonButtonProps {
  className?: string;
  style?: React.CSSProperties;
  textOne?: string;
  textTwo?: string;
  type?: "submit" | "reset" | "button" | undefined;
  onClick?: () => void;
}

const commonButtonClassName = "common-btn";

const CommonButton = ({ className, style, textOne, type, onClick }: CommonButtonProps) => {
  if (type === "button") {
    return (
      <button type="button" className={`${commonButtonClassName} ${className}`} style={style}>
        {textOne}
      </button>
    );
  } else {
    return (
      <button type={type} className={`${commonButtonClassName} ${className}`} style={style} onClick={onClick}>
        {textOne}
      </button>
    );
  }
};

export default CommonButton;
