import React from "react";

const SecurityIntegrate = () => {
  const title = "How we design security.";
  const description =
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. (Single & Multi Votings)";

  return (
    <div className="security-integrate-container">
      <div className="security-integrate-wrapper">
        <h2 className="security-integrate-title">{title}</h2>
        <p className="security-integrate-description">{description}</p>
      </div>
      <div className="security-integrate-image-wrapper"></div>
    </div>
  );
};

export default SecurityIntegrate;
