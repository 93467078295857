import React from "react";
import UseCases from "../components/basicComponents/squib-2024/use-cases/UseCases";

const UseCasesPage = () => {
  return (
    <div>
      <UseCases />
    </div>
  );
};

export default UseCasesPage;
