import React from "react";
import images from "../../../../../../assets/images";
interface IFeedBackContentProps {
  titleOne: string;
  titleTwo: string;
  titleThree: string;
  titleFour: string;
  subTitleOne: string;
  subTitleTwo: string;
  subTitleThree: string;
  subTitleFour: string;
}

const FeedBackContent = (props: IFeedBackContentProps) => {
  return (
    <div className="content-container">
      <div className="first-row">
        <div className="fb-content">
          <svg xmlns="http://www.w3.org/2000/svg" width="76" height="78" viewBox="0 0 76 78" fill="none">
            <rect
              x="23.6143"
              y="17.2351"
              width="66.8014"
              height="11.8765"
              rx="5.93823"
              transform="rotate(45 23.6143 17.2351)"
              stroke="#2A2A2A"
              strokeWidth="6"
            />
            <path d="M23 33.8389L31.8388 25" stroke="#2A2A2A" strokeWidth="6" />
            <path d="M8 2V14" stroke="#2A2A2A" strokeWidth="4" strokeLinecap="round" />
            <path d="M2 8H14" stroke="#2A2A2A" strokeWidth="4" strokeLinecap="round" />
            <path d="M54 10V22" stroke="#2A2A2A" strokeWidth="4" strokeLinecap="round" />
            <path d="M48 16H60" stroke="#2A2A2A" strokeWidth="4" strokeLinecap="round" />
            <path d="M16 49V61" stroke="#2A2A2A" strokeWidth="4" strokeLinecap="round" />
            <path d="M10 55H22" stroke="#2A2A2A" strokeWidth="4" strokeLinecap="round" />
          </svg>
          <h2 className="why-squib-feedback-title">{props.titleOne}</h2>
          <p className="why-squib-feedback-subtitle" style={{ width: "100%" }}>
            {props.subTitleOne}
          </p>
        </div>
        <div className="fb-content">
          <svg xmlns="http://www.w3.org/2000/svg" width="94" height="75" viewBox="0 0 94 75" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 6H40C42.2091 6 44 7.79086 44 10V65C44 67.2091 42.2091 69 40 69H10C7.79086 69 6 67.2091 6 65V10C6 7.79086 7.79086 6 10 6ZM47 72.1414C45.1963 73.9097 42.7255 75 40 75H10C4.47715 75 0 70.5229 0 65V10C0 4.47715 4.47715 0 10 0H40C42.7255 0 45.1962 1.09032 47 2.85857C48.8038 1.09032 51.2745 0 54 0H84C89.5228 0 94 4.47715 94 10V65C94 70.5229 89.5228 75 84 75H54C51.2745 75 48.8037 73.9097 47 72.1414ZM50 10V65C50 67.2091 51.7909 69 54 69H84C86.2091 69 88 67.2091 88 65V10C88 7.79086 86.2091 6 84 6H54C51.7909 6 50 7.79086 50 10Z"
              fill="#2A2A2A"
            />
            <text x="12" y="50" fill="#2A2A2A" fontSize={38} fontWeight="bold" fontFamily="PublicaSans-Bold" className="icon-letters">
              A
            </text>
            <text x="58" y="50" fill="#2A2A2A" fontSize={38} fontWeight="bold" fontFamily="PublicaSans-Bold" className="icon-letters">
              B
            </text>
          </svg>
          {/*<img src={images.abIcon} alt="ab-icon" width={38} />*/}
          <h2 className="why-squib-feedback-title">{props.titleTwo}</h2>
          <p className="why-squib-feedback-subtitle" style={{ width: "100%" }}>
            {props.subTitleTwo}
          </p>
        </div>
      </div>
      <div className="second-row">
        <div className="fb-content">
          <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none">
            <rect x="3" y="3" width="69" height="69" rx="12" stroke="#2A2A2A" strokeWidth="6" />
            <path
              d="M21 55.5V58.5H27V55.5H21ZM27 31C27 29.3431 25.6569 28 24 28C22.3431 28 21 29.3431 21 31H27ZM27 55.5V31H21V55.5H27Z"
              fill="#2A2A2A"
            />
            <path
              d="M34 55.5V58.5H40V55.5H34ZM40 18C40 16.3431 38.6569 15 37 15C35.3431 15 34 16.3431 34 18H40ZM40 55.5V18H34V55.5H40Z"
              fill="#2A2A2A"
            />
            <path
              d="M47 55.5V58.5H53V55.5H47ZM53 38.5C53 36.8431 51.6569 35.5 50 35.5C48.3431 35.5 47 36.8431 47 38.5H53ZM53 55.5V38.5H47V55.5H53Z"
              fill="#2A2A2A"
            />
          </svg>
          <h2 className="why-squib-feedback-title">{props.titleThree}</h2>
          <p className="why-squib-feedback-subtitle">{props.subTitleThree}</p>
        </div>
        <div className="fb-content">
          <svg xmlns="http://www.w3.org/2000/svg" width="53" height="69" viewBox="0 0 53 69" fill="none">
            <path d="M10 20C10 10.6112 17.6112 3 27 3C36.3888 3 44 10.6112 44 20V26H10V20Z" stroke="#2A2A2A" strokeWidth="6" />
            <rect x="3" y="26" width="47" height="40" rx="3" stroke="#2A2A2A" strokeWidth="6" />
            <circle cx="27" cy="46" r="6.5" fill="#2A2A2A" stroke="#2A2A2A" />
          </svg>
          <h2 className="why-squib-feedback-title">{props.titleFour}</h2>
          <p className="why-squib-feedback-subtitle">{props.subTitleFour}</p>
        </div>
      </div>
    </div>
  );
};

export default FeedBackContent;
