import React from "react";
import TestimonialsLayout from "../../../common/testimonials-layout-comp/TestimonialsLayout";
import images from "../../../../../../resources/images";
import { useTranslation } from "react-i18next";

const TestimonialOne = () => {
  const { t } = useTranslation("translation");
  const description = t("benedictParagraph", { ns: "translation" });
  const websiteLink = "www.benedict.ch";
  const name = "Thomas Strub";
  const title = "Schulleiter und Geschäftsführer";
  return (
    <TestimonialsLayout
      brandLogo={images.benedictPng}
      logoConfig="benedict-logo"
      description={description}
      websiteLink={websiteLink}
      avatar={images.thomas}
      authorName={name}
      authorTitle={title}
      membersInfoClass="benedict-member-info"
    />
  );
};

export default TestimonialOne;
