import React from "react";
import TestimonialOne from "./testimonial-one/TestimonialOne";
import TestimonialTwo from "./testimonial-two/TestimonialTwo";
import TestimonialThree from "./testimonial-three/TestimonialThree";
import TestimonialFour from "./testimonial-four/TestimonialFour";
import "../../../../../resources/styles/components/testimonials.css";

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <div className="testimonials-wrapper">
        <TestimonialOne />
        <TestimonialTwo />
      </div>
      <div className="testimonials-wrapper">
        <TestimonialThree />
        <TestimonialFour />
      </div>
    </div>
  );
};

export default Testimonials;
