import React from "react";
import FunctionalitiesComp from "../components/basicComponents/squib-2024/functionalities/Functionalities";

const Functionalities = () => {
  return (
    <div>
      <FunctionalitiesComp />
    </div>
  );
};

export default Functionalities;
