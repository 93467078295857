import React from "react";
import "../../../../../resources/styles/components/contact-components/contact-header-text.css";
import { useTranslation } from "react-i18next";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";

const ContactHeaderText = () => {
  const { t } = useTranslation("translation");
  const contactHeader = t("contactTitle", { ns: "translation" });
  const contactSubTitle = t("contactSubTitle", { ns: "translation" });

  return (
    <div className="contact-header-container">
      <div className="contact-header-wrapper">
        <HeaderTitleComp title={contactHeader} subTitle={contactSubTitle} />
      </div>
    </div>
  );
};

export default ContactHeaderText;
