import React from "react";
import "../../../../../../resources/styles/components/functionalities-components/untitled.css";
import check from "../../../../../../resources/images/checkb.png";
import images from "../../../../../../resources/images";

interface UntitledContentProps {
  valueOne: string;
  valueTwo: string;
  valueThree: string;
  valueFour: string;
}

const UntitledContent = (props: UntitledContentProps) => {
  return (
    <div className="untitled-content-container">
      <div className="check-box-untitle">
        <img src={images.checkBlack} alt="check" />
        <p>{props.valueOne}</p>
      </div>
      <div className="check-box-untitle">
        <img src={images.checkBlack} alt="check" />
        <p>{props.valueTwo}</p>
      </div>
      <div className="check-box-untitle">
        <img src={images.checkBlack} alt="check" />
        <p>{props.valueThree}</p>
      </div>
      <div className="check-box-untitle">
        <img src={images.checkBlack} alt="check" />
        <p>{props.valueFour}</p>
      </div>
    </div>
  );
};

export default UntitledContent;
