import React from "react";
import PricingFeaturesPlanLayout from "../layout/PricingFeaturesPlanLayout";

const PlanOne = () => {
  return (
    <>
      <PricingFeaturesPlanLayout
        headerPlanVersions={["Lite"]}
        headerPlanPrice={["$0"]}
        planOptions={["Create Squib Votings™(with Image & Text)", "Sharing"]}
        btnText="create a voting"
        posibleAccess="No registration"
      />
    </>
  );
};

export default PlanOne;
