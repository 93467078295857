import React from "react";
import FuntianalitiesHeaderText from "./functionalities-header-text/FuntionalitiesHeaderText";
import FunctionalitiesMainContent from "./functionalities-main-content/FuntionalitiesMainContent";
import WhySquibFeedBack from "./why-squib-feedback/WhySquibFeedBack";
import Untitled from "./untitled/Untitled";
import FAQ from "./faq/FAQ";
import GetInTouchFunctionalities from "./getintouch-funtionalities/GetInTouchFunctionalities";
import MetaData from "../common/metadata/MetaData";
import { useTranslation } from "react-i18next";
import "../../../../resources/styles/pages/functionalities.css";
import { metadataContent } from "../common/metadata/content";

const FunctionalitiesComp = () => {
  const { t } = useTranslation("translation");

  const explore = t("explore", { ns: "translation" });
  const exploreText = t("exploreText", { ns: "translation" });
  const exploreSubText = t("exploreSubText", { ns: "translation" });
  const advantageTitle = t("advantageTitle", { ns: "translation" });
  const funcQuestion = t("funcQuestion", { ns: "translation" });
  const funcCallToActionText = t("funcCallToActionText", { ns: "translation" });

  return (
    <>
      <MetaData
        title={explore}
        german={metadataContent.metadataGermanContentExplore}
        english={metadataContent.metadataEnglishContentExplore}
      />
      <div className="functionalities-comp-container">
        <FuntianalitiesHeaderText />
        <FunctionalitiesMainContent title={exploreText} subTitle={exploreSubText} />
        <WhySquibFeedBack />
        <Untitled title={advantageTitle} />
        <FAQ />
        <GetInTouchFunctionalities title={funcQuestion} callToActionBtn={funcCallToActionText} />
      </div>
    </>
  );
};

export default FunctionalitiesComp;
