import React from "react";
import CustomButton from "../../../../CustomButton";
import { Link } from "react-router-dom";

const SignupForm = () => {
  return (
    <div className="signup-form-container">
      <div className="signup-form-wrapper">
        <h1>Sign-up for Squib Pro</h1>
        <form>
          <input type="text" placeholder="Email" />
          <input type="text" placeholder="Password" />
        </form>
        <div className="signup-checkbox">
          <input type="checkbox" className="signup-check-box" />
          <label htmlFor="checkbox">
            I agree to the <span>Terms of Service </span>and <span>Privacy Policy.</span>
          </label>
        </div>
        <div className="create-account-wrapper">
          <Link to="/thanks-signup" style={{ textDecoration: "none" }}>
            <CustomButton btnText="Create my free account" style={{ fontSize: "20px" }} />
          </Link>
          <div className="set-account-wrapper">
            Already have an account?{" "}
            <Link to="/login" style={{ color: "#ff517b" }}>
              <span>Login here!</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
