import React from "react";
import "../../../../../resources/styles/components/useCases-components/customers-feedback.css";
import CardUseCasesLayout from "../../common/card-layout/CardUseCasesLayout";
import Reveal from "../../common/reveal-animation/Reveal";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import images from "../../../../../resources/images";
interface CustomersFeedbackProps {
  title: string;
  subTitle: string;
  brandHeader?: string;
  brandImage?: string;
}

const CustomersFeedback = (props: CustomersFeedbackProps) => {
  const { t } = useTranslation("translation");
  const cardOneHeader = t("useCasesFirstCardHeader", { ns: "translation" });
  const cardOneText = "Okay";
  const cardTwoText = t("cardOneUseCasesTwoText", { ns: "translation" });
  const spanTextOne = "80/80";
  const spanTextTwo = "80/80";

  return (
    <>
      <div className="customers-feedback-container fading-scroll-animation">
        <Reveal>
          <motion.div
            initial={{ opacity: 0, y: 90 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
            className="customers-feedback-wrapper"
          >
            <h1>{props.title}</h1>
            <p>{props.subTitle}</p>
            <p>{props.brandHeader}</p>
            <a href="https://www.benedict.ch" target="_blank" rel="noopener noreferrer">
              <img src={props.brandImage} alt="brand" className="benedict-brand-img" />
            </a>
          </motion.div>
        </Reveal>

        <img src={images.example1} alt="mobile voting card one" className="mobile-voting-card-one" />

        {/*<CardUseCasesLayout
          id="usesCasesID"
          className="inner-card-one-text"
          headerTextStyles="header-text-styles-cf"
          backgroundCardOne="card-one"
          backgroundCardTwo="card-two"
          thumbUpImage="thumb-up"
          thumbDownImage="thumb-down"
          cardHeaderTitle="Bussines Skills"
          cardHeaderText={cardOneHeader}
          cardOneText={cardOneText}
          cardTwoText={cardTwoText}
          spanTextOne={spanTextOne}
          spanTextTwo={spanTextTwo}
          imageUrl={images.funnyA}
          imageUrlTwo={images.funnyB}
          numWrapper="num-wrapper"
          numWrapperTwo="num-wrapper-two-se"
          funnyImgOneCardClassWrapper="funny-img-one-card-wrapper"
          funnyImgOneCardClass="funny-img-one-card"
          funnyImgTwoCardClass="funny-img-two-card"
          classLines={["line-one", "line-two", "line-three", "line-four", "line-five", "line-six", "line-seven", "line-eight"]}
          brandLogo={images.benedictSvg}
        /> */}
      </div>
    </>
  );
};

export default CustomersFeedback;
