import React from "react";
import "../../../../../resources/styles/components/terms-of-use-components/terms-of-use-header.css";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";
import { useTranslation } from "react-i18next";
import { nutzungsbedingungenTranslationVariables } from "../../../../../helpers/translationVariables";

const TermsOfUseHeader = () => {
  const { t } = useTranslation("translation");
  const titleTermsMobile = "Nutzungs-\nbedingungen";

  const translation = nutzungsbedingungenTranslationVariables({ t });

  return (
    <div className="terms-of-use-header-container">
      <div className="terms-of-use-header-wrapper">
        <HeaderTitleComp className="header-terms-title-mobile-none" title={translation.touTitle} titleMobile={translation.touMobileTitle} />
      </div>
    </div>
  );
};

export default TermsOfUseHeader;
