export default interface MetadataContent {
  metadataGermanContentHome?: string;
  metadataGermanContentExplore?: string;
  metadataGermanContentUseCases?: string;
  metadataGermanContentContact?: string;
  metadataGermanContentImprintPrivacy?: string;
  metadataEnglishContentHome?: string;
  metadataEnglishContentExplore?: string;
  metadataEnglishContentUseCases?: string;
  metadataEnglishContentContact?: string;
  metadataEnglishContentImprintPrivacy?: string;
}

export const metadataContent: MetadataContent = {
  metadataGermanContentHome:
    "Squib - die einfache und zuverlässige Art, Feedback zu erhalten. Erstelle attraktive Umfragen, mit denen du garantiert und schnell Feedback erhältst. Einfach clever. Professionell. Mit deinem Logo. Jetzt kostenlos testen.",
  metadataGermanContentExplore:
    "Weshalb Squib? Mit Squib holst du erfolgreicher Feedback ein als bisher. Dank einzigartiger Nutzerfreundlichkeit, welche die Zielgruppen zur Teilnahme motiviert.",
  metadataGermanContentUseCases:
    "Squib - Use Cases Alles geht: Mitarbeitende miteinbeziehen, Kunden befragen, Schüler um Feedback bitten, Online-Community involvieren etc.",
  metadataGermanContentContact:
    "Squib - Jetzt Kontakt aufnehmen. Kontaktformular für alle deine Fragen, für Feedback und für Stakeholder Management.",
  metadataGermanContentImprintPrivacy:
    "Squib - Impressum und Datenschutz Wir nehmen den Schutz deiner persönlichen Daten sehr ernst! Hinweise zum Datenschutz, zu individuellen Rechten sowie Dienste von Drittanbietern.",

  metadataEnglishContentHome:
    "Squib - the easy and reliable way to get feedback. Create attractive surveys with which you are guaranteed to get feedback quickly. Simply clever. Professional. With your logo. Try it now for free.",
  metadataEnglishContentExplore:
    "Why Squib? With Squib you collect feedback more successfully than before. Thanks to unique user-friendliness that motivates target groups to participate.",
  metadataEnglishContentUseCases:
    "Squib - Use Cases Anything goes: involve employees, survey customers, ask students for feedback, involve online community, etc.",
  metadataEnglishContentContact: "Squib - Contact Now. Contact form for all your questions, feedback and stakeholder management.",
  metadataEnglishContentImprintPrivacy:
    "Squib - Imprint and Privacy We take the protection of your personal data very seriously! Privacy notices, individual rights, and third-party services.",
};
