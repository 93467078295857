import React from "react";
import { Link } from "react-router-dom";
import "../../../../../resources/styles/components/functionalities-components/get-in-touch.css";
import CustomButtonTwo from "../../../CustomButtonTwo";

interface IGetInTouchProps {
  title?: string;
  callToAction?: string;
  headerOwnStyle?: string;
}

const GetInTouch = (props: IGetInTouchProps) => {
  //const { t } = useTranslation("translation");
  //const title = t("anyQuestion", { ns: "translation" });
  //const callToAction = t("getInTouchBtn", { ns: "translation" });

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="get-in-touch-container">
      <div className={`get-in-touch-wrapper ${props.headerOwnStyle}`}>
        <h1>{props.title}</h1>
        <div className="cbtn-wrapper">
          <Link to="/kontakt" onClick={handleScrollToTop}>
            <CustomButtonTwo btnText={props.callToAction} className="btn-get-in-touch" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
