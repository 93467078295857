import React, { useState } from "react";
import "../assets/final-slide.css";
import Lottie from "./animations/LottieAnim";

type FinalSlideType = {
  showFinalAnim: boolean;
  goBack: () => void;
  goNext: () => void;
  goToSlideOne: () => void;
  areaWidth?: number;
};

const FinalSlide = (props: FinalSlideType) => {
  const [startPosition, setStartPosition] = useState<number | null>(null);
  const [endPosition, setEndPosition] = useState<number | null>(null);

  const minSwipeDistance = 50;
  const onDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
    setEndPosition(null);
    setStartPosition(e.clientX);
  };
  let isLeftDrag: boolean;
  let isRightDrag: boolean;

  const onDragMove = (e: React.MouseEvent<HTMLDivElement>) => {
    setEndPosition(e.clientX);
    if (!startPosition || !endPosition) return;
    const distance = startPosition - endPosition;
    isLeftDrag = distance > 10;
    isRightDrag = distance < -10;
  };
  const onDragEnd = () => {
    if (isLeftDrag) {
      props.goNext();
    }
    if (isRightDrag) {
      props.goBack();
    }
  };
  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setEndPosition(null);
    setStartPosition(e.targetTouches[0].clientX);
  };
  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setEndPosition(e.targetTouches[0].clientX);
    if (!startPosition || !endPosition) return;
    const distance = startPosition - endPosition;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      props.goToSlideOne();
    }
    if (isRightSwipe) {
      props.goBack();
    }
  };

  return (
    <div
      className="final-slide-dev"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onMouseDown={onDragStart}
      onMouseMove={onDragMove}
      onMouseUp={onDragEnd}
      style={props.areaWidth ? { height: "200px", marginTop: "30%" } : {}}
    >
      {props.showFinalAnim && (
        <>
          <div className="lottie-container">
            <Lottie name={"explosion"} speed={1.5} />
          </div>
          <p className="Thanks-text">Thanks!</p>
        </>
      )}
    </div>
  );
};

export default FinalSlide;
