import React from "react";

const ThanksSignUp = () => {
  return (
    <div className="thanks-signup-container">
      <div className="thanks-signup-wrapper">
        <h1>Thank you for signing up!</h1>
        <p>
          Please confirm your registration that was just sent to <strong>xxx@xxx.com</strong>
        </p>
      </div>
    </div>
  );
};

export default ThanksSignUp;
