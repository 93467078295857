import React from "react";
import "../../resources/styles/components/button.css";

interface CustomButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  fixedWidthPx?: number;
  fixedWidthInPercentage?: number;
  fixedWidthInRem?: number;
  disableRowWrap?: boolean;
  wrapperStyle?: React.CSSProperties;
  btnText?: string | undefined;
}

function CustomButtonThree(props: CustomButtonProps) {
  const rowWrapStyle: React.CSSProperties = props.disableRowWrap
    ? { whiteSpace: "nowrap" }
    : {
        whiteSpace: "normal",
      };

  const widthStyle: React.CSSProperties = props.fixedWidthInPercentage
    ? { width: `${props.fixedWidthInPercentage}%` }
    : props.fixedWidthPx
    ? { width: `${props.fixedWidthPx}px` }
    : {};

  const widthStyleInRem: React.CSSProperties = props.fixedWidthInRem ? { width: `${props.fixedWidthInRem}rem` } : {};

  const btnText = props.btnText;

  return (
    <div className={`squib-button3`} style={{ ...props.style }}>
      <button style={{ ...widthStyle, ...rowWrapStyle, ...widthStyleInRem }} {...props}>
        {props.children} {btnText}
      </button>
    </div>
  );
}

export default CustomButtonThree;
