import React from "react";
import MetaData from "../common/metadata/MetaData";
import IntegrationHeader from "./integration-header/IntegrationHeader";
import SquibIntegrate from "./squib-integrate/SquibIntegrate";
import IntegrationOptions from "./integration-options/IntegrationOptions";
import GetInTouchIntegrations from "./getintouch-integrations/GetInTouchIntegrations";
import "../../../../resources/styles/components/integration-components/integrations.css";
import { useTranslation } from "react-i18next";

const Integrations = () => {
  const { t } = useTranslation("translation");
  const mdText = t("integrations", { ns: "translation" });

  return (
    <>
      <MetaData title={mdText} />
      <IntegrationHeader />
      <SquibIntegrate />
      <IntegrationOptions />
      <div style={{ marginTop: "10rem" }}>
        <GetInTouchIntegrations />
      </div>
    </>
  );
};

export default Integrations;
