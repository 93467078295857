import React from "react";
import VotingOptOne from "./option-one/VotingOptOne";
import VotingOptTwo from "./option-two/VotingOptTwo";
import VotingOptThree from "./option-three/VotingOptThree";

const VotingOptions = () => {
  return (
    <div className="voting-app-opt-container">
      <VotingOptOne />
      <VotingOptTwo />
      <VotingOptThree />
    </div>
  );
};

export default VotingOptions;
