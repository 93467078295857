import React from "react";
import GoogleMaps from "../../common/google-maps/GoogleMaps";
import "../../../../../resources/styles/components/contact-components/contact-comp.css";
import images from "../../../../../resources/images";

interface ContactProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  contactHeader?: string;
  title: string;
  subTitle: string;
  startup: string;
  place: string;
  desc: string;
  street: string;
  zip: string;
  tel?: string;
  email?: string;
  country?: string;
  className?: string;
  className2?: string;
  classMap?: string;
}

function Address(props: ContactProps) {
  return (
    <div className="contact-info">
      <div className="contact-header">
        <h2 className={`contact-header-text ${props.className}`}>{props.contactHeader}</h2>
        <div className={`address-text ${props.className2}`}>
          <p className="contact-info-header">{props.startup}</p>
          <p className="contact-info-place">c/o Hochschule Luzern – Wirtschaft </p>
          <p className="contact-info-desc">{props.desc}</p>
          <p className="contact-info-street">{props.street}</p>
          <p className="contact-info-zip">{props.zip}</p>
          <p className="contact-info-country">{props.country}</p>
          {/*<p className="contact-info-tel">{props.tel}</p> */}
        </div>
        {/*<a href="mailto:hello@squib.app" target="_blank" className="contact-info-email" rel="noreferrer">
          {props.email}
        </a> */}
        <a href="mailto:hello@squib.app" target="_blank" className="contact-info-email" rel="noreferrer">
          <img src={images.emailIcon} alt="email icon" className="email-icon" />
        </a>
        <a href="http://linkedin.com/company/squib-ltd/" target="_blank" rel="noreferrer">
          <img src={images.linkedinIcon} alt="Linkedin icon" className="linkedin-icon" />
        </a>
      </div>
      <div className={`squib-map ${props.classMap}`}>
        <GoogleMaps />
      </div>
    </div>
  );
}

export default Address;
