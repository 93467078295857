import React from "react";
import TestimonialsSuccessStoriesLayout from "../../../common/testimonials-layout-comp/TestimonialsLayout";
import images from "../../../../../../resources/images";

const TestimonialOne = () => {
  return (
    <TestimonialsSuccessStoriesLayout
      brandLogo={images.startUpNights}
      description="«Direkt am Event die Teilnehmenden auf einfache Art zu befragen, war für uns ein grosser Mehrwert. Die Rücklauf-Quote war dadurch höher und wir konnten gezielt in verschiedenen Bereichen nach der Meinung fragen.»"
      avatar={images.raphael}
      authorName="Raphael"
      authorTitle="Head Partnerships"
    />
  );
};

export default TestimonialOne;
