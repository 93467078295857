import React from "react";
import TermsOfUse from "../components/basicComponents/squib-2024/terms-of-use/TermsOfUse";

const TermsOfUsePage = () => {
  return (
    <div>
      <TermsOfUse />
    </div>
  );
};

export default TermsOfUsePage;
