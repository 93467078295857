import React from "react";
import MetaData from "../common/metadata/MetaData";
import IntegrationHeader from "./security-header/SecurityHeader";
import SecurityIntegrate from "./security-integrate/SecurityIntegrate";
import IntegrationOptions from "./security-options/SecurityOptions";
import HandlePrivacy from "./handle-privacy/HandlePrivacy";
import GetInTouchIntegrations from "./getintouch-security/GetInTouchSecurity";
import "../../../../resources/styles/components/security-components/security.css";
import { useTranslation } from "react-i18next";

const Security = () => {
  const { t } = useTranslation("translation");
  const mdText = t("integrations", { ns: "translation" });

  return (
    <>
      <MetaData title={mdText} />
      <IntegrationHeader />
      <SecurityIntegrate />
      <IntegrationOptions />
      <HandlePrivacy />
      <div style={{ marginTop: "10rem" }}>
        <GetInTouchIntegrations />
      </div>
    </>
  );
};

export default Security;
