import React from "react";
import ContactUs from "../components/basicComponents/squib-2024/contact/ContactUs";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation("translation");
  const contactHeader = t("contactAddressHeader", { ns: "translation" });
  const title = t("contactTitle", { ns: "translation" });
  const subTitle = t("contactSubTitle", { ns: "translation" });
  const startup = "Squib Ltd.";
  const place = t("contactPlace", { ns: "translation" });
  const desc = "Smart-up";
  const street = "Zentralstrasse 9";
  const zipCity = "6002 Luzern";
  const tel = "+41 41 511 46 76";
  const email = "hello@squib.app";
  const countryS = "Schweiz";

  return (
    <>
      <ContactUs
        contactHeader={contactHeader}
        title={title}
        subTitle={subTitle}
        startup={startup}
        place={place}
        desc={desc}
        street={street}
        zip={zipCity}
        tel={tel}
        email={email}
        country={countryS}
      />
    </>
  );
}

export default Contact;
