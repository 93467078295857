import React from "react";
import { Link } from "react-router-dom";
import GetIntouch from "../../common/get-in-touch/GetInTouch";
import { useTranslation } from "react-i18next";
import "../../../../../resources/styles/components/home-components/startup-event.css";

const GetInTouchHome = () => {
  const { t } = useTranslation("translation");
  const startupCallToActionHeader = t("startupCallToActionHeader", { ns: "translation" });
  const btnStartupCallToAction = t("btnStartupCallToAction", { ns: "translation" });

  return (
    <div className="home-getintouch-container">
      <div className="home-getintouch-wrapper">
        <GetIntouch headerOwnStyle={"home-header-style"} title={startupCallToActionHeader} callToAction={btnStartupCallToAction} />
      </div>
    </div>
  );
};

export default GetInTouchHome;
