import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../../CustomButton";

const LoginForm = () => {
  return (
    <div className="signup-form-container">
      <div className="signup-form-wrapper">
        <h1>Login</h1>
        <form>
          <input type="text" placeholder="Email" />
          <input type="text" placeholder="Password" />
        </form>
        <Link to="/#" style={{ color: "#ff517b" }}>
          <p className="forgot-password">Forgot password?</p>
        </Link>

        <div className="login-wrapper">
          <CustomButton btnText="Login" style={{ fontSize: "20px" }} />
          <div className="set-account-wrapper">
            <p>Don’t have an account? </p>
            <Link to="/sign-up" style={{ color: "#ff517b" }}>
              <span>Sign-up here!</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
