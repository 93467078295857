import React from "react";
import { Link } from "react-router-dom";
import "../../../../../resources/styles/components/footer.css";
import MobileTranslation from "../../../translation-btns/MobileTranslation";
import UpScrollMobile from "../../common/up-scroll-arrow/UpScrollMobile";
import images from "../../../../../resources/images";
import { useTranslation } from "react-i18next";
import UpScrollArrow from "../../common/up-scroll-arrow/UpScrollArrow";

interface PolicyProps {
  className?: string;
  onClick?: () => void;
  isVisibleArrow?: boolean;
}

const Policy = (props: PolicyProps) => {
  const { t } = useTranslation("translation");

  const policy = t("footerPolicy", { ns: "translation" });
  const linkImprintPrivacy = t("linkImprintPrivacy", { ns: "translation" });

  return (
    <div className={`policy-wrapper ${props.className}`}>
      <div className="policy-cont">
        <div className="squib-policy-wrapper">
          <UpScrollArrow imgSrc={images.upArrowSvgBlack} className={`up-arrow`} />
          <div className="mobile-up-arrow-wrapper">
            <UpScrollMobile imgSrc={images.upArrowSvgBlack} className={`mobile-up-arrow`} />
          </div>
          <div className="mobile-translation-in-policy">
            <MobileTranslation />
          </div>
          <span className="year-squib">© 2024 Squib Ltd</span>
          {policy}
          <br />
          <br />
          <Link to="/impressum-datenschutz" className="link-imprint-privacy" onClick={props.onClick}>
            {linkImprintPrivacy}
          </Link>
          {/*<Link to="/nutzungsbedingungen" className="link-imprint-privacy" onClick={props.onClick}>
            {" "}
            | Nutzungsbedingungen
          </Link>*/}
        </div>
      </div>
    </div>
  );
};

export default Policy;
