import React from "react";
import "../../../../../resources/styles/components/common/up-scroll-arrow.css";

interface UpScrollArrowProps {
  imgSrc?: string;
  className?: string;
}

const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const UpScrollMobile = (props: UpScrollArrowProps) => {
  return (
    <div className={`up-arrow-mobile-wrapper ${props.className} `} onClick={handleScrollToTop}>
      <img src={props.imgSrc} alt="chevron-up" className={`${props.className}`} width={45} />
    </div>
  );
};

export default UpScrollMobile;
