import React from "react";
import GetIntouch from "../../common/get-in-touch/GetInTouch";
import "../../../../../resources/styles/components/functionalities-components/get-in-touch.css";

interface IGetInTouchFuncProps {
  title?: string;
  callToActionBtn?: string;
}

const GetInTouchFunctionalities = (props: IGetInTouchFuncProps) => {
  return (
    <div className="get-in-touch-functionalities-container">
      <GetIntouch headerOwnStyle={"functionalities-header-style"} title={props.title} callToAction={props.callToActionBtn} />
    </div>
  );
};

export default GetInTouchFunctionalities;
