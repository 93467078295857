import React from "react";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";

const AppHeader = () => {
  const title = "Squib App";
  const useCasesSubTitle =
    "Mit der Squib App kannst du noch bequemer an Votings teilnehmen und neue Votings entdecken, die deinen Interessen entsprechen.";
  return (
    <div className="app-header-container">
      <div className="app-header-wrapper">
        <HeaderTitleComp title={title} subTitle={useCasesSubTitle} />
      </div>
    </div>
  );
};

export default AppHeader;
