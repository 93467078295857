import React from "react";
import HomeHeader from "./home-header/HomeHeader";
import "../../../../resources/styles/components/home-components/home-header.css";
import { useTranslation } from "react-i18next";
import video from "../../../../resources/videos";

import GroupOne from "./group-one/GroupOne";
import AppVersions from "./app-versions/AppVersions";
import GetInTouchHome from "./getintouch-home/GetInTouchHome";
import MetaData from "../common/metadata/MetaData";
import Reveal from "../common/reveal-animation/Reveal";
import Testimonials from "./testimonials/Testimonials";
import { metadataContent } from "../common/metadata/content";

export const handleBtnScrollVoting = () => {
  const element = document.getElementById("user-waiting-list");
  if (element) {
    const offset = -200;
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: elementPosition + offset,
      behavior: "smooth",
    });
  }
};

function Home() {
  const { t } = useTranslation("translation");
  const { i18n } = useTranslation();

  const groupOneTitle1 = t("groupOneTitle1", { ns: "translation" });
  const groupOneDescription1 = t("groupOneDesc1", { ns: "translation" });
  const groupOneTitle2 = t("groupOneTitle2", { ns: "translation" });
  const groupOneDescription2 = t("groupOneDesc2", { ns: "translation" });

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>
      <MetaData title="Home" german={metadataContent.metadataGermanContentHome} english={metadataContent.metadataEnglishContentHome} />
      <div className="home-container">
        <Reveal>
          <HomeHeader />
        </Reveal>
        <div className="home-video-wrapper">
          <video
            className="home-video"
            src={i18n.language === "de" ? video.squibTeaserDE : video.squibTeaser}
            autoPlay
            loop
            playsInline={isMobile}
            muted
          />
        </div>
        <Reveal>
          <GroupOne title={groupOneTitle1} description={groupOneDescription1} title2={groupOneTitle2} description2={groupOneDescription2} />
        </Reveal>

        <Reveal>
          <AppVersions />
        </Reveal>

        <Reveal>
          <Testimonials />
        </Reveal>

        <Reveal>
          <GetInTouchHome />
        </Reveal>
      </div>
    </>
  );
}

export default Home;
