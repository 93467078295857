import React from "react";
import IntegrationLayout from "../layout/IntegrationLayout";

const IntegrationOne = () => {
  return (
    <IntegrationLayout
      title="Share a link"
      description="Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna."
    />
  );
};

export default IntegrationOne;
