import React from "react";

const CreateNVThree = () => {
  return (
    <div className="create-nv-container">
      <div className="create-nv-wrapper">
        <h1>Wow!</h1>
      </div>
    </div>
  );
};

export default CreateNVThree;
