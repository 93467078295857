import React from "react";
import "../../../../../resources/styles/components/functionalities-components/untitled.css";
import UntitledContent from "./content/UntitledContent";
import { useTranslation } from "react-i18next";

interface UntitledProps {
  title: string;
}

const Untitled = (props: UntitledProps) => {
  const { t } = useTranslation("translation");
  const advantageOne = t("advantageOne", { ns: "translation" });
  const advantageTwo = t("advantageTwo", { ns: "translation" });
  const advantageThree = t("advantageThree", { ns: "translation" });
  const advantageFour = t("advantageFour", { ns: "translation" });

  return (
    <div className="untitled-container">
      <div className="untitled-title-wrapper">
        <h1>{props.title}</h1>
        <UntitledContent valueOne={advantageOne} valueTwo={advantageTwo} valueThree={advantageThree} valueFour={advantageFour} />
      </div>
    </div>
  );
};

export default Untitled;
