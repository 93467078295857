import React from "react";
import MetaData from "../common/metadata/MetaData";
import JobHeaderText from "./job-header-text/JobHeaderText";
import GetInTouchJob from "./getintouch-job/GetInTouchJob";
import "../../../../resources/styles/components/job-components/job.css";
import { useTranslation } from "react-i18next";

const Jobs = () => {
  const { t } = useTranslation("translation");
  const mdText = t("jobs", { ns: "translation" });

  return (
    <>
      <MetaData title={mdText} />
      <JobHeaderText />
      <GetInTouchJob />
    </>
  );
};

export default Jobs;
