import React from "react";
import "../../../../../resources/styles/components/useCases-components/voting-education-politics.css";
import CardUseCasesLayout from "../../common/card-layout/CardUseCasesLayout";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import images from "../../../../../resources/images";
interface VotingEducationPoliticsProps {
  title: string;
  subTitle: string;
  brandHeader?: string;
  brandImage?: string;
}

const VotingEducationPolitics = (props: VotingEducationPoliticsProps) => {
  const { t } = useTranslation("translation");
  const cardFourHeader = t("useCasesFourthCardHeader", { ns: "translation" });

  return (
    <div className="fading-scroll-animation">
      <div className="voting-education-politics-container fading-scroll-animation">
        <img src={images.example4} alt="mobile voting card four" className="mobile-voting-card-four" />
        {/*<CardUseCasesLayout
          id="votingEducationPoliticsID"
          className="inner-card-one-vep-text"
          classNameTwo="inner-card-two-vep-text"
          classNameThree="card-one-vep-like"
          headerTextStyles="card-header-vep-text"
          classNameFour="card-two-vep-like"
          backgroundCardOne="vep-background-card-one"
          backgroundCardTwo="vep-background-card-two"
          thumbUpImage="thumb-up"
          thumbDownImage="thumb-down"
          numWrapperTwo="num-wrapper-two-vep"
          cardHeaderText={cardFourHeader}
          brandLogo={images.skinHarmony}
          cardOneText={""}
          cardTwoText={""}
          spanTextOne={""}
          spanTextTwo={""}
          funnyImgOneCardClass="funny-img-one-card"
          funnyImgTwoCardClass="funny-img-two-card"
          imageUrl={images.imageA}
          imageUrlTwo={images.imageB}
          classLines={["line-one-vep", "line-two-vep"]}
        /> */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, delay: 5 }}
          className="voting-education-politics-wrapper"
        >
          <h1>{props.title}</h1>
          <p>{props.subTitle}</p>
          <p>{props.brandHeader}</p>
          <img src={props.brandImage} alt="brand" className="skin-harmony-brand-img" />
        </motion.div>
      </div>
    </div>
  );
};

export default VotingEducationPolitics;
