import React, { useMemo } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import "../../../../../resources/styles/components/contact-components/contact-comp.css";

interface GoogleMapsProps {
  classMap?: string;
}

const Map = (props: GoogleMapsProps) => {
  const center = useMemo(
    () => ({
      lat: 47.04916,
      lng: 8.30964,
    }),
    [],
  );

  return (
    <div className="google-map-wrapper">
      <GoogleMap zoom={15} center={center} mapContainerClassName="squib-map-container">
        <MarkerF position={center} />
      </GoogleMap>
    </div>
  );
};

const GoogleMaps = () => {
  //const squibCoord = process.env.SQUIB_GOOGLE_MAPS_APIKEY as string;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "",
  });

  if (!isLoaded) return <div>Loading...</div>;

  return <Map />;
};

export default GoogleMaps;
