import React from "react";
import SignupForm from "./SignupForm";
import "../../../../../../resources/styles/components/dashboard-components/dashboard.css";

const Signup = () => {
  return (
    <>
      <SignupForm />
    </>
  );
};

export default Signup;
