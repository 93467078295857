import React from "react";
import "../../../../../resources/styles/components/imprintPrivacy-components/imprintPrivacy-header.css";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";
import { useTranslation } from "react-i18next";

const FuntionalitiesHeaderText = () => {
  const { t } = useTranslation("translation");

  const imprintPrivacyTitle = t("imprintPrivacy", { ns: "translation" });
  const imprintPrivacySub = t("imprintPrivacySubTitle", { ns: "translation" });

  return (
    <div className="imprint-privacy-header-container">
      <div className="imprint-privacy-header-wrapper">
        <HeaderTitleComp title={imprintPrivacyTitle} />
      </div>
    </div>
  );
};

export default FuntionalitiesHeaderText;
