/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useRef } from "react";
import Carousel from "./Carousel";
import "../assets/voting.css";
import "../assets/header-footer.css";
import { Voting, ExtendedQuestion } from "../services/types";
import VotingFooter from "./VotingFooter";
import Header from "./Header";
import { readCookieContent, readCookies } from "../services/cookies";

interface MultiVotingProps {
  isDev?: boolean;
  voting?: Voting;
  isFooterHidden?: boolean;
  isDetailShown?: boolean;
  isNotVotable?: boolean;
  isFullScreen?: boolean;
}

const MultiVoting = (props: MultiVotingProps) => {
  const [voting, setVoting] = useState<Voting>();
  const [questions, setQuestions] = useState<ExtendedQuestion[]>([]);
  const [parentWidth, setParentWidth] = useState<number>(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(window.innerHeight);

  const setFullHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    setFullHeight();
  }, [window.innerHeight]);

  useEffect(() => {
    if (props.voting) {
      setVoting(props.voting);
      const isInActive = new Date(props.voting.expiry_date).getTime() < new Date().getTime();
      const wasVoted: boolean = props.isNotVotable || isInActive || false;
      const questions = props.voting.questions.map(q => ({
        ...q,
        voted: wasVoted ? true : readCookies(props.voting?.id || 1, q.id),
        question_options: q.question_options.map(o => ({
          ...o,
          was_voted: readCookieContent(props.voting?.id || 1, q.id) === o.id,
        })),
      }));
      setQuestions(questions);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateQuestions = (q: ExtendedQuestion): void => {
    for (const question of questions) {
      if (question.id == q.id) {
        question.voted = true;
        setQuestions(questions);
      }
    }
  };

  return (
    <div
      ref={parentRef}
      className={`votingWrapper ${props.isFullScreen && "fullscreen"}`}
      style={props.isFullScreen ? { height: `${height}px` } : {}}
    >
      {props.isFullScreen && <Header isShown={props.voting?.is_company_logo_shown} />}
      <Carousel
        questions={questions}
        voting={voting!}
        updateQuestions={updateQuestions}
        isDev={props.isDev}
        parentWidth={parentWidth}
        isFooterhidden={props.isFooterHidden}
        isDetailShown={props.isDetailShown}
        isFullScreen={props.isFullScreen}
      />
      {!props.isFooterHidden && <VotingFooter />}
    </div>
  );
};

export default MultiVoting;
