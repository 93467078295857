import React, { useEffect, useState, useRef } from "react";
import { QuestionOption, ExtendedQuestion } from "../services/types";
// import OptionsOverlay from "./OptionsOverlay";
import Lottie from "./animations/LottieAnim";
import Result from "./Result";
import ReactTextareaAutosize from "react-textarea-autosize";
import useAutosizeTextArea from "./autoSizedTextAriea";

type cardData = {
  classN: string;
  hideImg?: boolean;
  onVote: (optionId: number, otherId: number, questionId: number) => void;
  option: QuestionOption;
  image?: string;
  backgroundColour?: string;
  fontColour?: string;
  optionsIds: (number | undefined)[];
  sibling: boolean | undefined;
  index?: number;
  question: ExtendedQuestion;
  showResult?: boolean;
  isDisabled?: boolean;
  onUpdateUserInput?: (x: string) => void;
  resultHidden?: boolean;
};

const MIN_FONT_THRESHOLD = 24;

const Card = (props: cardData) => {
  const [voted, setVoted] = useState<boolean>(false);
  const [buttonClass, setButtonClass] = useState<string>("");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const [userInput, setUserInput] = useState<string>("");
  const maxLength = 80;
  const remainingLength = maxLength - userInput.length;
  const [borderRadius, setBorderRadius] = useState<number>(20);
  const [padding, setPadding] = useState<number>(22);
  const [resultFontSize, setResultFontSize] = useState<number>(18);
  const [initialFontSize, setInitialFontSize] = useState<number>(40);
  const [fontSize, setFontSize] = useState<number>(initialFontSize);
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);

  // This fo separating the two click on CustomAnswer card (VOTE - EDIT)
  const [isEditing, setIsEditing] = useState(false);

  // This for siting textArea Height
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setIsEditing(!props.option.is_custom_answer);
  }, [props.option]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        handleResize(entry.target);
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const element = textAreaRef.current;

    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      setInitialFontSize(entry.contentRect.width / 3.60425);
    });

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [textAreaRef]);

  const handleResize = (target: Element) => {
    const height = Number(target.clientHeight.toFixed(2));
    const width = Number(target.clientWidth.toFixed(2));
    setBorderRadius(Math.floor(height / 15.15));
    setPadding(Math.floor(width / 22.8));
    setResultFontSize(Math.floor(width / 9.415));
    changeFontSize();
  };

  useEffect(() => {
    changeFontSize();
  }, []);

  const changeFontSize = (text?: string) => {
    const input = text || userInput;
    const textLength: number = input.length <= 0 ? props.option.body?.length || 1 : input.length;
    if (textLength > 0) {
      const newFontSize = Math.floor(initialFontSize / (1 + (1 / maxLength) * textLength));
      setFontSize(newFontSize);
    }
  };

  const InputChangeHandler = (evt: { target: { value: string } }) => {
    const input = evt.target.value.slice(0, maxLength);
    changeFontSize(input);
    setIsEditing(true);
    setUserInput(input);
    props.onUpdateUserInput && props.onUpdateUserInput(input);
  };

  // This function for handling press of space key as it act as Enter
  const handleKeyDown = (event: { keyCode: number; preventDefault: () => void }) => {
    if (event.keyCode === 32) {
      // Prevent the default behavior of the space key (which acts as the Enter key)
      event.preventDefault();
      if (userInput.length === maxLength) {
        setUserInput(userInput + "");
      } else {
        setUserInput(userInput + " ");
      }
    }
  };

  const handleTextareaClick = (event: { [x: string]: any; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();
  };

  // This represent the input field which will be passed to the customAnswer card
  const inputField = (data: string | undefined, isVoted: boolean, textArea: HTMLTextAreaElement | null, isCustomAnswer?: boolean) => {
    const placeholderFontSize = data ? Math.floor(initialFontSize / (1 + (1 / maxLength) * data.length)) : 24;
    const maxLengthFontSize = textArea ? textArea.clientWidth / 7.5335735342 : 18;

    const style: any = {
      "--placeholder-size": `${placeholderFontSize}px`,
      fontSize: `${fontSize}px`,
      pointerEvents: isCustomAnswer ? "all" : "none",
    };

    return (
      <>
        {!isVoted && isCustomAnswer && (
          <div className="maxLength" style={{ fontSize: `${maxLengthFontSize}px` }}>
            {remainingLength}/{maxLength}
          </div>
        )}
        <ReactTextareaAutosize
          id="review-text"
          ref={textAreaRef}
          value={isCustomAnswer ? userInput : data}
          className="user-input-data"
          // placeholder={`[${data}]`}
          onChange={InputChangeHandler}
          disabled={props.isDisabled || props.question.voted}
          maxLength={maxLength}
          onKeyDown={handleKeyDown}
          cols={10}
          style={style as any}
          tabIndex={-1}
          rows={10}
          onClick={handleTextareaClick}
          onFocus={() => setShowPlaceholder(false)}
          onBlur={() => setShowPlaceholder(userInput === "")}
        />
        {showPlaceholder && isCustomAnswer && (
          <div className="placeholderText" style={{ fontSize: `${placeholderFontSize}px` }}>
            {`[${data}]`}
          </div>
        )}
      </>
    );
  };

  const notSelectedOptionId = props.optionsIds.find(id => id !== props.option.id);

  const onVote = (): void => {
    if (props.option.id && notSelectedOptionId) {
      setVoted(true);
      props.onVote(props.option.id, notSelectedOptionId, props.question.id);
    }
  };

  const handleClick = () => {
    if (props.question.body && props.question.body !== "") {
      if (!isEditing && userInput.length <= 0) {
        // console.log("This is a custom answer card and the user must write data.");
        if (textAreaRef.current) textAreaRef.current.focus();
      } else {
        setButtonClass("scale");
        setTimeout(() => {
          onVote();
        }, 100);
      }
    } else {
      // console.log("This is not a custom answer card, and you can press :)");
      setButtonClass("scale");
      setTimeout(() => {
        onVote();
      }, 100);
    }
  };

  useEffect(() => {
    if (buttonRef.current && props.question.voted) {
      buttonRef.current.classList.remove("scale");
    }
  }, [props.question.voted]);

  useEffect(() => {
    if (props.option?.was_voted) {
      setVoted(true);
    }
  }, [props.option]);

  return (
    <button
      ref={buttonRef}
      onClick={handleClick}
      className={`image-btn ${buttonClass} ${props.question.voted && "voted"}`}
      disabled={props.question.voted ? true : false}
      style={{ padding: padding }}
      tabIndex={-1}
    >
      <div
        className={`card-style ${props.classN}`}
        style={{ backgroundColor: props.backgroundColour, "--placeholder-colour": props.fontColour, borderRadius: borderRadius } as any}
        ref={elementRef}
      >
        {props.hideImg || !props.image ? null : <img alt="option-2" className="voteImage" src={props.image} />}
        {voted && <Lottie name="tick" speed={1} fontSize={resultFontSize} />}
        {props.showResult && !props.resultHidden && (
          <Result
            percentage={props.option.total_votes_percentage !== undefined ? props.option.total_votes_percentage : 50}
            fontSize={resultFontSize}
          />
        )}
        {props.option.body &&
          props.option.body !== "" &&
          inputField(props.option.body, props.showResult || false, textAreaRef.current, props.option.is_custom_answer)}
      </div>
    </button>
  );
};

export default Card;
