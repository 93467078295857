import React from "react";
import "../../../../../resources/styles/components/functionalities-components/functionalities-main-content.css";
import images from "../../../../../resources/images/index";

interface IWhyFeedBackProps {
  title: string;
  subTitle: string;
}

const FuntionalitiesMainContent = (props: IWhyFeedBackProps) => {
  return (
    <div className="functionalities-main-container">
      <div className="functionalities-header-wrapper">
        <h1>{props.title}</h1>
        <p>{props.subTitle}</p>
      </div>
      <div className="functionalities-img-container">
        <div className="funtionalities-img-wrapper">
          <img src="/images/char-squib3.png" alt="char-08" className="char-08-img" />
        </div>
      </div>
    </div>
  );
};

export default FuntionalitiesMainContent;
