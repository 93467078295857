import React from "react";
import "../../../../../resources/styles/components/useCases-components/use-cases-header.css";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";
import { useTranslation } from "react-i18next";
import Reveal from "../../common/reveal-animation/Reveal";

const UseCasesHeader = () => {
  const { t } = useTranslation();
  const title = t("useCasesTitle", { ns: "translation" });
  const titleMobile = t("useCasesTitleMobile", { ns: "translation" });
  const useCasesSubTitle = t("useCasesSubTitle", { ns: "translation" });

  return (
    <div className="use-cases-header-container">
      <div className="use-cases-header-wrapper">
        <Reveal>
          <HeaderTitleComp className="header-title-mobile-none" title={title} subTitle={useCasesSubTitle} titleMobile={titleMobile} />
        </Reveal>
      </div>
    </div>
  );
};

export default UseCasesHeader;
