import React from "react";

interface AddressProps {
  startUp: string;
  place: string;
  desc: string;
  street: string;
  zip: string;
  tel?: string;
  email?: string;
  country: string;
  dataProtectorOfficer?: string;
  dataProtectorName?: string;
}

const Address = (props: AddressProps) => {
  return (
    <div className="imprintPrivacy-contact-info">
      <div className="imprintPrivacy-contact-header">
        <div className="imprintPrivacy-address-text">
          <p className="contact-info-header">{props.startUp}</p>
          <p className="contact-info-place">{props.place}</p>
          <p className="contact-info-desc">{props.desc}</p>
          <p className="contact-info-street">{props.street}</p>
          <p className="contact-info-zip">{props.zip}</p>
          <p className="contact-info-country">{props.country}</p>
          <p className="contact-info-data-protector">{props.dataProtectorOfficer}</p>
          <p className="contact-info-data-protector-name">{props.dataProtectorName}</p>
        </div>
      </div>
    </div>
  );
};

export default Address;
