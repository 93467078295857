import React from "react";
import images from "../../../../../../resources/images";
import CustomButton from "../../../../CustomButton";

interface PricingFeaturesPlanLayoutProps {
  headerPlanVersions: string[];
  headerPlanPrice: string[];
  planOptions?: string[];
  btnText?: string;
  posibleAccess?: string;
}

const PricingFeaturesPlanLayout = (props: PricingFeaturesPlanLayoutProps) => {
  return (
    <div className="pricing-features-plan-layout-container">
      <div className="pricing-features-plan-layout-wrapper">
        {props.headerPlanVersions.map((version, index) => {
          return (
            <div className="pricing-features-plan-layout-item" key={index}>
              <div className="pricing-features-plan-layout-item-wrapper">
                <div className="item-version-wrapper">
                  <p className="item-version">{version}</p>
                </div>
                <div className="item-price">
                  <span>{props.headerPlanPrice[index]}</span>
                  <p>/ month</p>
                </div>
              </div>
              <div className="item-options-wrapper">
                {props.planOptions?.map((option, index) => {
                  return (
                    <div className="item-option-wrapper" key={index}>
                      <div>
                        <img src={images.check} alt="check" width={32} />
                      </div>
                      <div className="plan-option-wrapper">
                        <p>{option}</p>
                      </div>
                      <div className="excl-wrapper">
                        <img src={images.excl} alt="excl" width={24} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="custom-btn-wrapper">
          <CustomButton className="custom-btn" btnText={props.btnText} />
          <div className="posible-access-wrapper">
            <p>{props.posibleAccess}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingFeaturesPlanLayout;
