import React from "react";

const GetInTouchApp = () => {
  return (
    <div className="getintouch-app-container">
      <div className="getintouch-app-wrapper">
        <p>Download the Squib App now!</p>
      </div>
      <div className="store-app-wrapper">
        <div className="store-app-ap">
          <a href="https://apps.apple.com/ch/app/squib/id1583386830" target="_blank" rel="noreferrer">
            <img src="https://www.squib.app/static/media/app_store_badge.d64473ca.svg" alt="app-store-logo" />
          </a>
        </div>
        <div className="store-app-gp">
          <a href="https://play.google.com/store/apps/details?id=com.squibapp" target="_blank" rel="noreferrer">
            <img src="https://www.squib.app/static/media/google-play-badge.13c69222.svg" alt="play-store-logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default GetInTouchApp;
