import React from "react";
import ReleasesLayout from "../../layout/ReleasesLayout";

const FeatureTwo = () => {
  return (
    <ReleasesLayout
      title="Enhanced language support"
      description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      date="14.Februar 2023"
      versions={["Lite", "App", "Pro"]}
    />
  );
};

export default FeatureTwo;
