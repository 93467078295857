import React from "react";

const HandlePrivacy = () => {
  const title = "How we handle privacy.";
  const description =
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. (Single & Multi Votings)";

  return (
    <div className="handle-privacy-container">
      <div className="handle-privacy-image-wrapper"></div>
      <div className="handle-privacy-wrapper">
        <h2 className="handle-privacy-title">{title}</h2>
        <p className="handle-privacy-description">{description}</p>
      </div>
    </div>
  );
};

export default HandlePrivacy;
