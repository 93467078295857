import React from "react";
import PlanOne from "./plan-one/PlanOne";
import PlanTwo from "./plan-two/PlanTwo";
import PlanThree from "./plan-three/PlanThree";

const PricingFeaturesPlan = () => {
  return (
    <div className="pricing-features-plan-comp-container">
      <PlanOne />
      <PlanTwo />
      <PlanThree />
    </div>
  );
};

export default PricingFeaturesPlan;
