const link = "https://www.squib.app/impressum-datenschutz";
const squibEmail = "hello@squib.app";
const protectionOfficer = "marco.eichenberger@squib.app";

export const english = {
  home: "Home",
  createVote: "Create a Voting",
  explore: "Discover Squib",
  useCases: "Use cases",
  pricingFeatures: "Pricing & Features",
  successStories: "Success Stories",
  comparison: "Squib in comparison",
  security: "Security",
  integrations: "Integrations",
  releases: "Releases",
  imprintPrivacy: "Imprint & Privacy",
  faqHelp: "FAQ & Help",
  voting: "Voting",
  votingDeleted: "Voting deleted",
  votingClosed: "Voting closed",
  about: "About",
  contact: "Contact",
  balance: "Balance",
  error: "Error",

  comparisonUrl: "...",
  comparisonFooter: "...",

  voteForA: "...",
  voteForB: "...",

  voteForThis: "...",
  voteForThat: "...",

  homeHeaderTitle: "Simplify your surveys.",
  homeHeaderDesc: "The easiest way to create engaging surveys that all your target audiences will love.",

  errorWaitingListMessage: "Please enter a valid email address.",

  startUpNightsParagraph:
    "«With Squib, we were able to feel the pulse of the participants directly during the event. Thanks to the smart survey design, we achieved a great response rate and particularly authentic feedback.»",
  benedictParagraph:
    "«As a leading school, it's crucial for us to address the needs of our learners. By using Squib, we've been able to gather particularly authentic and immediate feedback. Also, Squib provides the results clearly, allowing us to draw conclusions very efficiently.»",

  useCasesTitle: "Use cases",
  useCasesTitleMobile: "Use cases",
  useCasesSubTitle:
    "Organisations can only achieve long-term success by consistently attending to the needs of their stakeholders. Below you find a selection of business examples from various organisations that use Squib successfully.",

  cardOneUseCasesTwoText: "...",
  cardThreeUseCasesText: "...",

  getInTouchUseCasesHeader: "Do you want to use Squib or do you have a question?",
  getInTouchUseCasesBtn: "Contact now",

  successStoriesTitle: "...",
  successStoriesSubTitle: "...",

  getInTouchSuccessStoriesHeader: "...",
  getInTouchSuccessStoriesBtn: "...",

  clientNeedsSubtitle:
    "«With Squib, we were able to immediately gauge the participants' sentiment during the event. Thanks to the smart survey design, we achieved a great response rate and particularly authentic feedback.»",
  clientNeedsParagraph: "www.startup-nights.com",
  clientNeedsAuthor: "Alyssia Kugler",
  clientNeedsAuthorTitle: "Director",

  pricingFeaturesTitle: "...",
  pricingFeaturesSubTitle: "...",
  appVersionHeader1: "...",
  appVersionHeader2: "...",
  appVersionHeader3: "...",
  appVersionsDesc: "...",
  appVersionBtn1: "...",
  appVersionBtn2: "...",
  freeRegistration: "...",
  pricePro: "...",
  priceProPlus: "...",

  components: "Components",

  whySquib: "Why Squib",
  feedback: "Feedback is fun",
  exploreHeaderTitle: "Why Squib?",
  exploreHeaderDesc:
    "With Squib, you get more and most authentic online feedback than with any other tools. Its extremely user-friendly and straightforward design encourages participation from your target audiences across all age groups. This has been repeatedly proven through empirical studies.",

  exploreText: "Because good surveys can also be fun.",
  exploreSubText:
    "It's proven that surveys get more participation by being visually appealing and easy to use. Squib's mission is to support organisations cultivate a genuine culture of feedback. In today's world, communication is frequent, yet individual needs often receive insufficient attention.",
  whySquibFeedback: "Which is why Squib inspires:",

  propertyOne: "Keep it simple",
  propertyTwo: "More honest feedback",
  propertyThree: "Clear evaluations",
  propertyFour: "Reliable data",
  propertyOneSub:
    "As attention is a valuable asset in today's world, it is worth focusing on proven and intuitively understandable methods that resonate with people instead of getting lost in a multitude of different types of questions.",
  propertyTwoSub:
    "The intuitive design encourages users to engage with Squib even while on the move or during waiting times. This not only boosts engagement but also results in more authentic responses.",
  propertyThreeSub:
    "The survey results are automatically prepared for you, allowing you to analyse them immediately. Thanks to advanced AI technology, it will soon be possible to categorise, summarise or contextualise qualitative feedback in more comprehensive ways.",
  propertyFourSub:
    "Simply choose whether your survey is publicly accessible or exclusively intended for a specific group of people. This degree of flexibility ensures reliable and secure data collection.",

  advantageTitle: "Advantages you have with Squib:",
  advantageOne: "Simple and intuitive handling, no manual and no training required.",
  advantageTwo: "Customisation with your own logo and styling to meet your individual needs.",
  advantageThree: "Gamification features ensure high engagement and low drop-off rates.",
  advantageFour: "Impressive user experience without the need to download an app.",

  funcQuestion: "Do you want to use Squib or do you have a question?",
  funcCallToActionText: "Contact now",
  anyQuestion: "Any questions or do you need help?",
  getInTouchBtn: "Get in touch now!",

  faqTitle: "Frequently Asked Questions (FAQ)",
  faqQuestionOne: "What is Squib? ",
  faqAnswerOne:
    "Squib is a pioneering and evidence-based survey tool that provides more authentic feedback compared to other tools. It is user-friendly and creates positive experiences for participants. Traditional survey tools no longer align with the changing media consumption habits characterized by decreasing attention spans. Organizations benefit from Squib's simplicity in creating and analyzing surveys.",
  faqQuestionTwo: "Why should I use Squib when there are many other survey tools available?",
  faqAnswerTwo:
    "If you want to receive more and authentic feedback from your stakeholders and don't want to send out unpopular reminders for every survey, then Squib is your first choice. Other tools offer many different question types and options, which is time-consuming to create and analyse. These complex survey designs often prevent participants from giving authentic answers until the last question.",

  faqQuestionThree: "What distinguishes Squib from other survey tools?",
  faqAnswerThree:
    "Surveys are crucial for organizations to understand their stakeholders' needs. However, lengthy and confusing survey designs are unpopular. Squib addresses all these with a simple principle that provides positive experiences for participants.",

  faqQuestionFour: "Is Squib necessary if nowadays I can use artificial intelligence (e.g., 'Customer Twins') to understand my customers?",
  faqAnswerFour:
    "This is an important question, thanks. With advances in the use of AI for quick and supposedly increasingly better answers, it's essential to know the real needs of your stakeholders. Generic responses from an average customer can be misleading. Your customers and employees have individual needs and personal connections to you which AI does not answer.",

  faqQuestionFive: "Is Squib relevant to my industry?",
  faqAnswerFive:
    "Yes. Media consumption habits have changed across all demographics, and simplicity and appealing design are effective across all age groups and education levels. With Squib, you can survey both simple and complex topics using the same principle.",

  faqQuestionSix: "We already have a good feedback culture. What additional benefit does Squib offer?",
  faqAnswerSix:
    "That's great to hear! A good feedback culture is crucial for success. Research shows that stakeholders are significantly more satisfied when they are regularly involved in specific topics rather than addressing multiple areas at once. Squib is uniquely suited to gather concrete feedback on individual concerns.",

  faqQuestionSeven: "What about data protection? Do you have access to data we collect?",
  faqAnswerSeven: `This is a crucial point. Based on GDPR (DSGVO) and the Swiss Data Protection Act, we offer the best possible protection for your data. Further information on data protection can be found <a href=${link} target="_blank">here</a>.`,

  faqQuestionEight: "I have an idea for an additional feature to make Squib even more effective. Can I suggest it?",
  faqAnswerEight: `Thank you for your input! Please send us an email at <a href="mailto:${squibEmail}">${squibEmail}</a>. We continuously improve our services and will consider your suggestion as much as possible.`,

  faqQuestionNine: "Does my subscription renew automatically, and how can I cancel it?",
  faqAnswerNine: "tbd.",

  ctaText: "...",
  subText: "...",

  groupOneTitle1: "Why are classic surveys unpopular?",
  groupOneDesc1:
    "Classic surveys are often tiresome and offer little incentive for participation. Squib revolutionises this process by offering an intuitive and empirically tested method that not only encourages genuine responses but also significantly increases engagement. What makes it truly unique and appealing is that people enjoy taking part!",
  btnGroupOne1: "Learn more",

  versionsTitle: "You want to be among the first to use Squib and benefit from preferential conditions?",
  versionsDescHome: "Sign up now for the waitlist and get exclusive updates on Squib.",
  inputWaitingList: "Your email address",
  btnWaitingList: "Enter here",
  waitingListConfirm: "Thank you!",
  waitingListConfirmSubText: "We'll keep you up to date.",

  btnVersions: "...",

  startupCallToActionHeader: "Do you want to use Squib or do you have a question?",
  btnStartupCallToAction: "Contact us",

  groupOneTitle2: "Less effort, more feedback.",
  groupOneDesc2:
    "Squib enhances the engagement of your target audiences through attractive design. Authentic feedback is achieved with simple yet clever usability. All you need to do now is involve your stakeholders on a regular basis.",
  btnGroupOne2: "See examples",

  btnStartup: "...",

  question: "...",
  questionInp: "...",
  hashtagInp: "...",
  linkTerms: "...",
  linkSub: "...",
  hintText: "...",

  useCasesTitleOne: "Establish needs-based school instruction",
  useCasesDescOne:
    "An ideal learning environment also takes into account the needs of learners. It is important to obtain feedback regularly during the semester. Contemporary and interactive, directly via QR code in the classroom.",

  brandHeader: "This example was realized with",

  useCasesTitleTwo: "Addressing the employees' needs",
  useCasesDescTwo:
    "Listening to employees and involving them is essential for an organisation's success. However, this should be done in an accessible and intuitive manner, either directly at the workplace via a QR codes or remotely, at any time and from any location, via a link.",

  useCasesTitleThree: "Real-time event feedback",
  useCasesDescThree:
    "Engage your event attendees directly and in a clever way to gather valuable feedback on the topics that will help you move forward. This innovative approach to interaction also empowers participants to actively contribute to future developments.",

  useCasesTitleFour: "Success factor co-creation: actively involving customers",
  useCasesDescFour:
    "The approach to actively involve current and future customers makes it possible to tailor products and services towards their needs and preferences: 'market research light' combined with a clever marketing approach leads to innovations that truly resonate with their target audience.",

  useCasesFirstCardHeader: "...",
  useCasesSecondCardHeader: "...",
  useCasesThirdCardHeader: "...",
  useCasesFourthCardHeader: "...",

  votingMainTitle: "...",
  linkOptionTitle: "...",
  dataText: "...",
  dataDesc: "...",

  contactTitle: "Contact",
  contactSubTitle:
    "Are you interested in creating state-of-the-art surveys with Squib? Do you have a specific question or simply want to give us feedback? Contact us by e-mail or LinkedIn. We look forward to it!",
  contactPlace: "Squib Ltd.\nc/o Hochschule Luzern – Wirtschaft\nSmart-up\nZentralstrasse 9\nPostfach 2940\n6002 Luzern\nSwitzerland",

  checkText1: "I want to use Squib (Pro, Pro+ or Enterprise)",
  checkText2: "I have a question or want to give feedback",
  checkText3: "I would like to work for Squib",
  checkText4: "I am an investor and I am interested in your business case",

  company: "Company name",
  name: "Last name",
  lastname: "First name",
  telephone: "Telephone number",
  message: "Message",
  oblig: "required",

  labelCompany: "Company",
  labelName: "Name",
  labelLastName: "Last Name",
  labelTelephone: "Phone",
  labelMessage: "Message",

  sendMsg: "Send",

  contactHeader: "Contact",
  contactSubHeader: "...",

  aboutSquibAppText: "...",
  aboutSquibAppDesc: "...",
  createVotingBtn: "Create a Voting",

  stepsTitle: "...",
  step1: "...",
  step2: "...",
  step3: "...",
  callToActionTitle: "...",

  contactAddressHeader: "...",

  squibProDesc: "...",

  errorTitle: "Sorry, this Voting is already finished.",
  errorHintText: "Create a new Voting here!",

  integration: "Integration",
  product: "Product",
  discover: "Discover",
  resources: "Resources",

  uberSquib: "About Squib",
  blog: "Blog",
  contactUs: "Contact",
  jobs: "Jobs",
  media: "Media",
  imprint: "Imprint",
  footerPolicy:
    ". Squib is a copyrighted trademark of Squib Ltd. All rights reserved. All other trademarks are the property of their respective owners.",

  linkImprintPrivacy: "Imprint & Privacy",

  mobileMenuOne: "Explore",
  mobileMenuTwo: "Use cases",
  mobileMenuThree: "Contact",

  waitingListBtnText: "Join the waiting list",

  btnCreateVoting: "Create Voting",
  btnStartVoting: "Start Voting",
  btnNewCreateVoting: "Create new Voting",
  btnLearnMore: "Learn more",
  btnSendMessage: "Send Message",

  headerContactInfo: "Responsible for the content of the website:",

  // Imprint & Privacy Policy
  imprintPrivacyHeaderQuestion: "Would you like to use Squib or do you have a question?",
  imprintPrivacyCallToActionBtn: "Contact now",

  imprintPrivacyContentTitle1: "Imprint & Privacy Policy",
  imprintPrivacyAddress:
    "Squib Ltd.\nc/o Hochschule Luzern – Wirtschaft\nSmart-up\nZentralstrasse 9\nPostfach 2940\n6002 Luzern\nSwitzerland",
  imprintPrivacyResponsive: "Marco Eichenberger",

  imprintPrivacyContent1One: "Last updated: June 2, 2024",
  imprintPrivacyContent1Two:
    "Squib is aware of the importance of privacy for its users. Transparent handling of personal data is therefore an important concern for us. This privacy policy informs about which personal data we collect for which purpose and to whom we disclose this data.",
  imprintPrivacyContent1Three:
    "We also inform you about our data processing policies and methods that comply with the GDPR. These data protection regulations supplement our terms of use. Please note that in the event of discrepancies between the English and German versions of our data protection regulations, the German version shall prevail.",

  imprintPrivacyContentTitle2: "Content",
  imprintPrivacyContentListOne: "1. What do we do?",
  imprintPrivacyContentListTwo: "2. What do we inform about?",
  imprintPrivacyContentListThree: "3. Definitions of terms",
  imprintPrivacyContentListFour: "4. Contact",
  imprintPrivacyContentListFive: "5. Data security",
  imprintPrivacyContentListSix: "6. Data subject rights",
  imprintPrivacyContentListSeven: "7. General principles",
  imprintPrivacyContentListEight: "8. Individual data processing operations",
  imprintPrivacyContent2One:
    "Diese Datenschutzerklärung bezieht sich auf die Verarbeitung von Daten, die generiert werden, wenn Sie unsere Dienste nutzen oder darauf zugreifen.",

  imprintPrivacyContent2Two:
    "Diese Datenschutzerklärung findet keine Anwendung für die Praxis von Drittparteien, die wir nicht besitzen, kontrollieren oder verwalten. Dies sind Websites, Dienste, Anwendungen und Firmen von Drittparteien. Wir sind zwar bestrebt, nur mit Diensten von Drittparteien zu arbeiten, welche unseren Respekt für den Schutz Ihrer Daten teilen, tragen aber keine Verantwortung für die Richtlinien dieser Drittparteien bezüglich Inhalten und Datenschutz. Wir möchten Sie dazu motivieren, die Datenschutzrichtlinien sämtlicher Dienste von Drittparteien zur Kenntnis zu nehmen, auf die Sie zugreifen.",
  imprintPrivacyContent2Three:
    "Des Weiteren regelt diese Datenschutzerklärung nicht, was unsere Benutzer auf ihren Squib-Profilen tun und wir tragen keine Verantwortung für die Praxis der Erfassung und Nutzung von Daten durch einzelne Nutzer.",

  imprintPrivacyContentTitle3: "1. What do we do?",
  imprintPrivacyContent3One:
    "Squib Ltd. (c/o Hochschule Luzern - Wirtschaft, Smart-up, Zentralstrasse 9, 6002 Luzern, Switzerland) operates the website www.squib.app (hereinafter referred to as 'we').",
  imprintPrivacyContent3Two:
    "The protection of your personal data is a major concern for us. In this privacy policy, we inform you transparently and comprehensibly about which data we collect on our website and how we handle it.",

  imprintPrivacyContentTitle4: "2.  What do we inform about?",
  imprintPrivacyInformListOne: "Who is responsible for data processing;",
  imprintPrivacyInformListTwo: "What data is collected;",
  imprintPrivacyInformListThree: "For what purpose this data is collected;",
  imprintPrivacyInformListFour: " On what legal basis we collect this data;",
  imprintPrivacyInformListFive: "To whom we disclose this data;",
  imprintPrivacyInformListSix: "How you can object to data processing;",
  imprintPrivacyInformListSeven: "hat rights you have and how you can exercise them.",
  imprintPrivacyContent4:
    "Wenn Sie in unseren Diensten einen Account einrichten, können Sie Daten wie Benutzername, Alter, Geschlecht, Standort hinterlegen, um die Dienste optimal zu nutzen. Diese Daten können wir anonymisiert verwenden, um die Dienste zu erweitern oder zu verbessern, beispielsweise durch Personalisierung. Wir können z.B. Ihr Alter heranziehen, um zu bestätigen, dass es Ihnen gesetzlich gestattet ist, die Dienste zu nutzen und um unsere Inhalte an Ihr Alter anzupassen. Diese Einstellungen können Sie in den Einstellungen in unseren Diensten jederzeit anpassen.",

  imprintPrivacyContentTitle5: "3. Definitions of terms",
  imprintPrivacySubTitle5One: "What is personal data?",
  imprintPrivacyContent5One:
    "Personal data (equivalent to the term 'personal information') are all details that relate to an identified or identifiable natural person. This includes, for example, name, address, date of birth, email address, or telephone number as well as the IP address. Data on personal preferences such as hobbies or memberships are also considered personal data.",
  imprintPrivacySubTitle5Two: "What are special categories of personal data?",
  imprintPrivacy5Content1Two: "Special categories of personal data (equivalent to the term 'sensitive data') include:",
  imprintPrivacyContent5ListOne: "Data on religious, ideological, political, or trade union views or activities;",
  imprintPrivacyContent5ListTwo: "Data on health, intimate sphere, racial or ethnic origin, and sexual life and orientation;",
  imprintPrivacyContent5ListThree:
    " Data on administrative or criminal proceedings and sanctions as well as data on social assistance measures;",
  imprintPrivacyContent5ListFour: " Genetic data and biometric data that uniquely identify a person.",
  imprintPrivacy5Content3Two:
    "To the extent necessary and you provide us with such data, we may process data that belong to a special category of personal data. In this case, their processing is subject to stricter confidentiality.",

  imprintPrivacySubTitle5Three: "What is the processing of personal data?",
  imprintPrivacy5Content1Three:
    "Processing (equivalent to the term 'handling') is any handling of personal data, regardless of the means and procedures used, particularly the acquisition, storage, retention, use, modification, disclosure, archiving, deletion, or destruction of personal data.",

  imprintPrivacySubTitle5Four: "What is the disclosure of personal data?",
  imprintPrivacy5Content1Four:
    "This is the transmission or making available of personal data, for example, the publication or disclosure to a third party.",

  imprintPrivacyContentTitle6: "4. Contact",
  imprintPrivacyContent6: `If you have any questions or concerns about the protection of your data by us, you can contact our data protection officer: Marco Eichenberger (<a href="mailto:${protectionOfficer}">${protectionOfficer}</a>).`,

  imprintPrivacyContentTitle7: "5. Data Security",
  imprintPrivacyContent7One:
    "We will keep your data secure and take all reasonable measures to protect your data from loss, access, misuse, or alteration. Our contractors and employees who have access to your data are required to comply with data protection regulations. In some cases, it will be necessary for us to forward your inquiries to affiliated companies as part of order processing. Even in these cases, your data will be treated confidentially. Within our website, we use the SSL (Secure Socket Layer) procedure in connection with the highest encryption level supported by your browser.",
  imprintPrivacyContent7Two:
    "Sie haben überdies jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben ausserdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.",

  imprintPrivacyContentTitle8: "6. Data Subject Rights",
  imprintPrivacySubTitle8One: "Right of Access",
  imprintPrivacyContent8One: `You can request information about the data we hold about you at any time. Please send your request for information along with a credible proof of identity to <a href="mailto:${protectionOfficer}">${protectionOfficer}</a>. The information will be provided in writing or in another form, if necessary, electronically. If you request it, we can also provide the information orally, provided you can prove your identity in another way. If you make the request electronically, we will provide the information in a commonly used electronic format unless you specify otherwise.`,
  imprintPrivacyContent8Two:
    "The information is generally provided free of charge. If additional copies are requested, a easonable fee may be charged.",
  imprintPrivacyContent8Three: "The right to receive a copy of the processed data must not impair the rights and freedoms of other persons",
  imprintPrivacyContent8Four:
    "In the case of obviously unfounded or excessive requests for information, we reserve the right to refuse to provide information or to charge a reasonable fee within the legal limits.",
  imprintPrivacyContent8Five:
    "The processing of your request is subject to the statutory period of one month. We may extend this period by two additional months due to the complexity and high number of requests if necessary. You will be informed of the extension within one month of making the request and will be provided with the reasons for the extension.",

  imprintPrivacySubTitle8Two: "Right to Erasure and Rectification",
  imprintPrivacySubTitle8TwoContentOne:
    "You can request the deletion or correction/completion of your data at any time, provided that no legal retention obligations or a legal basis oppose this.",
  imprintPrivacySubTitle8TwoContentTwo:
    "Please note that exercising your rights may conflict with contractual agreements and may have corresponding effects on contract performance (e.g., premature termination of the contract or cost consequences).",

  imprintPrivacySubTitle8Three: "Right to Restriction of Processing",
  imprintPrivacySubTitle8ThreeContentOne:
    "You also have the right to request the restriction of processing if you dispute the accuracy of this data, the processing is unlawful, the data is no longer needed, or you have objected to the processing.",
  imprintPrivacySubTitle8ThreeContentTwo:
    "If the processing of the data is restricted, they may only be stored. Further processing may only take place with your consent, for the assertion, exercise, or defense of legal claims, for the protection of the rights of another person, or for reasons of important public interest. If the restriction is lifted, you will be notified.",

  imprintPrivacySubTitle8Four: "Right to Data Portability",
  imprintPrivacySubTitle8FourContentOne:
    "You have the right to receive the personal data concerning you, which you have provided to us, in a structured, commonly used, and machine-readable format and to transmit this data to another controller without hindrance from us, provided that the processing is based on consent according to Article 6(1)(a) or Article 9(2)(a) GDPR or on a contract according to Article 6(1)(b) GDPR and the processing is carried out by automated means. You also have the right to request that the personal data be transmitted directly from us to another controller, where technically feasible.",

  imprintPrivacySubTitle8Five: "Right to Object",
  imprintPrivacySubTitle8FiveContentOne:
    "If you have given consent to the processing of your data, you can withdraw it at any time. Such a withdrawal affects the permissibility of the processing of your personal data after you have declared your objection to us.",
  imprintPrivacySubTitle8FiveContentTwo:
    "As far as we base the processing of your personal data on the balance of interests, you can object to the processing. This is the case if the processing is not necessary in particular for the fulfillment of a contract with you, which is described by us in each case in the following description of the functions. When exercising such an objection, we ask you to explain the reasons why we should not process your personal data as we have done. In the case of your justified objection, we will examine the situation and will either stop or adjust the data processing or show you our compelling legitimate reasons on the basis of which we continue the processing.",
  imprintPrivacySubTitle8FiveContentOneThree:
    "You can object to the processing of your personal data for advertising and data analysis purposes at any time. You can inform us of your advertising objection using the contact details provided in this privacy policy.",

  imprintPrivacySubTitle8Six: "Right to Complain",
  imprintPrivacySubTitle8SixContentOne:
    "You also have the right to complain to a data protection supervisory authority about the processing of your personal data by us.",

  imprintPrivacyContentTitle9: "7. General Principles",

  imprintPrivacySubTitle9One: "What data do we process about you and from whom do we receive this data?",
  imprintPrivacySubTitle9OneContentOne:
    "Primarily, we process personal data that you provide to us or that we collect in the course of operating our website. We may also receive personal data about you from third parties. These may include the following categories:",
  imprintPrivacy9ListOne: " Personal master data (name, address, birth dates, etc.);",
  imprintPrivacy9ListTwo: "Contact data (mobile phone number, email address, etc.);",
  imprintPrivacy9ListThree: " Financial data (e.g., account information);",
  imprintPrivacy9ListFour: " Online identifiers (e.g., cookie identifier, IP addresses);",
  imprintPrivacy9ListFive: "Location and traffic data.",
  imprintPrivacySubTitle9OneContentTwo: "These data can come from the following sources:",

  imprintPrivacy9ListSix: "Information from publicly accessible sources (e.g., media, internet);",
  imprintPrivacy9ListSeven: "Information from public registers (e.g., commercial register, debt enforcement register, land register);",
  imprintPrivacy9ListEight: "Information in connection with administrative or judicial proceedings;",
  imprintPrivacy9ListNine: "Information about your professional functions and activities (e.g., professional networks);",
  imprintPrivacy9ListTen: "Information about you in correspondence and meetings with third parties;",
  imprintPrivacy9ListEleven: "Credit information (as far as we conduct personal business with you);",
  imprintPrivacy9ListTwelve:
    "Information about you provided by people from your environment so that we can conclude or process contracts with you;",
  imprintPrivacy9ListThirteen: "Data in connection with the use of the website.",

  imprintPrivacySubTitle9Two: "Under what conditions do we process your data?",
  imprintPrivacySubTitle9TwoContentOne:
    "We process your data in accordance with the applicable data protection laws, in particular the GDPR. The processing is carried out for the purposes specified in this privacy policy. We ensure transparency and proportionality in the process.",
  imprintPrivacySubTitle9TwoContentTwo:
    "The processing of your data is lawful if a legal basis of the GDPR is fulfilled. The following legal bases are particularly considered:",
  imprintPrivacy9ListFourteen: "Your consent (Art. 6(1)(a) GDPR);",
  imprintPrivacy9ListFifteen: "The fulfillment of a contract or pre-contractual measures (Art. 6(1)(b) GDPR);",
  imprintPrivacy9ListSixteen: "The fulfillment of legal obligations to which we are subject (Art. 6(1)(c) GDPR);",
  imprintPrivacy9ListSeventeen: "The protection of vital interests of the data subject or another natural person (Art. 6(1)(d) GDPR);",
  imprintPrivacy9ListEighteen:
    "The performance of a task carried out in the public interest or in the exercise of official authority vested in the controller (Art. 6(1)(e) GDPR);",
  imprintPrivacy9ListNineteen: "Our legitimate interests, provided your interests do not override them (Art. 6(1)(f) GDPR).",

  imprintPrivacySubTitle9TwoContentThree:
    "It may be necessary for you to provide us with certain personal data so that contractual obligations can be fulfilled. Without such data, we are usually unable to process a contract.",
  imprintPrivacySubTitle9TwoContentFour:
    "The website generally cannot be used if certain information necessary for securing data traffic, such as your IP address, is not disclosed.",

  imprintPrivacySubTitle9Three: "In what cases can we disclose your data to third parties?",
  imprintPrivacyTitleA: "a. Principle",
  imprintPrivacySubTitle9ThreeContentOneA: `We may need to use the services of third parties or affiliated companies and
commission them to process your data (so-called processors). Categories of recipients include:`,

  imprintPrivacy9ListTwenty: "Accounting, fiduciary, and auditing companies;",
  imprintPrivacy9ListTwentyOne: "Consulting firms (legal advice, taxes, etc.);",
  imprintPrivacy9ListTwentyTwo: "IT service providers (web hosting, support, cloud services, website design, etc.);",
  imprintPrivacy9ListTwentyThree: " Payment service providers;",
  imprintPrivacy9ListTwentyFour: " Providers of tracking, conversion, and advertising services.",

  imprintPrivacySubTitle9ThreeContentTwoA:
    "We ensure that these third parties and our affiliated companies comply with data protection requirements and treat your personal data confidentially. We may also be required to disclose your personal data to authorities.",
  imprintPrivacyTitleB: "b. Disclosure to partners and cooperating companies",
  imprintPrivacySubTitle9ThreeContentThreeB:
    "We sometimes work with different companies and partners who post their offers on our website. It is recognizable for you that it is a third-party offer (marked as 'advertisement').",
  imprintPrivacySubTitle9ThreeContentFourB:
    "If you take advantage of such an offer, we transmit your personal data to the respective partner or cooperating company (e.g., name, function, communication, etc.) whose offer you want to take advantage of. These partners and cooperating companies are independently responsible for the personal data received. After the data has been transmitted, the data protection regulations of the respective partner apply.",
  imprintPrivacyTitleC: "c. Disclosure abroad",
  imprintPrivacySubTitle9ThreeContentFiveC:
    "In the context of order processing, it may be necessary to transmit your personal data to companies abroad. These companies are required to protect your data to the same extent as we do. The transmission can take place worldwide.",
  imprintPrivacySubTitle9ThreeContentSixC:
    "If the level of data protection does not correspond to that of the EEA area, we carry out a prior risk assessment and contractually ensure that the same protection as in the EEA area is guaranteed (e.g., by means of the EU Commission's standard contractual clauses or other legally prescribed measures). If our risk assessment is negative, we take additional technical measures to protect your data. You can access the EU Commission's standard contractual clauses at the following link.",

  imprintPrivacySubTitle9Four: "How long do we keep your data?",
  imprintPrivacySubTitle9FourContentOne:
    "We only store personal data for as long as necessary to fulfill the purposes for which the data was collected.",
  imprintPrivacySubTitle9FourContentTwo:
    "We store contract data longer because we are legally obliged to do so. In particular, we must retain business communications, concluded contracts, and booking receipts for up to 10 years. As far as we no longer need such data from you to perform services, the data will be restricted for further processing and we will only use them for accounting and tax purposes.",

  imprintPrivacyContentTitle10: "8. Individual Data Processing Operations",

  imprintPrivacySubTitle10One: "Provision of the Website and Creation of Log Files",
  imprintPrivacySubTitle10OneContentOne:
    "By simply visiting www.squib.app without registering or providing any information, only the data that your browser automatically transmits to our server is collected. This data is technically necessary for the operation of the website",
  imprintPrivacy10QuestionOne: "hat Data Do We Process?",
  imprintPrivacySubTitle10OneContentTwo:
    "Zur Bereitstellung der Webseite und bei der Erstellung von Logfiles werden insbesondere folgende Daten bearbeitet:",
  impringPrivacyUlText10One: "Name of the Internet service provider",
  impringPrivacyUlText10Two: " IP address",
  impringPrivacyUlText10Three: "Technical information such as browser, operating system, or screen resolution",
  impringPrivacyUlText10Four: "Date and time of access",
  impringPrivacyUlText10Five: "Referrer URL",

  imprintPrivacySubTitle10OneContentThree:
    "This data cannot be assigned to any specific person, and it is not merged with other data sources.",
  imprintPrivacy10QuestionTwo: "For What Purpose Do We Process the Data?",
  imprintPrivacySubTitle10OneContentFour:
    "The processing of log files is carried out to guarantee the functionality of the website and to ensure the security of our information technology systems.",
  imprintPrivacy10QuestionThree: "On What Basis Do We Process the Data?",
  imprintPrivacySubTitle10OneContentFive:
    "The legal basis for this data processing is our legitimate interest according to Art. 6 para. 1 lit. f GDPR. Our legitimate interests arise from the purpose of data processing.",
  imprintPrivacy10QuestionFour: "How Can You Prevent Data Processing?",
  imprintPrivacySubTitle10OneContentSix:
    "The data is only stored as long as necessary to achieve the purpose for which it was collected. Consequently, the data is deleted after each session ends. The storage of log files is mandatory for the operation of the website; therefore, you have no option to object unless you refrain from visiting our website",

  imprintPrivacySubTitle10Two: "Contact",
  imprintPrivacySubTitle10TwoContentOne:
    "You can contact us in various ways. If you contact us and provide personal data, this results in data processing by us. This includes any oral, written, and any other form of contacting us.",
  imprintPrivacy10QuestionFive: "What Data Do We Process?",
  imprintPrivacySubTitle10TwoContentTwo: "When you contact us, we process all data that you provide to us. This includes, in particular:",
  impringPrivacyUlText10Six: "Name",
  impringPrivacyUlText10Seven: " Email address",
  impringPrivacyUlText10Eight: " Content and timing of your contact",
  impringPrivacyUlText10Nine: " Contact details",

  imprintPrivacySubTitle10TwoContentThree:
    "In certain cases, you may be required to provide specific data to contact us, for example, when using a contact form or requesting a callback.",
  imprintPrivacy10QuestionSix: "For What Purpose Do We Process the Data?",
  imprintPrivacySubTitle10TwoContentFour:
    "The purpose for which we use the data depends on the nature of the contact. However, we never use the data for unforeseen or unexpected purposes. The most common purposes are communication and feedback, customer service, and handling business inquiries.",
  imprintPrivacy10QuestionSeven: "On What Basis Do We Process the Data?",
  imprintPrivacySubTitle10TwoContentFive:
    "The legal basis for this data processing is our legitimate interest according to Art. 6 para. 1 lit. f GDPR. Our legitimate interests arise from the purpose of data processing.",
  imprintPrivacy10QuestionEight: "To Whom Do We Pass the Data On?",
  imprintPrivacySubTitle10TwoContentSix:
    "The transfer of data by us is governed by our data sharing statements. If the purpose of your contact requires the transfer of your data to third parties, we will pass on the data to the necessary extent.",
  imprintPrivacy10QuestionNine: "How Can You Prevent Data Processing?",
  imprintPrivacySubTitle10TwoContentSeven:
    "If you contact us, data processing is unavoidable. Therefore, you must refrain from contacting us if you do not want your data to be processed.",

  imprintPrivacySubTitle10Three: "Cookies",
  imprintPrivacySubTitle10ThreeContentOne:
    "Our website uses cookies. Cookies are text files that are stored on your device's operating system by the browser when you visit our website. Cookies do not harm your computer and do not contain viruses. Some cookies are technically necessary for the website to function. Most of the cookies we use are 'session cookies,' which are automatically deleted after your visit. Other cookies remain stored on your device until you delete them or their lifetime expires.",
  imprintPrivacy10QuestionTen: "For What Purpose Do We Process the Data?",
  imprintPrivacySubTitle10ThreeContentTwo:
    "We use cookies to make our website more user-friendly, effective, and secure based on the data collected. We use cookies particularly for saving your preferences (e.g., language and location settings), quickly providing and attractively displaying website content (e.g., using fonts and content delivery networks), analyzing website usage for statistical evaluation, and continuous improvements (usually using third-party cookies). The purposes for which we use (non-essential) cookies are detailed in the subsequent sections of this privacy policy.",
  imprintPrivacy10QuestionEleven: "On What Legal Basis Do We Process the Data?",
  imprintPrivacySubTitle10ThreeContentThree:
    "The legal basis for this processing is your consent according to Art. 6 para. 1 lit. a GDPR. For technically necessary cookies, our legitimate interest according to Art. 6 para. 1 lit. f GDPR forms the legal basis.",
  imprintPrivacy10QuestionTwelve: "To Whom Do We Pass the Data On?",
  imprintPrivacySubTitle10ThreeContentFour:
    "The transfer of data by us is governed by our data sharing statements. The subsequent sections of this privacy policy provide further details on individual data processing activities.",
  imprintPrivacy10QuestionThirteen: "How Can You Prevent Data Processing?",
  imprintPrivacySubTitle10ThreeContentFive:
    "When you visit the website, a cookie banner is displayed. Cookies requiring your consent according to Art. 6 para. 1 lit. a GDPR are only activated after you give your consent. If you refuse consent, no data will be collected by the cookies that require your consent.",
  imprintPrivacySubTitle10ThreeContentSix:
    "The data collection by cookies that we use based on our legitimate interest according to Art. 6 para. 1 lit. f GDPR cannot be prevented through the cookie banner. These technically necessary cookies are stored on your computer. You can completely delete them or disable or restrict their transmission by changing the settings in your browser. Disabling these cookies may limit the functionality of certain services.",
  imprintPrivacy10UlHeader: "Instructions for the most common browsers can be found here:",
  impringPrivacyUlText10Ten: "For Google Chrome",
  impringPrivacyUlText10Eleven: "For Apple Safari",
  impringPrivacyUlText10Twelve: " For Microsoft Edge",
  impringPrivacyUlText10Thirteen: "For Mozilla Firefox",

  imprintPrivacySubTitle10ThreeContentSeven:
    "For cookies used for performance and reach measurement or advertising, general objections ('opt-out') can be made for numerous services via the Network Advertising Initiative (NAI), YourAdChoices (Digital Advertising Alliance), or Your Online Choices (European Interactive Digital Advertising Alliance, EDAA).",

  imprintPrivacySubTitle10Four: "Counting Pixels",
  imprintPrivacySubTitle10FourContentOne:
    "We may use counting pixels on our website or in our emails. Counting pixels, also known as web beacons, are small, usually invisible images automatically retrieved when you visit our website or open our emails.",
  imprintPrivacy10QuestionFourteen: "What Data Do We Process?",
  imprintPrivacySubTitle10FourContentTwo:
    "Counting pixels can collect the same information as log files. They can also capture movement profiles of the entire session. Counting pixels are particularly used by third parties whose services we utilize. Detailed information about these third-party services is provided in this privacy policy.",

  imprintPrivacy10QuestionFifteen: "For What Purpose Do We Process the Data?",
  imprintPrivacySubTitle10FourContentThree:
    "Counting pixels are used by various tracking services to analyze website usage and for statistical evaluation and continuous improvements. Counting pixels can also be used for email tracking.",

  imprintPrivacy10QuestionSixteen: "On What Legal Basis Do We Process the Data?",
  imprintPrivacySubTitle10FourContentFour: "The legal basis for the dissemination is your consent according to Art. 6 para. 1 lit. a GDPR.",

  imprintPrivacy10QuestionSeventeen: "To Whom Do We Pass the Data On?",
  imprintPrivacySubTitle10FourContentFive:
    "The transfer of data by us is governed by our data sharing statements. Further details on individual tracking services are provided in this privacy policy.",

  imprintPrivacy10QuestionEighteen: "How Can You Prevent Data Processing?",
  imprintPrivacySubTitle10FourContentSix:
    "To prevent data processing via counting pixels, you can install suitable browser extensions such as uBlockOrigin and block external graphics in your email program.",

  imprintPrivacySubTitle10Five: "Google Analytics",
  imprintPrivacySubTitle10FiveContentOne:
    "We use Google Analytics on our website, a service provided by Google Ireland Ltd., Google Building Gordon House, Barrow St, Dublin 4, Ireland, with headquarters at Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, together referred to as 'Google.'",
  imprintPrivacy10QuestionNineteen: "What Data Do We Process?",
  imprintPrivacySubTitle10FiveContentTwo:
    "Google Analytics is a website analysis service used to collect information about website usage. Specifically, the following information can be collected:",
  impringPrivacyUlText10Fourteen: "IP address",
  impringPrivacyUlText10Fifteen: "Technical information such as browser, operating system, or screen resolution",
  impringPrivacyUlText10Sixteen: " Interactions on the website",
  impringPrivacyUlText10Seventeen: "Duration of the visit",
  impringPrivacyUlText10Eighteen: "Time and date of website access",
  impringPrivacyUlText10Nineteen: "Referrer URL",

  imprintPrivacySubTitle10FiveContentThree:
    "The IP address is anonymized by Google Analytics, making it impossible to associate it with any person. When a visitor visits our website for the first time, Google Analytics can generate an identifier to recognize the visitor on subsequent visits. If you are logged into your Google account, data processing can also be cross-device.",

  imprintPrivacy10QuestionTwenty: "For What Purpose Do We Process the Data?",
  imprintPrivacySubTitle10FiveContentFour:
    "Your IP address is used to determine your approximate location. From the resulting information, we can measure the relevance of our offers in different regions. We also use the IP address to determine how visitors arrive at our website. The technical information is processed to ensure the website displays satisfactorily on every device. Interactions, duration, time, and date are collected to evaluate and optimize our marketing campaigns and offers. This data helps us determine how visitors interact with our website and which content is popular with which visitors. The referrer URL is processed to measure the effectiveness and analyze various marketing channels.",
  imprintPrivacy10QuestionTwentyOne: "To Whom Do We Pass the Data On?",
  imprintPrivacySubTitle10FiveContentFive:
    "The transfer of data by us is governed by our data sharing statements. As Google is a transnational company, your data can be transferred worldwide by Google, particularly to the USA, where the legislation may not provide adequate data protection.",

  imprintPrivacySubTitle10Six: "Google Maps",
  imprintPrivacySubTitle10SixContentOne:
    "We use Google Maps on our website, a service provided by Google Ireland Ltd., Google Building Gordon House, Barrow St, Dublin 4, Ireland, with headquarters at Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, together referred to as 'Google.'",
  imprintPrivacy10QuestionTwentyTwo: "What Data Do We Process?",
  imprintPrivacySubTitle10SixContentTwo:
    "Google Maps is a map service used to embed Google Maps on websites. Specifically, the following information can be collected:",
  impringPrivacyUlText10Twenty: "IP address",
  impringPrivacyUlText10TwentyOne: "Technical information such as browser, operating system, or screen resolution",
  impringPrivacyUlText10TwentyTwo: "Interactions with the map",

  imprintPrivacySubTitle10SixContentThree:
    "The IP address is anonymized by Google Maps, making it impossible to associate it with any person. If you have a Google account and are logged in, data processing by Google Maps can also be cross-device.",

  imprintPrivacy10QuestionTwentyThree: "For What Purpose Do We Process the Data?",
  imprintPrivacySubTitle10SixContentFour:
    "The IP address is processed to analyze the visitor's rough location to display the correct section when accessing the website. The technical information is processed to ensure the map displays appropriately on all devices. Interaction data is collected to use the map interactively and to improve map usage. If you are logged into your Google account, the data is synchronized across your devices. For example, markings and planned routes are synchronized between devices.",

  imprintPrivacy10QuestionTwentyFour: "To Whom Do We Pass the Data On?",
  imprintPrivacySubTitle10SixContentFive:
    "The transfer of data by us is governed by our data sharing statements. As Google is a transnational company, your data can be transferred worldwide by Google, particularly to the USA, where the legislation may not provide adequate data protection.",

  imprintPrivacySubTitle10Seven: "PrivacyBee",
  imprintPrivacySubTitle10SevenContentOne:
    "PrivacyBee is used on websites to detect all privacy-related services and generate an individual privacy policy from them.",
  imprintPrivacy10QuestionTwentyFive: "Does Our Privacy Policy Always Stay the Same?",
  imprintPrivacySubTitle10SevenContentTwo:
    "We may change this privacy policy at any time. Changes will be published on www.squib.app. You will not be informed separately",

  // Terms of Use Content
  termsOfUseTitle: "Terms of Use",
  termsOfUseMobileTitle: "Terms of Use",
  termsOfUseLastUpdated: "Last Updated: June 2, 2024",
  termsOfUseContent1One:
    "These terms of use apply to your access to and use of the services, websites, and apps provided by Squib, which you purchase or subscribe to as 'Service(s)' on the Squib websites.",
  termsOfUseContent1Two:
    "Wherever you see 'You,' 'Your,' 'User,' or 'Customer' in these terms of use, it refers to you, the user of Squib Services. The terms 'We,' 'Us,' and 'Squib' refer to Squib Ltd.",

  termsOfUseContentTitle1: "Inhalt",
  termsOfUseContentList1: "1. Agreement and Withdrawal of Agreement",
  termsOfUseContentList2: "2. Fees and Payments",
  termsOfUseContentList3: "3. Privacy",
  termsOfUseContentList4: "4. Your Content",
  termsOfUseContentList5: "5. Squib Intellectual Property",
  termsOfUseContentList6: "6. Third-Party Resources",
  termsOfUseContentList7: "7. Account Management",
  termsOfUseContentList8: "8. Suspension and Termination of Services",
  termsOfUseContentList9: "9. Changes and Updates",
  termsOfUseContentList10: "10. Disclaimers and Indemnification",
  termsOfUseContentList11: "11. Miscellaneous Terms",

  termsOfUseContentTitle2: "1. Agreement and Withdrawal of Agreement",
  termsOfUseContent2One:
    "The agreement for the services takes effect as soon as the user accepts our terms of use and, for paid services, upon payment. The agreement is automatically extended for another monthly or yearly billing period until the user cancels this automatic subscription. This cancellation can be done at any time on the account management page (more information in the sections 'Fees and Payments' and 'Suspension and Termination of Services').",

  termsOfUseContentTitle3: "2. Fees and Payments",
  termsOfUseSubTitle3One: "Service Fees",
  termsOfUseContent3One:
    "You agree to pay all fees for the paid services you purchase or use according to the prices and payment terms presented to you by Squib or the external payment processor 'Stripe Payments Europe Limited' (https://stripe.com/de-ch) at the time of purchase, which may be updated occasionally as per the section 'Price Adjustments' below. If applicable, billing will be done using the billing method you selected on your account management page. If you choose to pay the fee by credit card, you represent and warrant that the credit card details you provide are accurate and you will promptly notify 'Stripe Payments Europe Limited' of any changes. Unless provided otherwise in these terms or required by law, fees you pay are non-refundable.",
  termsOfUseContent3Two:
    "We are not responsible for the performance and security of 'Stripe Payments Europe Limited' and have no control over it. You agree to pay all fees for each purchase in accordance with the applicable payment terms.",
  termsOfUseSubTitle3Two: "Subscriptions",
  termsOfUseContent3Three:
    "Some of our services are billed on a subscription basis ('Subscriptions' or 'Pricing Plans'). There are yearly and monthly billing periods depending on the subscription plan you choose when purchasing a subscription. Your subscription automatically renews at the end of a billing period unless you cancel your subscription via your account management page; in this case, your subscription will be valid until the end of the billing period that started before the cancellation.",
  termsOfUseContent3Four:
    "If you cancel your subscription, you can access the service and export your data until the end of the relevant billing cycle. After that, you will no longer have access to the service for that subscription. If you no longer have an active subscription, your account will be downgraded to the Squib FREE plan (free). To close your account and terminate your agreement with us, please refer to the section 'Suspension and Termination of Services' below",
  termsOfUseContent3Five:
    "You are responsible for all taxes. You are liable for remitting any taxes in connection with your purchase and for the accuracy of your billing information.",

  termsOfUseSubTitle3Three: "Price Adjustments",
  termsOfUseContent3Six:
    "Squib is entitled to change the fees charged for the services at any time, provided that the change for services billed on a subscription basis takes effect only at the end of the current billing cycle of your subscription. Squib will inform you of price adjustments in advance. If you do not agree with the fee change, you can cancel your subscription before the change takes effect.",

  termsOfUseSubTitle3Four: "Response Limit per Subscription",
  termsOfUseContent3Seven:
    "Each subscription has a fixed response limit. The free subscription FREE allows up to 50 participants per survey. The paid PRO allows up to 1,000 participants per month, and the BUSINESS subscription up to 50,000 participants per month. If you reach the participant limit for your paid subscription within a billing cycle, no further responses will be recorded unless you switch to a higher subscription.",
  termsOfUseContent3Eight:
    "You agree that unused participant limits will not automatically apply to the next billing cycle. Unless otherwise specified, any overage fees incurred will be billed retrospectively via the stored payment method at the beginning of your next billing cycle. If overage fees are not paid within 30 days of billing, default occurs. If overage fees are not paid, the respective service may be restricted, suspended, or terminated (subject to applicable legal requirements), which may result in loss of data associated with that service.",

  termsOfUseContentTitle4: "3. Privacy",
  termsOfUseSubTitle4One: "Privacy",
  termsOfUseContent4One:
    "We understand that submitting your content (as defined below) to us involves trusting us to handle the content appropriately. The Squib privacy policy and its services address how we handle your content, which may be considered personal data (as defined in our Data Processing Agreement), and we agree to adhere to these Squib privacy policies. You, in turn, agree that Squib may use and share your content per the Squib privacy policy and applicable data protection laws. You further agree that you are responsible for notifying your respondents about the Squib privacy policies. In case of conflict between the Data Processing Agreement and these terms, the Data Processing Agreement prevails, except for the disclaimer and limitation of liability, for which these terms are decisive.",
  termsOfUseSubTitle4Two: "Confidentiality",
  termsOfUseContent4Two:
    "Squib treats your content as confidential information and will use and disclose it only per these terms of use (including the Squib privacy policies). However, your content is not considered confidential if: a) it becomes publicly known through no breach of these terms by Squib, b) Squib already knew it lawfully before you submitted it, c) Squib receives it from a third party without any known breach of obligations to you, d) it is released during the migration of your account to an enterprise account of an organization if your account is registered with a work email address from that organization, or e) it is independently developed by Squib without reference to your content. Squib may disclose your content as required by legal or regulatory obligations but will take economically reasonable steps under legal requirements to notify you first to enable you to challenge the obligation to disclose.",
  termsOfUseSubTitle4Three: "Security",
  termsOfUseContent4Three:
    "Squib stores and processes your content in a manner consistent with general security standards. Squib has implemented appropriate technical, organizational, and administrative systems, policies, and procedures.",
  termsOfUseContent4Four:
    "If Squib becomes aware of unauthorized access, acquisition, use, alteration, disclosure, or destruction of personal data (as defined in our Data Processing Agreement) related to your account ('Security Incident'), Squib will take reasonable measures to notify you promptly. Such notification shall not be interpreted or construed as an admission of fault or liability by Squib. A Security Incident does not include unsuccessful login attempts, pings, port scans, denial-of-service attacks, or other network attacks on firewalls or connected systems. Squib will also cooperate reasonably with you in investigating Security Incidents, providing required notifications and information to the extent reasonably requested by you, if such information is not directly available to you in your account or online through updates provided by Squib.",

  termsOfUseContentTitle5: "4. Your Content",
  termsOfUseSubTitle5One: "Ownership of Your Content",
  termsOfUseContent5One:
    "In using the services, you may submit content to Squib (including personal data from you and others), and third parties may submit content to you via the services (all of the foregoing being your 'Content'). You retain ownership of all copyright in your content. Squib does not claim any ownership rights in your content. These terms do not grant us any licenses or rights to your content except for the limited license set forth in these terms. When you use our services, you retain what you own and only grant us permission to use it as necessary to provide and improve our services as described in our privacy policies.",
  termsOfUseSubTitle5Two: "Representations and Warranties",
  termsOfUseContent5Two:
    "You represent and warrant that: (a) you own or control the appropriate rights to your content, including the intellectual property rights of third parties; and (b) you will not submit, upload, or otherwise provide any content or materials through the services that violate generally accepted acceptable use guidelines. This includes hate speech, bullying, discrimination of any minorities, harassment, and criminal activities, pornography, offensive images, and intellectual property violations.",

  termsOfUseSubTitle5Three: "Responsibility for Your Content",
  termsOfUseContent5Three:
    "You are responsible for your content. We are not responsible for what you do with your content and may refuse to display your content if we believe it is illegal or violates our terms. Per applicable law, Squib is not liable for your content, other content, or third-party materials or for any loss or damage resulting from the use of your content or other content or third-party materials or reliance on them.",

  termsOfUseContentTitle6: "5. Squib Intellectual Property",
  termsOfUseContent6One:
    "These terms and the use of the services do not grant you any ownership rights to the services or the content you access through the services, except for your own content. If you provide us with feedback or suggestions about our services, we may use them without compensation to you and without obligation to you for any purpose.",

  termsOfUseContentTitle7: "6. Third-Party Resources",
  termsOfUseContent7One:
    "Squib may post links to websites operated by third parties on its services. Squib does not guarantee that it has reviewed such third-party websites and is not responsible for them or their content. Trademarks displayed in connection with the services are the property of their respective owners.",

  termsOfUseContentTitle8: "7. Account Management",
  termsOfUseSubTitle8One: "Password-, Email- and Account Data Security",
  termsOfUseSubTitle8OneMobile: "Password-, Email-\nand Account Data-\nSecurity",
  termsOfUseContent8One:
    "We continuously work to maintain the security of your account. You must create a customer account with a secure password to use our services. Never share passwords. You, not Squib, are responsible for all activities occurring in your account (except those for which Squib is directly responsible and not performed at your instruction), whether or not you authorized such activities.",
  termsOfUseContent8Two:
    "Keep your email and account data up to date. Squib occasionally sends notices to the email address registered for your account. It is your responsibility to keep your email address and any other contact and payment data associated with your account accurate and current. You warrant that your account details are accurate.",
  termsOfUseSubTitle8Two: "Account Inactivity",
  termsOfUseContent8Three:
    "Squib is entitled to close your account and delete all content therein if no account activity (e.g., login events or payments) has occurred for more than 12 months. If appropriate, we will attempt to warn you via email before closing your account due to inactivity to allow you to log into your account to keep it active.",

  termsOfUseContentTitle9: "8. Suspension and Termination of Services",
  termsOfUseSubTitle9One: "By You",
  termsOfUseContent9One:
    "You can cancel your subscription at any time via the account management page. We do not offer refunds for payments already made. The exception is if the termination is due to a material breach of contract by us or if a refund is legally required. We reserve the right to delete all your data, including all surveys and survey results, without notice 30 days after the termination of your subscription.",
  termsOfUseSubTitle9Two: "By Squib",
  termsOfUseContent9Two:
    "Squib may terminate your subscription without a refund for a prior period effective at the end of a billing cycle by providing you with at least 30 days' written notice.",
  termsOfUseContent9Three:
    "Squib is entitled to terminate your subscription for any of the following reasons: I) you have materially breached these terms, II) you do not pay fees within 30 days of the due date, III) you use the services in a way that creates liability for us or prevents third parties from using the services, or IV) we are required to do so by applicable law. Furthermore, if we deactivate or terminate your subscription and/or discontinue the services, we will make reasonable efforts to notify you in advance and allow you to export a copy of your content from this service, depending on the reason. However, urgent situations may arise where Squib decides to take immediate action without prior notice.",
  termsOfUseContent9Four:
    "Surveys and results remain stored and accessible for PRO customers for two months and BUSSINES customers for four years. In the event of Squib Ltd.'s insolvency, we reserve the right to adjust these access periods. In such a scenario, Squib commits to informing affected customers at least 30 days in advance in writing.",

  termsOfUseContentTitle10: "9. Changes and Updates",
  termsOfUseSubTitle10One: "Changes to Terms and Services",
  termsOfUseContent10One:
    "Squib may change these terms at any time for various reasons, such as reflecting changes in applicable laws or updates to the services and to consider new services or features, for security reasons, or to prevent abuse or harm. Therefore, Squib is entitled to add functions to a service it provides to you at any time without prior notice or to change existing functions in the service. Squib also reserves the right to limit, suspend, or discontinue a service it provides to you at its discretion. If Squib discontinues a service, we will notify you in advance within a reasonable period to allow you to export a copy of your content from this service.",
  termsOfUseSubTitle10Two: "Downgrades",
  termsOfUseContent10Two:
    "Downgrading your account plan (cancelling a subscription or downgrading to our free pricing plan) may result in the loss of content, features, functionality, or account capacity.",

  termsOfUseContentTitle11: "10. Disclaimers and Indemnification",
  termsOfUseSubTitle11One: "Disclaimers",
  termsOfUseContent11One:
    "Squib strives to provide you with an optimal user experience of our online services, which, however, may temporarily fail for various reasons. To the extent permitted by applicable law and unless otherwise specified in these terms, we provide the services and related consultations without any express, implied, or statutory warranties, including, but not limited to, warranties of merchantability, fitness for a particular purpose, non-infringement, availability, reliability, or accuracy. Within the legal framework, Squib is not liable for indirect, consequential, special, incidental, or punitive damages or losses, including loss of use, data loss, loss of business or profits, arising from the use of the services or under these terms, regardless of the legal basis, even if informed of the possibility of such damages.",
  termsOfUseSubTitle11Two: "Indemnification",
  termsOfUseContent11Two:
    "If you are a company, you agree to indemnify Squib (including its officers, representatives, and employees) from any liability, damages, and costs (including settlement costs and reasonable attorney's fees) arising from third-party claims related to or in connection with your use of the services or your or your end-users' breach of these terms, to the extent such liabilities, damages, and costs were caused by you or your end-users.",

  termsOfUseContentTitle12: "11. Miscellaneous Terms",
  termsOfUseSubTitle12One: "Assignment",
  termsOfUseContent12One:
    "You may not assign these terms without Squib's prior written consent, which Squib may withhold at its sole discretion. Squib may assign these terms at any time without notice to you",

  termsOfUseSubTitle12Two: "Independent Contractors",
  termsOfUseContent12Two:
    "You and Squib operate as independent contractors, and neither party has the status of a partner, employee, or agent of the other party.",

  termsOfUseSubTitle12Three: "Interpretation",
  termsOfUseContent12Three:
    "The terms 'including,' 'such as,' 'for example,' and similar terms are not intended to limit the content further.",

  termsOfUseSubTitle12Four: "Language",
  termsOfUseContent12Four:
    "These terms have been drafted and prepared in the German language. To the extent translated versions conflict with the German version, the German version shall prevail to the extent permitted by applicable law.",

  termsOfUseSubTitle12Five: "No Waiver",
  termsOfUseContent12Five:
    "If a party waives the enforcement of a provision of these terms or delays exercising it, it does not waive its rights to enforce it later.",

  termsOfUseSubTitle12Six: "Severability",
  termsOfUseContent12Six:
    "If a provision of these terms is declared unenforceable by a competent court, the provision shall be deemed removed from these terms, and the other provisions shall remain fully in force.",

  termsOfUseSubTitle12Seven: "No Third-Party Beneficiaries",
  termsOfUseContent12Seven: "There are no third-party beneficiaries to these terms.",

  termsOfUseSubTitle12Eight: "Beta Services",
  termsOfUseContent12Eight:
    "Squib may offer certain products or features as test, alpha, pre-beta, or beta versions for early access or as a general beta version ('Beta Service'). If you use a Beta Service, the following additional terms apply:",
  termsOfUseContent12Nine:
    "A Beta Service is offered for testing and evaluation purposes. It is not the final product or service and may contain bugs that can cause system failures or other disruptions, errors, and data loss. Squib may decide not to release a commercial version of the Beta Service. Squib may remove or discontinue the Beta Service at its discretion and release a commercial version of the Beta Service. You must immediately stop using the Beta Service if we request you to do so. In return for using a Beta Service, you agree that Squib collects data regarding your use of the Beta Service, including feedback, and analyzes your content (including manual reviews) to improve the service and fix errors. If you do not want this usage or feedback data to be collected or the content to be analyzed, you must immediately stop using the Beta Service.",
  termsOfUseContent12Ten:
    "Squib makes no representations or warranties regarding Beta Services, including no warranty that a Beta Service will be generally available, will work uninterrupted or error-free, or that your content will be secure or not lost or damaged. Squib assumes no liability arising from or in connection with a Beta Service. Any use of a Beta Service by you is at your own risk and discretion.",

  // Metadata Content
  metadataContentHome:
    "Squib - the easy and reliable way to get feedback. Create attractive surveys with which you are guaranteed to get feedback quickly. Simply clever. Professional. With your logo. Try it now for free.",
  metadataContentExplore:
    "Why Squib? With Squib you collect feedback more successfully than before. Thanks to unique user-friendliness that motivates target groups to participate.",
  metadataContentUseCases:
    "Squib - Use Cases Anything goes: involve employees, survey customers, ask students for feedback, involve online community, etc.",
  metadataContentContact: "Squib - Contact Now. Contact form for all your questions, feedback and stakeholder management.",
  metadataContentImprintPrivacy:
    "Squib - Imprint and Privacy We take the protection of your personal data very seriously! Privacy notices, individual rights, and third-party services.",
};
