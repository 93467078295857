import React from "react";
import PricingFeaturesHeaderText from "./pricing-features-header-text/PricingFeaturesHeaderText";
import HeaderPlan from "./header-plan/HeaderPlan";
import PricingFeaturesPlan from "./pricing-features-plan/PricingFeaturesPlan";
import GetInTouchPricingFeatures from "./getintouch-pricing-features/GetInTouchPricingFeatures";
import "../../../../resources/styles/pages/pricing-features.css";
import { useTranslation } from "react-i18next";
import MetaData from "../common/metadata/MetaData";

const PricingFeatures = () => {
  const { t } = useTranslation("translation");
  const mdText = t("pricingFeatures", { ns: "translation" });

  return (
    <>
      <MetaData title={mdText} />
      <div className="pricing-features-comp-container">
        <PricingFeaturesHeaderText />
        <HeaderPlan />
        <PricingFeaturesPlan />
        <GetInTouchPricingFeatures />
      </div>
    </>
  );
};

export default PricingFeatures;
