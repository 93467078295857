import React from "react";
import images from "../../../../../resources/images";

interface MarketEnvPositionProps {
  headerTitle: string;
  title: string;
  description: React.ReactNode;
}

const marketEnvPositionData: MarketEnvPositionProps = {
  headerTitle: "Positionierung im Marktumfeld",
  title: "Lorem ipsum dolor sit amet.",
  description: (
    <div>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
        erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
        erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
      </p>
    </div>
  ),
};

const MarketEnvPosition = () => {
  return (
    <div className="market-env-position-container">
      <h1>{marketEnvPositionData.headerTitle}</h1>

      <div className="market-env-position-wrapper">
        <div className="market-env-position-content">
          <h2>{marketEnvPositionData.title}</h2>
          <p>{marketEnvPositionData.description}</p>
        </div>
        <div className="scientific-nature-wrapper">
          <p className="scientific-nature-text">Wissenschaftlich</p>
          <p className="spass-text">Spass</p>
          <div className="scientific-nature-content">
            <div className="market-box-one">
              <div className="first-position area">
                <div className="point-one market-point"></div>
                <p>SurveyMonkey</p>
              </div>
              <div className="second-position area">
                <div className="point-two market-point"></div>
                <p>Findmind</p>
              </div>
            </div>
            <div className="market-box-two">
              <div className="third-position area">
                <div className="point-three market-point"></div>
                <p>Mentimeter</p>
              </div>
              <div className="fourth-position area">
                <div className="point-four market-point"></div>
                <p>StrawPoll</p>
              </div>
            </div>
            <div className="market-box-three">
              <div className="fifth-position area">
                <div className="point-five market-point"></div>
                <p>Appinio</p>
              </div>
              <div className="sixth-position area">
                <div className="point-six market-point"></div>
                <p>Typeform</p>
              </div>
              <div className="seventh-position area">
                <div className="point-seven market-point"></div>
                <p>involve.me</p>
              </div>
            </div>
            <div className="market-box-four">
              <div className="eighth-position area">
                <img src={images.squibIcon} alt="squib-logo" />
                <p>Squib</p>
              </div>
              <div className="ninth-position area">
                <div className="point-nine market-point"></div>
                <p>Google Forms</p>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="519" height="454" viewBox="0 0 519 454" fill="none">
              <path
                d="M1 210.5C202.901 210.5 316.099 210.5 518 210.5M259.5 1V453"
                stroke="#9B9B9B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeDasharray="8 8"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketEnvPosition;
