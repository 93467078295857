import squibTeaser from "./squib-teaser.mp4";
import squibTeaserDE from "./squib-teaser-DE.mp4";

interface IVideos {
  [key: string]: string;
}

const video: IVideos = {
  squibTeaser,
  squibTeaserDE,
};

export default video;
