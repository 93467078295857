import React from "react";

const HeaderPlan = () => {
  return (
    <div className="header-plan-container">
      <div className="header-plan-wrapper">
        <div className="plan-monthly">
          <p>Monthly</p>
        </div>
        <div className="plan-yearly">
          <div>
            <p>Yearly</p>
          </div>
          <div className="percentoff">
            <span>25% OFF</span>
          </div>
        </div>
      </div>
      <p className="cost">The costs are charged to your credit card on a monthly basis. Termination possible at the end of each month.</p>
    </div>
  );
};

export default HeaderPlan;
