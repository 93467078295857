import React from "react";
import ReleasesLayout from "../../layout/ReleasesLayout";

const FeatureOne = () => {
  return (
    <ReleasesLayout
      title="Create multivotings"
      description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
      date="21.März 2023"
      versions={["Pro"]}
    />
  );
};

export default FeatureOne;
