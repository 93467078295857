import { TFunction } from "i18next";

interface ITranslationVariables {
  t: TFunction;
}

export const imprintPrivacyTranslationVariables = ({ t }: ITranslationVariables) => ({
  mdText: t("imprintPrivacy", { ns: "translation" }),

  headerContactInfo: t("headerContactInfo", { ns: "translation" }),

  title1: t("imprintPrivacyContentTitle1", { ns: "translation" }),
  contentTitle: t("imprintPrivacyContentTitle", { ns: "translation" }),

  // First block - small Squib intro
  content1One: t("imprintPrivacyContent1One", { ns: "translation" }),
  content1Two: t("imprintPrivacyContent1Two", { ns: "translation" }),
  content1Three: t("imprintPrivacyContent1Three", { ns: "translation" }),

  // Second block - Inhalt
  title2: t("imprintPrivacyContentTitle2", { ns: "translation" }),
  content2One: t("imprintPrivacyContent2One", { ns: "translation" }),
  content2Two: t("imprintPrivacyContent2Two", { ns: "translation" }),
  content2Three: t("imprintPrivacyContent2Three", { ns: "translation" }),

  // Third block - Was tun wir?
  title3: t("imprintPrivacyContentTitle3", { ns: "translation" }),
  content3One: t("imprintPrivacyContent3One", { ns: "translation" }),
  content3Two: t("imprintPrivacyContent3Two", { ns: "translation" }),

  // Fourth block - Über was informieren wir?
  title4: t("imprintPrivacyContentTitle4", { ns: "translation" }),

  // Fifth block - Begriffsdefinitionen
  title5: t("imprintPrivacyContentTitle5", { ns: "translation" }),
  subContentTitle5One: t("imprintPrivacySubTitle5One", { ns: "translation" }),
  content5ParagOne: t("imprintPrivacyContent5One", { ns: "translation" }),
  subContentTitle5Two: t("imprintPrivacySubTitle5Two", { ns: "translation" }),
  content5ParagTwo: t("imprintPrivacy5Content1Two", { ns: "translation" }),
  content5ParagThree: t("imprintPrivacy5Content3Two", { ns: "translation" }),
  content5ParagFour: t("imprintPrivacy5Content1Three", { ns: "translation" }),
  content5ParagFive: t("imprintPrivacy5Content1Four", { ns: "translation" }),
  subContentTitle5Three: t("imprintPrivacySubTitle5Three", { ns: "translation" }),
  subContentTitle5Four: t("imprintPrivacySubTitle5Four", { ns: "translation" }),

  // Sixth block - Kontakt
  title6: t("imprintPrivacyContentTitle6", { ns: "translation" }),
  content6: t("imprintPrivacyContent6", { ns: "translation" }),

  // Seventh block - Datensicherheit
  title7: t("imprintPrivacyContentTitle7", { ns: "translation" }),
  content7One: t("imprintPrivacyContent7One", { ns: "translation" }),

  // Eighth block - Betroffenenrechte
  title8: t("imprintPrivacyContentTitle8", { ns: "translation" }),
  subContentTitle8One: t("imprintPrivacySubTitle8One", { ns: "translation" }),
  paragOne8One: t("imprintPrivacyContent8One", { ns: "translation" }),
  paragOne8Two: t("imprintPrivacyContent8Two", { ns: "translation" }),
  paragOne8Three: t("imprintPrivacyContent8Three", { ns: "translation" }),
  paragOne8Four: t("imprintPrivacyContent8Four", { ns: "translation" }),
  paragOne8Five: t("imprintPrivacyContent8Five", { ns: "translation" }),

  subContentTitle8Two: t("imprintPrivacySubTitle8Two", { ns: "translation" }),
  paragTwo8Six: t("imprintPrivacySubTitle8TwoContentOne", { ns: "translation" }),
  paragTwo8Seven: t("imprintPrivacySubTitle8TwoContentTwo", { ns: "translation" }),

  subContentTitle8Three: t("imprintPrivacySubTitle8Three", { ns: "translation" }),
  paragTwo8Eght: t("imprintPrivacySubTitle8ThreeContentOne", { ns: "translation" }),
  paragTwo8Nine: t("imprintPrivacySubTitle8ThreeContentTwo", { ns: "translation" }),

  subContentTitle8Four: t("imprintPrivacySubTitle8Four", { ns: "translation" }),
  paragTwo8Ten: t("imprintPrivacySubTitle8FourContentOne", { ns: "translation" }),

  subContentTitle8Five: t("imprintPrivacySubTitle8Five", { ns: "translation" }),
  paragTwo8Eleven: t("imprintPrivacySubTitle8FiveContentOne", { ns: "translation" }),
  paragTwo8Twelve: t("imprintPrivacySubTitle8FiveContentTwo", { ns: "translation" }),
  paragTwo8Thirteen: t("imprintPrivacySubTitle8FiveContentOneThree", { ns: "translation" }),

  subContentTitle8Six: t("imprintPrivacySubTitle8Six", { ns: "translation" }),
  paragTwo8Fourteen: t("imprintPrivacySubTitle8SixContentOne", { ns: "translation" }),

  // Ninth block - Allgemeine Grundsätze
  title9: t("imprintPrivacyContentTitle9", { ns: "translation" }),

  subContentTitle9One: t("imprintPrivacySubTitle9One", { ns: "translation" }),
  paragOne9One: t("imprintPrivacySubTitle9OneContentOne", { ns: "translation" }),
  paragOne9Two: t("imprintPrivacySubTitle9OneContentTwo", { ns: "translation" }),

  subContentTitle9Two: t("imprintPrivacySubTitle9Two", { ns: "translation" }),
  paragTwo9Three: t("imprintPrivacySubTitle9TwoContentOne", { ns: "translation" }),
  paragTwo9Four: t("imprintPrivacySubTitle9TwoContentTwo", { ns: "translation" }),
  paragTwo9Five: t("imprintPrivacySubTitle9TwoContentThree", { ns: "translation" }),
  paragTwo9Six: t("imprintPrivacySubTitle9TwoContentFour", { ns: "translation" }),

  subContentTitle9Three: t("imprintPrivacySubTitle9Three", { ns: "translation" }),
  textA: t("imprintPrivacyTitleA", { ns: "translation" }),
  paragTwo9Seven: t("imprintPrivacySubTitle9ThreeContentOneA", { ns: "translation" }),
  paragTwo9Eight: t("imprintPrivacySubTitle9ThreeContentTwoA", { ns: "translation" }),
  textB: t("imprintPrivacyTitleB", { ns: "translation" }),
  paragTwo9Nine: t("imprintPrivacySubTitle9ThreeContentThreeB", { ns: "translation" }),
  paragTwo9Ten: t("imprintPrivacySubTitle9ThreeContentFourB", { ns: "translation" }),
  textC: t("imprintPrivacyTitleC", { ns: "translation" }),
  paragTwo9Eleven: t("imprintPrivacySubTitle9ThreeContentFiveC", { ns: "translation" }),
  paragTwo9Twelve: t("imprintPrivacySubTitle9ThreeContentSixC", { ns: "translation" }),

  subContentTitle9Four: t("imprintPrivacySubTitle9Four", { ns: "translation" }),
  paragTwo9Thirteen: t("imprintPrivacySubTitle9FourContentOne", { ns: "translation" }),
  paragTwo9Fourteen: t("imprintPrivacySubTitle9FourContentTwo", { ns: "translation" }),

  // Tenth block - Einzelne Datenverarbeitungsvorgänge
  title10: t("imprintPrivacyContentTitle10", { ns: "translation" }),
  subContentTitle10One: t("imprintPrivacySubTitle10One", { ns: "translation" }),

  // Question 1
  paragOne10One: t("imprintPrivacySubTitle10OneContentOne", { ns: "translation" }),
  question10One: t("imprintPrivacy10QuestionOne", { ns: "translation" }),
  paragOne10Two: t("imprintPrivacySubTitle10OneContentTwo", { ns: "translation" }),
  paragOne10Three: t("imprintPrivacySubTitle10OneContentThree", { ns: "translation" }),

  // Question 2
  question10Two: t("imprintPrivacy10QuestionTwo", { ns: "translation" }),
  paragOne10Four: t("imprintPrivacySubTitle10OneContentFour", { ns: "translation" }),

  // Question 3
  question10Three: t("imprintPrivacy10QuestionThree", { ns: "translation" }),
  paragOne10Five: t("imprintPrivacySubTitle10OneContentFive", { ns: "translation" }),

  // Question 4
  question10Four: t("imprintPrivacy10QuestionFour", { ns: "translation" }),
  paragOne10Six: t("imprintPrivacySubTitle10OneContentSix", { ns: "translation" }),

  // --- Kontakt --- //
  subContentTitle10Two: t("imprintPrivacySubTitle10Two", { ns: "translation" }),
  paragOne10Seven: t("imprintPrivacySubTitle10TwoContentOne", { ns: "translation" }),

  // Question 1
  question10Five: t("imprintPrivacy10QuestionFive", { ns: "translation" }),
  paragOne10Eight: t("imprintPrivacySubTitle10TwoContentTwo", { ns: "translation" }),
  paragOne10Nine: t("imprintPrivacySubTitle10TwoContentThree", { ns: "translation" }),

  // Question 2
  question10Six: t("imprintPrivacy10QuestionSix", { ns: "translation" }),
  paragOne10Ten: t("imprintPrivacySubTitle10TwoContentFour", { ns: "translation" }),

  // Question 3
  question10Seven: t("imprintPrivacy10QuestionSeven", { ns: "translation" }),
  paragOne10Eleven: t("imprintPrivacySubTitle10TwoContentFive", { ns: "translation" }),

  // Question 4
  question10Eight: t("imprintPrivacy10QuestionEight", { ns: "translation" }),
  paragOne10Twelve: t("imprintPrivacySubTitle10TwoContentSix", { ns: "translation" }),

  // Question 5
  question10Nine: t("imprintPrivacy10QuestionNine", { ns: "translation" }),
  paragOne10Thirteen: t("imprintPrivacySubTitle10TwoContentSeven", { ns: "translation" }),

  // --- Cookies --- //
  subContentTitle10Three: t("imprintPrivacySubTitle10Three", { ns: "translation" }),
  paragOne10Fourteen: t("imprintPrivacySubTitle10ThreeContentOne", { ns: "translation" }),
  question10Ten: t("imprintPrivacy10QuestionTen", { ns: "translation" }),
  paragOne10Fifteen: t("imprintPrivacySubTitle10ThreeContentTwo", { ns: "translation" }),
  question10Eleven: t("imprintPrivacy10QuestionEleven", { ns: "translation" }),
  paragOne10Sixteen: t("imprintPrivacySubTitle10ThreeContentThree", { ns: "translation" }),
  question10Twelve: t("imprintPrivacy10QuestionTwelve", { ns: "translation" }),
  paragOne10Seventeen: t("imprintPrivacySubTitle10ThreeContentFour", { ns: "translation" }),
  question10Thirteen: t("imprintPrivacy10QuestionThirteen", { ns: "translation" }),
  paragOne10Eighteen: t("imprintPrivacySubTitle10ThreeContentFive", { ns: "translation" }),
  paragOne10Nineteen: t("imprintPrivacySubTitle10ThreeContentSix", { ns: "translation" }),
  ul10Header: t("imprintPrivacy10UlHeader", { ns: "translation" }),
  paragOne10Twenty: t("imprintPrivacySubTitle10ThreeContentSeven", { ns: "translation" }),

  // --- Zählpixel --- //
  subContentTitle10Four: t("imprintPrivacySubTitle10Four", { ns: "translation" }),
  paragOne10TwentyOne: t("imprintPrivacySubTitle10FourContentOne", { ns: "translation" }),
  question10Fourteen: t("imprintPrivacy10QuestionFourteen", { ns: "translation" }),
  paragOne10TwentyTwo: t("imprintPrivacySubTitle10FourContentTwo", { ns: "translation" }),
  question10Fifteen: t("imprintPrivacy10QuestionFifteen", { ns: "translation" }),
  paragOne10TwentyThree: t("imprintPrivacySubTitle10FourContentThree", { ns: "translation" }),
  question10Sixteen: t("imprintPrivacy10QuestionSixteen", { ns: "translation" }),
  paragOne10TwentyFour: t("imprintPrivacySubTitle10FourContentFour", { ns: "translation" }),
  question10Seventeen: t("imprintPrivacy10QuestionSeventeen", { ns: "translation" }),
  paragOne10TwentyFive: t("imprintPrivacySubTitle10FourContentFive", { ns: "translation" }),
  question10Eighteen: t("imprintPrivacy10QuestionEighteen", { ns: "translation" }),
  paragOne10TwentySix: t("imprintPrivacySubTitle10FourContentSix", { ns: "translation" }),

  // --- Google Analytics --- //
  subContentTitle10Five: t("imprintPrivacySubTitle10Five", { ns: "translation" }),
  paragOne10TwentySeven: t("imprintPrivacySubTitle10FiveContentOne", { ns: "translation" }),
  question10Nineteen: t("imprintPrivacy10QuestionNineteen", { ns: "translation" }),
  paragOne10TwentyEight: t("imprintPrivacySubTitle10FiveContentTwo", { ns: "translation" }),
  paragOne10TwentyNine: t("imprintPrivacySubTitle10FiveContentThree", { ns: "translation" }),
  question10Twenty: t("imprintPrivacy10QuestionTwenty", { ns: "translation" }),
  paragOne10Thirty: t("imprintPrivacySubTitle10FiveContentFour", { ns: "translation" }),
  question10TwentyOne: t("imprintPrivacy10QuestionTwentyOne", { ns: "translation" }),
  paragOne10ThirtyOne: t("imprintPrivacySubTitle10FiveContentFive", { ns: "translation" }),

  // --- Google Maps --- //
  subContentTitle10Six: t("imprintPrivacySubTitle10Six", { ns: "translation" }),
  paragOne10ThirtyTwo: t("imprintPrivacySubTitle10SixContentOne", { ns: "translation" }),
  question10TwentyTwo: t("imprintPrivacy10QuestionTwentyTwo", { ns: "translation" }),
  paragOne10ThirtyThree: t("imprintPrivacySubTitle10SixContentTwo", { ns: "translation" }),
  paragOne10ThirtyFour: t("imprintPrivacySubTitle10SixContentThree", { ns: "translation" }),
  question10TwentyThree: t("imprintPrivacy10QuestionTwentyThree", { ns: "translation" }),
  paragOne10ThirtyFive: t("imprintPrivacySubTitle10SixContentFour", { ns: "translation" }),
  question10TwentyFour: t("imprintPrivacy10QuestionTwentyFour", { ns: "translation" }),
  paragOne10ThirtySix: t("imprintPrivacySubTitle10SixContentFive", { ns: "translation" }),

  // --- PrivacyBee --- //
  subContentTitle10Seven: t("imprintPrivacySubTitle10Seven", { ns: "translation" }),
  paragOne10ThirtySeven: t("imprintPrivacySubTitle10SevenContentOne", { ns: "translation" }),
  question10TwentyFive: t("imprintPrivacy10QuestionTwentyFive", { ns: "translation" }),
  paragOne10ThirtyEight: t("imprintPrivacySubTitle10SevenContentTwo", { ns: "translation" }),

  // --- Inhalt List --- //
  inhaltList: [
    {
      id: 1,
      title: t("imprintPrivacyContentListOne", { ns: "translation" }),
    },
    {
      id: 2,
      title: t("imprintPrivacyContentListTwo", { ns: "translation" }),
    },
    {
      id: 3,
      title: t("imprintPrivacyContentListThree", { ns: "translation" }),
    },
    {
      id: 4,
      title: t("imprintPrivacyContentListFour", { ns: "translation" }),
    },
    {
      id: 5,
      title: t("imprintPrivacyContentListFive", { ns: "translation" }),
    },
    {
      id: 6,
      title: t("imprintPrivacyContentListSix", { ns: "translation" }),
    },
    {
      id: 7,
      title: t("imprintPrivacyContentListSeven", { ns: "translation" }),
    },
    {
      id: 8,
      title: t("imprintPrivacyContentListEight", { ns: "translation" }),
    },
  ],

  // --- 2. Über was informieren wir? --- //
  uberInformierenList: [
    {
      id: 1,
      title: t("imprintPrivacyInformListOne", { ns: "translation" }),
    },
    {
      id: 2,
      title: t("imprintPrivacyInformListTwo", { ns: "translation" }),
    },
    {
      id: 3,
      title: t("imprintPrivacyInformListThree", { ns: "translation" }),
    },
    {
      id: 4,
      title: t("imprintPrivacyInformListFour", { ns: "translation" }),
    },
    {
      id: 5,
      title: t("imprintPrivacyInformListFive", { ns: "translation" }),
    },
    {
      id: 6,
      title: t("imprintPrivacyInformListSix", { ns: "translation" }),
    },
    {
      id: 7,
      title: t("imprintPrivacyInformListSeven", { ns: "translation" }),
    },
  ],

  // --- Content 5 List --- //
  content5List: [
    {
      id: 1,
      content: t("imprintPrivacyContent5ListOne", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("imprintPrivacyContent5ListTwo", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("imprintPrivacyContent5ListThree", { ns: "translation" }),
    },
    {
      id: 4,
      content: t("imprintPrivacyContent5ListFour", { ns: "translation" }),
    },
  ],

  // --- Allgemeine Grundsätze List --- //
  allgaumeineGrundsatzeList1: [
    {
      id: 1,
      content: t("imprintPrivacy9ListOne", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("imprintPrivacy9ListTwo", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("imprintPrivacy9ListThree", { ns: "translation" }),
    },
    {
      id: 4,
      content: t("imprintPrivacy9ListFour", { ns: "translation" }),
    },
    {
      id: 5,
      content: t("imprintPrivacy9ListFive", { ns: "translation" }),
    },
  ],

  allgaumeineGrundsatzeList2: [
    {
      id: 1,
      content: t("imprintPrivacy9ListSix", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("imprintPrivacy9ListSeven", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("imprintPrivacy9ListEight", { ns: "translation" }),
    },
    {
      id: 4,
      content: t("imprintPrivacy9ListNine", { ns: "translation" }),
    },
    {
      id: 5,
      content: t("imprintPrivacy9ListTen", { ns: "translation" }),
    },
    {
      id: 6,
      content: t("imprintPrivacy9ListEleven", { ns: "translation" }),
    },
    {
      id: 7,
      content: t("imprintPrivacy9ListTwelve", { ns: "translation" }),
    },
    {
      id: 8,
      content: t("imprintPrivacy9ListThirteen", { ns: "translation" }),
    },
  ],

  allgaumeineGrundsatzeList3: [
    {
      id: 1,
      content: t("imprintPrivacy9ListFourteen", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("imprintPrivacy9ListFifteen", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("imprintPrivacy9ListSixteen", { ns: "translation" }),
    },
    {
      id: 4,
      content: t("imprintPrivacy9ListSeventeen", { ns: "translation" }),
    },
    {
      id: 5,
      content: t("imprintPrivacy9ListEighteen", { ns: "translation" }),
    },
    {
      id: 6,
      content: t("imprintPrivacy9ListNineteen", { ns: "translation" }),
    },
  ],

  allgaumeineGrundsatzeList4: [
    {
      id: 1,
      content: t("imprintPrivacy9ListTwenty", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("imprintPrivacy9ListTwentyOne", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("imprintPrivacy9ListTwentyTwo", { ns: "translation" }),
    },
    {
      id: 4,
      content: t("imprintPrivacy9ListTwentyThree", { ns: "translation" }),
    },
    {
      id: 5,
      content: t("imprintPrivacy9ListTwentyFour", { ns: "translation" }),
    },
  ],

  // 8. Einzelne Datenverarbeitungsvorgänge - Lists
  topic8List1: [
    {
      id: 1,
      content: t("impringPrivacyUlText10One", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("impringPrivacyUlText10Two", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("impringPrivacyUlText10Three", { ns: "translation" }),
    },
    {
      id: 4,
      content: t("impringPrivacyUlText10Four", { ns: "translation" }),
    },
    {
      id: 5,
      content: t("impringPrivacyUlText10Five", { ns: "translation" }),
    },
  ],

  topic8List2: [
    {
      id: 1,
      content: t("impringPrivacyUlText10Six", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("impringPrivacyUlText10Seven", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("impringPrivacyUlText10Eight", { ns: "translation" }),
    },
    {
      id: 4,
      content: t("impringPrivacyUlText10Nine", { ns: "translation" }),
    },
  ],

  topic8List3: [
    {
      id: 1,
      content: t("impringPrivacyUlText10Ten", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("impringPrivacyUlText10Eleven", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("impringPrivacyUlText10Twelve", { ns: "translation" }),
    },
    {
      id: 4,
      content: t("impringPrivacyUlText10Thirteen", { ns: "translation" }),
    },
  ],

  topic8List4: [
    {
      id: 1,
      content: t("impringPrivacyUlText10Fourteen", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("impringPrivacyUlText10Fifteen", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("impringPrivacyUlText10Sixteen", { ns: "translation" }),
    },
    {
      id: 4,
      content: t("impringPrivacyUlText10Seventeen", { ns: "translation" }),
    },
    {
      id: 5,
      content: t("impringPrivacyUlText10Eighteen", { ns: "translation" }),
    },
    {
      id: 6,
      content: t("impringPrivacyUlText10Nineteen", { ns: "translation" }),
    },
  ],

  topic8List5: [
    {
      id: 1,
      content: t("impringPrivacyUlText10Twenty", { ns: "translation" }),
    },
    {
      id: 2,
      content: t("impringPrivacyUlText10TwentyOne", { ns: "translation" }),
    },
    {
      id: 3,
      content: t("impringPrivacyUlText10TwentyTwo", { ns: "translation" }),
    },
  ],
});

export const nutzungsbedingungenTranslationVariables = ({ t }: ITranslationVariables) => ({
  // First block - terms of use
  touTitle: t("termsOfUseTitle", { ns: "translation" }),
  touMobileTitle: t("termsOfUseMobileTitle", { ns: "translation" }),
  lastUpdated: t("termsOfUseLastUpdated", { ns: "translation" }),
  touContent1One: t("termsOfUseContent1One", { ns: "translation" }),
  touContent1Two: t("termsOfUseContent1Two", { ns: "translation" }),

  // Second block - Inhalt
  touContentTitle1: t("termsOfUseContentTitle1", { ns: "translation" }),
  touInhaltList: [
    {
      id: 1,
      title: t("termsOfUseContentList1", { ns: "translation" }),
    },
    {
      id: 2,
      title: t("termsOfUseContentList2", { ns: "translation" }),
    },
    {
      id: 3,
      title: t("termsOfUseContentList3", { ns: "translation" }),
    },
    {
      id: 4,
      title: t("termsOfUseContentList4", { ns: "translation" }),
    },
    {
      id: 5,
      title: t("termsOfUseContentList5", { ns: "translation" }),
    },
    {
      id: 6,
      title: t("termsOfUseContentList6", { ns: "translation" }),
    },
    {
      id: 7,
      title: t("termsOfUseContentList7", { ns: "translation" }),
    },
    {
      id: 8,
      title: t("termsOfUseContentList8", { ns: "translation" }),
    },
    {
      id: 9,
      title: t("termsOfUseContentList9", { ns: "translation" }),
    },
    {
      id: 10,
      title: t("termsOfUseContentList10", { ns: "translation" }),
    },
    {
      id: 11,
      title: t("termsOfUseContentList11", { ns: "translation" }),
    },
  ],

  // Third block - Vertrag und Vertragswiderruf
  touContentTitle2: t("termsOfUseContentTitle2", { ns: "translation" }),
  touContent2One: t("termsOfUseContent2One", { ns: "translation" }),

  // Fourth block - Gebühren und Zahlungen
  touContentTitle3: t("termsOfUseContentTitle3", { ns: "translation" }),

  touSub3One: t("termsOfUseSubTitle3One", { ns: "translation" }),
  touContent3One: t("termsOfUseContent3One", { ns: "translation" }),
  touContent3Two: t("termsOfUseContent3Two", { ns: "translation" }),

  touSub3Two: t("termsOfUseSubTitle3Two", { ns: "translation" }),
  touContent3Three: t("termsOfUseContent3Three", { ns: "translation" }),
  touContent3Four: t("termsOfUseContent3Four", { ns: "translation" }),
  touContent3Five: t("termsOfUseContent3Five", { ns: "translation" }),

  touSub3Three: t("termsOfUseSubTitle3Three", { ns: "translation" }),
  touContent3Six: t("termsOfUseContent3Six", { ns: "translation" }),

  touSub3Four: t("termsOfUseSubTitle3Four", { ns: "translation" }),
  touContent3Seven: t("termsOfUseContent3Seven", { ns: "translation" }),
  touContent3Eight: t("termsOfUseContent3Eight", { ns: "translation" }),

  // Fifth block - Datenschutz
  touContentTitle4: t("termsOfUseContentTitle4", { ns: "translation" }),

  touSub4One: t("termsOfUseSubTitle4One", { ns: "translation" }),
  touContent4One: t("termsOfUseContent4One", { ns: "translation" }),

  touSub4Two: t("termsOfUseSubTitle4Two", { ns: "translation" }),
  touContent4Two: t("termsOfUseContent4Two", { ns: "translation" }),

  touSub4Three: t("termsOfUseSubTitle4Three", { ns: "translation" }),
  touContent4Three: t("termsOfUseContent4Three", { ns: "translation" }),
  touContent4Four: t("termsOfUseContent4Four", { ns: "translation" }),

  // Sixth block - Ihre Inhalte
  touContentTitle5: t("termsOfUseContentTitle5", { ns: "translation" }),
  touSub5One: t("termsOfUseSubTitle5One", { ns: "translation" }),
  touContent5One: t("termsOfUseContent5One", { ns: "translation" }),

  touSub5Two: t("termsOfUseSubTitle5Two", { ns: "translation" }),
  touContent5Two: t("termsOfUseContent5Two", { ns: "translation" }),

  touSub5Three: t("termsOfUseSubTitle5Three", { ns: "translation" }),
  touContent5Three: t("termsOfUseContent5Three", { ns: "translation" }),

  // Seventh block - Geistiges Eigentum von Squib
  touContentTitle6: t("termsOfUseContentTitle6", { ns: "translation" }),
  touContent6One: t("termsOfUseContent6One", { ns: "translation" }),

  // Eighth block - Ressourcen Dritter
  touContentTitle7: t("termsOfUseContentTitle7", { ns: "translation" }),
  touContent7One: t("termsOfUseContent7One", { ns: "translation" }),

  // Ninth block - Kontoverwaltung
  touContentTitle8: t("termsOfUseContentTitle8", { ns: "translation" }),

  touSub8One: t("termsOfUseSubTitle8One", { ns: "translation" }),
  touSub8OneMobile: t("termsOfUseSubTitle8OneMobile", { ns: "translation" }),
  touContent8One: t("termsOfUseContent8One", { ns: "translation" }),
  touContent8Two: t("termsOfUseContent8Two", { ns: "translation" }),

  touSub8Two: t("termsOfUseSubTitle8Two", { ns: "translation" }),
  touContent8Three: t("termsOfUseContent8Three", { ns: "translation" }),

  // Tenth block - Aussetzung und Beendigung von Services
  touContentTitle9: t("termsOfUseContentTitle9", { ns: "translation" }),
  touSub9One: t("termsOfUseSubTitle9One", { ns: "translation" }),
  touContent9One: t("termsOfUseContent9One", { ns: "translation" }),

  touSub9Two: t("termsOfUseSubTitle9Two", { ns: "translation" }),
  touContent9Two: t("termsOfUseContent9Two", { ns: "translation" }),
  touContent9Three: t("termsOfUseContent9Three", { ns: "translation" }),
  touContent9Four: t("termsOfUseContent9Four", { ns: "translation" }),

  // Eleventh block - Änderungen und Aktualisierungen
  touContentTitle10: t("termsOfUseContentTitle10", { ns: "translation" }),
  touSub10One: t("termsOfUseSubTitle10One", { ns: "translation" }),
  touContent10One: t("termsOfUseContent10One", { ns: "translation" }),

  touSub10Two: t("termsOfUseSubTitle10Two", { ns: "translation" }),
  touContent10Two: t("termsOfUseContent10Two", { ns: "translation" }),

  // Twelfth block - Haftungsausschlüsse und Entschädigung
  touContentTitle11: t("termsOfUseContentTitle11", { ns: "translation" }),
  touSub11One: t("termsOfUseSubTitle11One", { ns: "translation" }),
  touContent11One: t("termsOfUseContent11One", { ns: "translation" }),

  touSub11Two: t("termsOfUseSubTitle11Two", { ns: "translation" }),
  touContent11Two: t("termsOfUseContent11Two", { ns: "translation" }),

  // Thirteenth block - Sonstige Bedingungen
  touContentTitle12: t("termsOfUseContentTitle12", { ns: "translation" }),
  touSub12One: t("termsOfUseSubTitle12One", { ns: "translation" }),
  touContent12One: t("termsOfUseContent12One", { ns: "translation" }),

  touSub12Two: t("termsOfUseSubTitle12Two", { ns: "translation" }),
  touContent12Two: t("termsOfUseContent12Two", { ns: "translation" }),

  touSub12Three: t("termsOfUseSubTitle12Three", { ns: "translation" }),
  touContent12Three: t("termsOfUseContent12Three", { ns: "translation" }),

  touSub12Four: t("termsOfUseSubTitle12Four", { ns: "translation" }),
  touContent12Four: t("termsOfUseContent12Four", { ns: "translation" }),

  touSub12Five: t("termsOfUseSubTitle12Five", { ns: "translation" }),
  touContent12Five: t("termsOfUseContent12Five", { ns: "translation" }),

  touSub12Six: t("termsOfUseSubTitle12Six", { ns: "translation" }),
  touContent12Six: t("termsOfUseContent12Six", { ns: "translation" }),

  touSub12Seven: t("termsOfUseSubTitle12Seven", { ns: "translation" }),
  touContent12Seven: t("termsOfUseContent12Seven", { ns: "translation" }),

  touSub12Eight: t("termsOfUseSubTitle12Eight", { ns: "translation" }),
  touContent12Eight: t("termsOfUseContent12Eight", { ns: "translation" }),
  touContent12Nine: t("termsOfUseContent12Nine", { ns: "translation" }),
  touContent12Ten: t("termsOfUseContent12Ten", { ns: "translation" }),
});
