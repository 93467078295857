import React from "react";
import GetIntouch from "../../common/get-in-touch/GetInTouch";

import { useTranslation } from "react-i18next";

const GetInTouchSuccessStories = () => {
  const { t } = useTranslation("translation");
  const question = t("getInTouchSuccessStoriesHeader", { ns: "translation" });
  const callToActBtn = t("getInTouchSuccessStoriesBtn", { ns: "translation" });

  return (
    <div className="getintouch-success-stories-container">
      <div className="getintouch-success-stories-wrapper">
        <GetIntouch title={question} callToAction={callToActBtn} />
      </div>
    </div>
  );
};

export default GetInTouchSuccessStories;
