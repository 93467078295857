import argusOne from "./argus-one.png";
import argusTwo from "./argus-two.png";
import glore from "./glore.png";
import twiny from "./twiny.png";
import raphael from "./raphael.png";
import rebekka from "./rebekka.png";
import nadia from "./nadia.png";
import mike from "./mike.png";
import alissia from "./alissia.png";
import changeContImg from "./img-change-cont.png";
import squibIcon from "./squib-icon.png";
import excl from "./excl.png";
import check from "./check.png";
import leftVotingArrow from "./left-voting-arrow.png";
import rightVotingArrow from "./right-voting-arrow.png";
import hand from "./hand.png";
import tinySquibIcon from "./tiny-squib-icon.png";
import cardCartoon from "./card-cartoon.png";
import upArrow from "./chevron-up.png";
import squibLogo from "./svg/squibLogo.svg";
import upArrowSvg from "./svg/upArrow.svg";
import upArrowSvgBlack from "./svg/upArrowBlack.svg";
import benedictSvg from "./svg/benedict.svg";
import startUpNightsSvg from "./svg/startup-night.svg";
import benedictPng from "./png/benedictPng.png";
import startUpNightsPng from "./png/startup-nightsPng.png";
import benedict from "./png/benedict.png";
import startUpNights from "./png/startup-nights.png";
import info from "./svg/info.svg";
import biuqsA from "./svg/biuqs-a.svg";
import biuqsB from "./svg/biuqs-b.svg";
import imageA from "./svg/image-a.svg";
import imageB from "./svg/image-b.svg";
import skinHarmony from "./svg/skin-harmony.svg";
import skinHarmonyPng from "./png/skin-harmony.png";
import funnyA from "./svg/funny-a.svg";
import funnyB from "./svg/funny-b.svg";
import checkArrow from "./svg/check.svg";
import checkBlack from "./svg/checkBlack.svg";
import xMark from "./svg/xmark.svg";
import socialLinkedinIcon from "./svg/social-linkedin-icon.svg";
import emailIcon from "./svg/email-icon.svg";
import mobileVotingOne from "./svg/mobile-voting-one.svg";
import mobileVotingTwo from "./svg/mobile-voting-two.svg";
import mobileVotingThree from "./svg/mobile-voting-three.svg";
import mobileVotingFour from "./svg/mobile-voting-four.svg";
import testMobileVoting from "./png/test-mobile-voting.png";
import squibLogoBlack from "./svg/logo_black.svg";
import closeIcon from "./svg/close-icon.svg";
import abIcon from "./svg/ab-icon.svg";
import linkedinIcon from "./svg/linkedin-icon.svg";
import example1 from "./png/Example1.png";
import example from "./png/Example2.png";
import example3 from "./png/Example3.png";
import example4 from "./png/Example4.png";
import startUpN from "./png/startUpN.png";
import thomas from "./thomas.jpeg";
import iconDark from "./icon-dark.png";

interface IImages {
  [key: string]: string;
}

const images: IImages = {
  squibLogoBlack,
  argusOne,
  argusTwo,
  glore,
  twiny,
  benedict,
  startUpNights,
  raphael,
  rebekka,
  nadia,
  mike,
  thomas,
  alissia,
  benedictSvg,
  startUpNightsSvg,
  benedictPng,
  startUpNightsPng,
  changeContImg,
  squibIcon,
  excl,
  check,
  checkBlack,
  leftVotingArrow,
  rightVotingArrow,
  hand,
  tinySquibIcon,
  cardCartoon,
  upArrow,
  squibLogo,
  upArrowSvg,
  upArrowSvgBlack,
  info,
  biuqsA,
  biuqsB,
  imageA,
  imageB,
  skinHarmony,
  funnyA,
  funnyB,
  checkArrow,
  xMark,
  socialLinkedinIcon,
  emailIcon,
  mobileVotingOne,
  mobileVotingTwo,
  mobileVotingThree,
  mobileVotingFour,
  testMobileVoting,
  closeIcon,
  abIcon,
  linkedinIcon,
  skinHarmonyPng,
  example,
  example1,
  example3,
  example4,
  startUpN,
  iconDark,
};

export default images;
