import React from "react";
import ReleasesLayout from "../../layout/ReleasesLayout";

const FeatureFour = () => {
  return (
    <ReleasesLayout
      title="New feature"
      description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
      labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
      ea rebum."
      date="14.Februar 2023"
      versions={["App"]}
    />
  );
};

export default FeatureFour;
