import React from "react";
import GetIntouch from "../../common/get-in-touch/GetInTouch";
import "../../../../../resources/styles/pages/imprint-privacy.css";
import { useTranslation } from "react-i18next";

interface GetInTouchImprintPrivacyProps {
  title: string;
  callToAction: string;
}

const GetInTouchImprintPrivacy = () => {
  const { t } = useTranslation("translation");
  const headerTitle = t("imprintPrivacyHeaderQuestion", { ns: "translation" });
  const callToActionBtn = t("imprintPrivacyCallToActionBtn", { ns: "translation" });

  const getIntouchImprintPrivacy: GetInTouchImprintPrivacyProps[] = [
    {
      title: headerTitle,
      callToAction: callToActionBtn,
    },
  ];

  return (
    <div className="get-in-touch-impprint-container">
      <GetIntouch title={getIntouchImprintPrivacy[0].title} callToAction={getIntouchImprintPrivacy[0].callToAction} />
    </div>
  );
};

export default GetInTouchImprintPrivacy;
