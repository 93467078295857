import React, { useEffect, useState } from "react";
import "../../../../resources/styles/pages/voting-error.css";
import VotingFooter from "../../../../squip-lite/components/VotingFooter";

const VotingClosed = () => {
  const [height, setHeight] = useState(window.innerHeight);

  const setFullHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    setFullHeight();
    window.addEventListener("resize", setFullHeight);
    return () => window.removeEventListener("resize", setFullHeight);
  }, []);

  return (
    <div className="votingerrorscreen" style={{ height: `calc(var(--vh, 1vh) * 100)` }}>
      <h1>
        Sorry, this voting <br />
        is already closed.
      </h1>
      <VotingFooter />
    </div>
  );
};

export default VotingClosed;
