import React from "react";
import { useTranslation } from "react-i18next";
import Reveal from "../../common/reveal-animation/Reveal";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";
import "../../../../../resources/styles/components/home-components/home-header.css";

const HomeHeader = () => {
  const { t } = useTranslation();
  const title = t("homeHeaderTitle", { ns: "translation" });
  const subTitle = t("homeHeaderDesc", { ns: "translation" });
  const firstTitle = "Simplify";
  const secondTitle = " your surveys.";

  return (
    <div className="home-container">
      <div className="home-wrapper">
        <Reveal>
          <HeaderTitleComp secondTitleClass="second-title" title={firstTitle} secondTitle={secondTitle} subTitle={subTitle} />
        </Reveal>
      </div>
    </div>
  );
};

export default HomeHeader;
