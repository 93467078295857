import React from "react";
import VotingCards from "./voting-cards/VotingCards";

const CreateVoting = () => {
  return (
    <>
      <div>
        <VotingCards />
      </div>
    </>
  );
};

export default CreateVoting;
