import TermsOfUse from "../components/basicComponents/squib-2024/terms-of-use/TermsOfUse";

const link = "https://www.squib.app/impressum-datenschutz";
const squibEmail = "hello@squib.app";
const protectionOfficer = "marco.eichenberger@squib.app";

export const german = {
  home: "Home",
  createVote: "Voting erstellen",
  explore: "Squib entdecken",
  useCases: "Anwendungsbeispiele",
  pricingFeatures: "Preise",
  successStories: "Erfolgsgeschichten",
  comparison: "Squib im Vergleich",
  security: "Sicherheit",
  integrations: "Integrationen",
  releases: "Releases",
  imprintPrivacy: "Impressum & Datenschutzerklärung",
  faqHelp: "FAQ & Hilfe",
  voting: "Voting",
  votingDeleted: "Voting gelöscht",
  votingClosed: "Voting geschlossen",
  about: "Über uns",
  contact: "Kontakt",
  balance: "Guthaben",
  error: "Fehler",

  comparisonUrl: "...",
  comparisonFooter: "...",

  voteForA: "...",
  voteForB: "...",

  voteForThis: "...",
  voteForThat: "...",

  homeHeaderTitle: "Simplify your surveys.",
  homeHeaderDesc: "Die einfachste Art, attraktive Umfragen zu erstellen, die alle deine Zielgruppen lieben werden.",

  errorWaitingListMessage: "Bitte gib eine korrekte E-Mail-Adresse ein.",

  startUpNightsParagraph:
    "«Mit Squib konnten wir unmittelbar während des Events den Puls der Teilnehmenden spüren. Dank des smarten Umfragedesigns erzielten wir eine tolle Rücklaufquote und besonders authentisches Feedback.»",
  benedictParagraph:
    "«Als fortschrittliche Schule ist es uns wichtig, auf die Bedürfnisse der Lernenden einzugehen. Mit Squib konnten wir besonders authentisches und unmittelbares Feedback von den Lernenden einholen. Squib bereitet die Resultate einfach auf, sodass wir sehr effizient die Schlüsse daraus ziehen können.»",
  useCasesTitle: "Anwendungsbeispiele",
  useCasesTitleMobile: "Anwendungs-\nbeispiele",
  useCasesSubTitle:
    "Längerfristiger Erfolg ist für Organisationen nur dann erreichbar, wenn sie kontinuierlich den Bedürfnissen ihrer Stakeholder Gehör schenken. Hier findest du einige Beispiele aus dem Alltag verschiedener Organisationen, die Squib erfolgreich einsetzen.",

  cardOneUseCasesTwoText: "...",
  cardThreeUseCasesText: "...",

  getInTouchUseCasesHeader: "Möchtest du Squib nutzen oder hast du eine Frage?",
  getInTouchUseCasesBtn: "Jetzt kontaktieren",

  successStoriesTitle: "...",
  successStoriesSubTitle: "...",

  getInTouchSuccessStoriesHeader: "...",
  getInTouchSuccessStoriesBtn: "...",

  clientNeedsSubtitle:
    "«Mit Squib konnten wir unmittelbar während des Events den Puls der Teilnehmenden spüren. Dank des smarten Umfragedesigns erzielten wir eine tolle Rücklaufquote und besonders authentisches Feedback.»",
  clientNeedsParagraph: "www.startup-nights.com",
  clientNeedsAuthor: "Alyssia Kugler",
  clientNeedsAuthorTitle: "Director",

  pricingFeaturesTitle: "...",
  pricingFeaturesSubTitle: "...",
  appVersionHeader1: "...",
  appVersionHeader2: "...",
  appVersionHeader3: "...",
  appVersionsDesc: "...",
  appVersionBtn1: "...",
  appVersionBtn2: "...",
  freeRegistration: "...",
  pricePro: "...",
  priceProPlus: "...",

  components: "Komponenten",

  whySquib: "Warum Squib",
  feedback: "Feedback macht Spass",
  exploreHeaderTitle: "Weshalb Squib?",
  exploreHeaderDesc:
    "Mit Squib kriegst du mehr und authentischer Online-Feedback als mit anderen Tools. Durch die äusserst benutzerfreundliche und einfache Handhabung fördert Squib die Beteiligung deiner Zielgruppen aller Altersklassen. Empirische Untersuchungen belegen dies mehrfach.",

  exploreText: "Weil gute Umfragen auch Spass machen können.",
  exploreSubText:
    "Es ist erwiesen, dass Umfragen mehr Beteiligung erhalten, wenn sie optisch ansprechend und einfach zu handhaben sind. Squib verfolgt die Mission, Organisationen dabei zu unterstützen, eine echte Kultur des Feedbacks zu leben. Denn heutzutage wird viel kommuniziert, jedoch wird den individuellen Bedürfnissen oft zu wenig Aufmerksamkeit geschenkt.",
  whySquibFeedback: "Weshalb Squib begeistert:",

  propertyOne: "Keep it simple",
  propertyTwo: "Ehrlicheres Feedback",
  propertyThree: "Übersichtliche Auswertungen",
  propertyFour: "Verlässliche Daten",
  propertyOneSub:
    "In einer Welt, in der Aufmerksamkeit ein kostbares Gut ist, lohnt es sich, auf bewährte und intuitiv verständliche Methoden zu setzen, die bei den Menschen Anklang finden, statt sich in einer Vielzahl unterschiedlicher Fragetypen zu verlieren.",
  propertyTwoSub:
    "Das intuitive Design lädt ein, Squib auch unterwegs oder bei Wartezeiten zu nutzen. Dies erhöht das Engagement und führt gleichzeitig zu authentischeren Antworten.",
  propertyThreeSub:
    "Die Ergebnisse werden automatisch für dich aufbereitet, sodass du direkt loslegen kannst. Dank fortschrittlicher KI-Technologie wird es bald möglich sein, qualitative Rückmeldungen zu gruppieren, zusammenzufassen oder in erweiterte Zusammenhänge zu stellen.",
  propertyFourSub:
    "Wähle einfach aus, ob deine Umfrage öffentlich zugänglich oder exklusiv für einen bestimmten Personenkreis bestimmt ist. Diese Flexibilität gewährleistet eine verlässliche und sichere Datenerhebung.",
  advantageTitle: "Vorteile, die du mit Squib hast:",

  advantageOne: "Einfaches und intuitives Handling, kein Handbuch und keine Einarbeitung nötig.",
  advantageTwo: "Individualisierung mit eigenem Logo und Styling, ganz nach deinen Bedürfnissen.",
  advantageThree: "Gamification: Spielerische Elemente sorgen für hohe Beteiligungsquote und minimale Abbruchrate.",
  advantageFour: "Überzeugende User Experience, ohne dass ein App-Download notwendig ist.",
  funcQuestion: "Möchtest du Squib nutzen oder hast du eine Frage?",
  funcCallToActionText: "Jetzt kontaktieren",
  anyQuestion: "Haben Sie Fragen oder brauchen Sie Hilfe?",
  getInTouchBtn: "Nehmen Sie jetzt Kontakt auf!",

  faqTitle: "Fragen & Antworten (FAQ)",
  faqQuestionOne: "Was ist Squib?",
  faqAnswerOne:
    "Squib ist ein neuartiges Umfragetool, mit dem Sie mehr und authentisches Feedback erhalten als mit anderen Tools. Squib ist niederschwellig zu nutzen und vermittelt positive Gefühlserlebnisse bei den Teilnehmenden. Reguläre Umfragetools entsprechen weitgehend nicht mehr der veränderten Mediennutzung, die geprägt ist von abnehmender Aufmerksamkeitsspanne. Sie als Organisation profitieren von verhältnismässig wenig Aufwand beim Erstellen und Auswerten der Umfragen. Simplicity ist das Credo von Squib.",
  faqQuestionTwo: "Weshalb soll ich Squib nutzen, es gibt ja schon viele Umfragetools?",
  faqAnswerTwo:
    "Wollen Sie mehr und authentisches Feedback von Ihren Zielgruppen erhalten? Wollen Sie nicht für jede Befragung unbeliebte Reminder versenden? Dann ist Squib Ihre erste Wahl. Andere Tools bieten eine Fülle von Fragetypen und viele Optionen, was zeitaufwendig ist beim Erstellen und Auswerten. Und letztlich sind es genau die unterschiedlichen Fragetypen und komplexen Umfragedesigns, welche Teilnehmende davon abhält, bis zum Schluss authentische Antworten zu geben. s Feedback zu geben.",

  faqQuestionThree: "Was unterscheidet Squib von den anderen Umfragetools?",
  faqAnswerThree:
    "Umfragen sind sehr wichtig für Organisationen, um die Bedürfnisse ihrer Zielgruppen zu kennen. Diese jedoch mögen weder lange noch unübersichtlich graue Umfragedesigns. Genau hier setzt Squib an, mit einem einfachen Prinzip, welches bei der Teilnahme positive Gefühlserlebnisse vermittelt.",

  faqQuestionFour:
    "Braucht es Squib denn noch, wenn ich dank künstlicher Intelligenz (Stichwort 'Customer Twins') meine Kunden quasi nachbauen kann?",
  faqAnswerFour:
    "Wichtiger Punkt. Eben genau aufgrund dieser Entwicklungen ist es wichtig zu wissen, was die wirklichen Bedürfnisse der Anspruchsgruppen sind. Generische Antworten eines Durchschnittskunden sind trügerisch. Und Ihre Kundinnen und Mitarbeitenden haben ganz individuelle Bedürfnisse und eine persönliche Bindung zu Ihnen.",

  faqQuestionFive: "Ist Squib denn auch für meine Branche relevant?",
  faqAnswerFive:
    "Ja. Einerseits hat sich die Mediennutzung bei der gesamten Bevölkerung verändert, mit Einfachheit und ansprechendem Design gewinnt man bei allen Alterskohorten und Bildungsschichten. Und andererseits können Sie mit Squib sowohl einfache als auch komplexere Inhalte befragen, immer mit dem gleichen Prinzip.",

  faqQuestionSix: "Wir haben bereits eine gute Feedbackkultur, was nutzt uns dann Squib noch?",
  faqAnswerSix:
    "Das freut uns, eine gute Feedbackkultur ist erfolgskritisch! Wissenschaftliche Untersuchungen zeigen, dass Stakeholder zufriedener sind, wenn sie regelmässig zu einzelnen Themen mit einbezogen werden, als wenn Sie mehrere Themenbereiche adressieren müssen. Dafür erhalten sie konkrete Feedbacks zu ihren Anliegen. Genau dafür eignet sich Squib wie kein anderes Tool.",

  faqQuestionSeven: "Wie sieht es denn mit dem Datenschutz aus? Und seht ihr die Daten, welche wir erheben?",
  faqAnswerSeven: `Das ist ein ganz wichtiger Punkt. Auf der Basis der DSGVO und dem Schweizer Datenschutzgesetz bieten wir Ihren Daten den bestmöglichen Schutz. Weitere Informationen zum Datenschutz finden Sie <a href=${link} target="_blank">hier</a>.`,

  faqQuestionEight: "Ich hätte eine Idee für ein zusätzliches Feature, um Squib noch effektiver zu nutzen. Kann ich das jemandem melden?",
  faqAnswerEight: `Vielen Dank fürs Mitdenken! Sehr gerne, senden Sie uns einfach eine E-Mail an <a href="mailto:${squibEmail}">${squibEmail}</a>. Wir entwickeln unsere Services laufend weiter und berücksichtigen Ihr Anliegen so gut wie möglich.`,

  faqQuestionNine: "Erneuert sich mein Abo automatisch, und wie kann ich es kündigen?",
  faqAnswerNine: "tbd.",

  ctaText: "...",
  subText: "...",

  groupOneTitle1: "Warum sind klassische Umfragen unbeliebt?",
  groupOneDesc1:
    "Klassische Befragungen sind oft anstrengend und bieten wenig Motivation zur Teilnahme. Squib revolutioniert diesen Prozess durch eine intuitive und empirisch geprüfte Methode, die besonders authentische Antworten und eine hohe Beteiligung fördert. Das Besondere (und Einmalige) dabei: Die Menschen nehmen gerne teil!",
  btnGroupOne1: "Mehr erfahren",

  versionsTitle: "Willst du zu den Ersten gehören, die Squib nutzen und von Vorzugskonditionen profitieren können?",
  versionsDescHome: "Trage dich jetzt in die Warteliste ein und erhalte laufend exklusive News zu Squib.",
  inputWaitingList: "Deine E-Mail-Adresse",
  btnWaitingList: "Hier eintragen",
  waitingListConfirm: "Danke!",
  waitingListConfirmSubText: "Wir halten dich auf dem Laufenden.",

  btnVersions: "...",

  startupCallToActionHeader: "Möchtest du Squib nutzen oder hast du eine Frage?",
  btnStartupCallToAction: "Jetzt kontaktieren",

  groupOneTitle2: "Weniger Aufwand, mehr Feedback!",
  groupOneDesc2:
    "Squib erhöht die Aufmerksamkeit deiner Zielgruppen durch attraktives Design. Authentisches Feedback erreichst du mit der einfachen, zugleich cleveren Usability. Gefordert bist du nur, deine Anspruchsgruppen auch regelmässig einzubinden.",
  btnGroupOne2: "Beispiele ansehen",

  btnStartup: "...",

  question: "...",
  questionInp: "...",
  hashtagInp: "...",
  linkTerms: "...",
  linkSub: "...",
  hintText: "...",

  useCasesTitleOne: "Bedürfnisorientierter Schulunterricht etablieren",
  useCasesDescOne:
    "Ein ideales Lernumfeld stellt die Bedürfnisse der Lernenden stets in den Fokus. Wesentlich ist dabei, regelmässig und fortlaufend während des Semesters Feedback zu sammeln – zeitgemäss und interaktiv, direkt über einen QR-Code im Klassenzimmer.",

  brandHeader: "Dieses Beispiel wurde realisiert mit",

  useCasesTitleTwo: "Bedürfnisse der Mitarbeitenden berücksichtigen",
  useCasesDescTwo:
    "Den Mitarbeitenden zuhören und diese partizipieren zu lassen, ist ein wichtiger Erfolgsfaktor einer Organisation. Dies sollte jedoch niederschwellig und intuitiv geschehen, entweder direkt am Arbeitsplatz via QR-Code oder zeit- und ortsunabhängig via Link.",

  useCasesTitleThree: "Event-Feedback in Echtzeit",
  useCasesDescThree:
    "Erreiche an deinem Event die Besuchenden direkt und auf eine clevere Art und Weise, um wertvolles Feedback zu den Themen zu sammeln, die dich weiterbringen. Diese innovative Form der Interaktion ermöglicht es auch den Teilnehmenden, aktiv an den Weichenstellungen für die Zukunft mitzuwirken.",

  useCasesTitleFour: "Co-Creation: Kunden in Entwicklungen miteinbeziehen",
  useCasesDescFour:
    "Eine neue Art von aktiver Einbindung der (künftigen) Kunden ermöglicht es, Produkte und Dienstleistungen genau an deren Bedürfnissen und Wünschen auszurichten: «Marktforschung light» kombiniert mit cleverem Marketing.",

  useCasesFirstCardHeader: "...",
  useCasesSecondCardHeader: "...",
  useCasesThirdCardHeader: "...",
  useCasesFourthCardHeader: "...",

  votingMainTitle: "...",
  linkOptionTitle: "...",
  dataText: "...",
  dataDesc: "...",

  contactTitle: "Kontakt",
  contactSubTitle:
    "Möchtest du Squib nutzen? Hast du eine Frage oder möchtest du uns einfach ein Feedback geben? Kontaktiere uns per E-Mail oder LinkedIn. Wir freuen uns!",
  contactPlace: "Squib Ltd.\nc/o Hochschule Luzern – Wirtschaft\nSmart-up\nZentralstrasse 9\nPostfach 2940\n6002 Luzern\nSchweiz",

  checkText1: "Ich möchte Squib nutzen (Pro, Pro+ oder Enterprise)",
  checkText2: "Ich habe eine Frage oder möchte ein Feedback geben",
  checkText3: "Ich möchte für Squib arbeiten",
  checkText4: "Ich bin Investor und bin an eurem Business-Case interessiert",

  company: "Firmenname eingegeben",
  name: "Nachname eingegeben",
  lastname: "Vorname eingegeben",
  telephone: "Telefonnummer eingegeben",
  message: "Mittteilung eingegeben",
  oblig: "erforderlich",

  labelCompany: "Firma",
  labelName: "Name",
  labelLastName: "Vorname",
  labelTelephone: "Telefon",
  labelMessage: "Nachricht",

  sendMsg: "Senden",

  contactHeader: "Kontakt",
  contactSubHeader: "...",

  aboutSquibAppText: "...",
  aboutSquibAppDesc: "...",
  contactAddressHeader: "...",

  createVotingBtn: "Voting erstellen",

  stepsTitle: "...",
  step1: "...",
  step2: "...",
  step3: "...",
  callToActionTitle: "...",

  squibProDesc: "...",

  errorTitle: "Sorry, dieses Voting ist bereits abgeschlossen.",
  errorHintText: "Erstelle ein neues Voting hier!",

  integration: "Integration",
  product: "Produkt",
  discover: "Entdecken",
  resources: "Ressourcen",

  uberSquib: "Über Squib",
  blog: "Blog",
  contactUs: "Kontakt",
  jobs: "Jobs",
  media: "Medien",
  imprint: "Impressum",
  footerPolicy:
    ". Squib ist eine urheberrechtlich geschützte Marke von Squib Ltd. Alle Rechte vorbehalten. Alle anderen Marken sind das Eigentum der jeweiligen Inhaber.",

  linkImprintPrivacy: "Impressum & Datenschutzerklärung",

  mobileMenuOne: "Squib entdecken",
  mobileMenuTwo: "Anwendungsbeispiele",
  mobileMenuThree: "Kontakt",

  waitingListBtnText: "Auf die Warteliste setzen",

  btnCreateVoting: "Voting erstellen",
  btnStartVoting: "Voting Starten",
  btnNewCreateVoting: "Neues Voting erstellen",
  btnLearnMore: "Mehr erfahren",
  btnSendMessage: "Nachricht senden",

  headerContactInfo: "Verantwortlich für den Inhalt der Webseite:",

  // Imprint & Privacy Policy
  imprintPrivacyHeaderQuestion: "Möchtest du Squib nutzen oder hast du eine Frage?",
  imprintPrivacyCallToActionBtn: "Jetzt kontaktieren",

  imprintPrivacyContentTitle1: "Impressum & Datenschutz",
  imprintPrivacyAddress: "Squib Ltd.\nc/o Hochschule Luzern – Wirtschaft\nSmart-up\nZentralstrasse 9\nPostfach 2940\n6002 Luzern\nSchweiz",
  imprintPrivacyResponsive: "Marco Eichenberger",

  imprintPrivacyContent1One: "Letzte Änderung: 26. Februar 2024",
  imprintPrivacyContent1Two:
    "Squib ist sich der Wichtigkeit der Privatsphäre seiner Nutzenden bewusst. Ein transparenter Umgang mit Personendaten ist uns deshalb ein wichtiges Anliegen. Die vorliegende Datenschutzerklärung informiert darüber, welche Personendaten wir zu welchem Zweck erheben und an wen wir diese weitergeben.",
  imprintPrivacyContent1Three:
    "Wir informieren Sie auch über unsere Richtlinien und Methoden zur Datenverarbeitung, welche im Einklang mit der DSGVO stehen. Diese Datenschutzregeln ergänzen unsere Nutzungsbedingungen. Bitte beachten Sie, dass bei Differenzen zwischen der englischen und deutschen Fassung unserer Datenschutzregeln die deutsche Version massgeblich ist.",

  imprintPrivacyContentTitle2: "Inhalt",
  imprintPrivacyContentListOne: "1. Was tun wir?",
  imprintPrivacyContentListTwo: "2. Über was informieren wir?",
  imprintPrivacyContentListThree: "3. Begriffsdefinitionen",
  imprintPrivacyContentListFour: "4. Kontakt",
  imprintPrivacyContentListFive: "5. Datensicherheit",
  imprintPrivacyContentListSix: "6. Betroffenenrechte",
  imprintPrivacyContentListSeven: "7. Allgemeine Grundsätze",
  imprintPrivacyContentListEight: "8. Einzelne Datenverarbeitungsvorgänge",
  imprintPrivacyContent2One:
    "Diese Datenschutzerklärung bezieht sich auf die Verarbeitung von Daten, die generiert werden, wenn Sie unsere Dienste nutzen oder darauf zugreifen.",

  imprintPrivacyContent2Two:
    "Diese Datenschutzerklärung findet keine Anwendung für die Praxis von Drittparteien, die wir nicht besitzen, kontrollieren oder verwalten. Dies sind Websites, Dienste, Anwendungen und Firmen von Drittparteien. Wir sind zwar bestrebt, nur mit Diensten von Drittparteien zu arbeiten, welche unseren Respekt für den Schutz Ihrer Daten teilen, tragen aber keine Verantwortung für die Richtlinien dieser Drittparteien bezüglich Inhalten und Datenschutz. Wir möchten Sie dazu motivieren, die Datenschutzrichtlinien sämtlicher Dienste von Drittparteien zur Kenntnis zu nehmen, auf die Sie zugreifen.",
  imprintPrivacyContent2Three:
    "Des Weiteren regelt diese Datenschutzerklärung nicht, was unsere Benutzer auf ihren Squib-Profilen tun und wir tragen keine Verantwortung für die Praxis der Erfassung und Nutzung von Daten durch einzelne Nutzer.",

  imprintPrivacyContentTitle3: "1. Was tun wir?",
  imprintPrivacyContent3One:
    "Squib Ltd. (c/o Hochschule Luzern – Wirtschaft, Zentralstrasse 6, 6002 Luzern) betreibt die Website www.squib.app (nachfolgend als «wir» bezeichnet).",
  imprintPrivacyContent3Two:
    "Der Schutz Ihrer persönlichen Daten ist uns ein grosses Anliegen. Wir informieren Sie in dieser Datenschutzerklärung transparent und verständlich darüber, welche Daten wir über unsere Website sammeln und wie wir damit umgehen.",

  imprintPrivacyContentTitle4: "2. Über was informieren wir?",
  imprintPrivacyInformListOne: "Wer für die Datenverarbeitung verantwortlich ist;",
  imprintPrivacyInformListTwo: "Welche Daten erhoben werden;",
  imprintPrivacyInformListThree: "Zu welchem Zweck diese Daten erhoben werden;",
  imprintPrivacyInformListFour: "Auf welcher Rechtsgrundlage wir diese Daten erheben;",
  imprintPrivacyInformListFive: "An wen wir diese Daten weitergeben;",
  imprintPrivacyInformListSix: "Wie Sie einer Datenverarbeitung widersprechen können;",
  imprintPrivacyInformListSeven: "Welche Rechte Sie haben und wie Sie diese geltend machen können;",
  imprintPrivacyContent4:
    "Wenn Sie in unseren Diensten einen Account einrichten, können Sie Daten wie Benutzername, Alter, Geschlecht, Standort hinterlegen, um die Dienste optimal zu nutzen. Diese Daten können wir anonymisiert verwenden, um die Dienste zu erweitern oder zu verbessern, beispielsweise durch Personalisierung. Wir können z.B. Ihr Alter heranziehen, um zu bestätigen, dass es Ihnen gesetzlich gestattet ist, die Dienste zu nutzen und um unsere Inhalte an Ihr Alter anzupassen. Diese Einstellungen können Sie in den Einstellungen in unseren Diensten jederzeit anpassen.",

  imprintPrivacyContentTitle5: "3. Begriffsdefinitionen",
  imprintPrivacySubTitle5One: "Was sind personenbezogene Daten?",
  imprintPrivacyContent5One:
    "Personenbezogene Daten (gleichbedeutend mit dem Begriff «Personendaten») sind alle Angaben, die sich auf eine bestimmte oder bestimmbare natürliche Person beziehen. Dazu gehören beispielsweise Name, Adresse, Geburtsdatum, E-Mail-Adresse oder Telefonnummer sowie die IP-Adresse. Auch Daten über persönliche Vorlieben wie Freizeitbeschäftigungen oder Mitgliedschaften zählen zu den personenbezogenen Daten.",
  imprintPrivacySubTitle5Two: "Was sind besondere Kategorien personenbezogener Daten?",
  imprintPrivacy5Content1Two:
    "Besondere Kategorien personenbezogener Daten (gleichbedeutend mit dem Begriff «besonders schützenswerte Daten») sind:",
  imprintPrivacyContent5ListOne: "Daten über religiöse, weltanschauliche, politische oder gewerkschaftliche Ansichten oder Tätigkeiten;",
  imprintPrivacyContent5ListTwo:
    "Daten über die Gesundheit, Intimsphäre, die Zugehörigkeit zu einer Rasse oder Ethnie, sowie zum Sexualleben und zur sexuellen Orientierung;",
  imprintPrivacyContent5ListThree:
    "Daten zu administrativen oder strafrechtlichen Verfolgungen und Sanktionen, sowie Daten über Massnahmen der sozialen Hilfe;",
  imprintPrivacyContent5ListFour: "Genetische Daten und biometrische Daten, die eine Person eindeutig identifizieren.",
  imprintPrivacy5Content3Two:
    "Soweit erforderlich und Sie uns diese Daten selbst preisgeben, können wir Daten, die einer besonderen Kategorie von personenbezogenen Daten angehören, verarbeiten. In diesem Fall unterliegt deren Verarbeitung strengerer Vertraulichkeit.",

  imprintPrivacySubTitle5Three: "Was ist das Verarbeiten von personenbezogenen Daten?",
  imprintPrivacy5Content1Three:
    "Verarbeiten (gleichbedeutend mit dem Begriff «Bearbeiten») ist jeder Umgang mit personenbezogenen Daten, unabhängig von den angewandten Mitteln und Verfahren, insbesondere das Beschaffen, Speichern, Aufbewahren, Verwenden, Verändern, Bekanntgeben, Archivieren, Löschen oder Vernichten von personenbezogenen Daten.",

  imprintPrivacySubTitle5Four: "Was ist das Bekanntgeben von personenbezogenen Daten?",
  imprintPrivacy5Content1Four:
    "Dies ist das Übermitteln oder Zugänglichmachen von personenbezogenen Daten, bspw. die Veröffentlichung oder die Offenbarung an einen Dritten.",

  imprintPrivacyContentTitle6: "4. Kontakt",
  imprintPrivacyContent6: `Bei Fragen oder Anliegen zum Schutz Ihrer Daten durch uns, können Sie sich an unseren Datenschutzverantwortlichen wenden: Marco Eichenberger (<a href="mailto:${protectionOfficer}">${protectionOfficer}</a>).`,

  imprintPrivacyContentTitle7: "5. Datensicherheit",
  imprintPrivacyContent7One:
    "Wir werden Ihre Daten sicher aufbewahren und alle angemessenen Massnahmen ergreifen, um Ihre Daten vor Verlust, Zugriff, Missbrauch oder Änderungen zu schützen. Unsere Vertragspartner und Mitarbeitenden, die Zugang zu Ihren Daten haben, sind zur Einhaltung der datenschutzrechtlichen Bestimmungen verpflichtet. In manchen Fällen wird es im Rahmen der Auftragsverarbeitung erforderlich sein, dass wir Ihre Anfragen an mit uns verbundene Unternehmen weiterreichen. Auch in diesen Fällen werden Ihre Daten vertraulich behandelt. Innerhalb unserer Webseite verwenden wir das SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird.",
  imprintPrivacyContent7Two:
    "Sie haben überdies jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben ausserdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.",

  imprintPrivacyContentTitle8: "6. Betroffenenrechte",
  imprintPrivacySubTitle8One: "Auskunftsrecht",
  imprintPrivacyContent8One:
    "Sie können jederzeit eine Auskunft, über die von uns über Sie gespeicherten Daten verlangen. Wir bitten Sie, Ihr Auskunftsgesuch zusammen mit einem glaubhaften Identitätsnachweis an marco.eichenberger@squib.app zu senden. Die Auskunft erfolgt in schriftlicher oder auch in anderer Form, ggf. auch elektronisch. Sofern Sie dies verlangen, können wir Ihnen die Auskunft auch mündlich erteilen, sofern Sie Ihre Identität in anderer Form nachweisen. Stellen Sie den Auskunftsantrag elektronisch, stellen wir die Auskünfte in einem gängigen elektronischen Format zur Verfügung, sofern Sie nichts anderes angeben.",
  imprintPrivacyContent8Two:
    "Die Auskunft ist im Regelfall unentgeltlich. Werden darüber hinaus Kopien angefordert, kann ein angemessenes Entgelt verlangt werden.",
  imprintPrivacyContent8Three:
    "Das Recht eine Kopie über die verarbeiteten Daten zu erhalten, darf die Rechte und Freiheiten anderer Personen nicht beeinträchtigen.",
  imprintPrivacyContent8Four:
    "Im Falle von offensichtlich unbegründeten oder exzessiven Auskunftsanträgen behalten wir uns vor, die Auskunft im Rahmen der gesetzlichen Schranken zu verweigern oder ein angemessenes Entgelt dafür zu verlangen.",
  imprintPrivacyContent8Five:
    "Die Bearbeitung Ihres Gesuchs unterliegt der gesetzlichen Frist von einem Monat. Diese Frist dürfen wir aufgrund der Komplexität und der hohen Anzahl von Anfragen um zwei weitere Monate verlängern, soweit dies erforderlich ist. Sie werden über die Fristverlängerung innerhalb eines Monats nach dem Stellen des Auskunftsgesuch informiert. Zugleich werden Ihnen die Gründe für die Verlängerung genannt.",

  imprintPrivacySubTitle8Two: "Löschung und Berichtigung",
  imprintPrivacySubTitle8TwoContentOne:
    "Sie haben jederzeit die Möglichkeit, die Löschung oder Berichtigung bzw. Vervollständigung Ihrer Daten zu verlangen, sofern keine gesetzlichen Aufbewahrungspflichten oder ein gesetzlicher Erlaubnistatbestand entgegenstehen.",
  imprintPrivacySubTitle8TwoContentTwo:
    "Bitte beachten Sie, dass die Ausübung Ihrer Rechte unter Umständen im Konflikt mit vertraglichen Vereinbarungen stehen und entsprechende Auswirkungen auf die Vertragsdurchführung haben kann (z.B. vorzeitige Vertragsauflösung oder Kostenfolgen).",

  imprintPrivacySubTitle8Three: "Einschränkung der Verarbeitung",
  imprintPrivacySubTitle8ThreeContentOne:
    "Sie haben ausserdem das Recht, eine Einschränkung der Bearbeitung zu verlangen, wenn Sie die Richtigkeit dieser Daten bestreiten, die Verarbeitung unrechtmässig ist, die Daten nicht länger benötigt werden oder Sie Widerspruch gegen die Verarbeitung erhoben haben.",
  imprintPrivacySubTitle8ThreeContentTwo:
    "Wird die Verarbeitung der Daten eingeschränkt, dürfen diese nur noch gespeichert werden. Eine weitergehende Verarbeitung darf nur mit Ihrer Einwilligung, zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen, zum Schutz der Rechte einer anderen Person oder aus Gründen eines wichtigen öffentlichen Interesses erfolgen. Im Falle einer Aufhebung der Einschränkung werden Sie benachrichtigt.Wird die Verarbeitung der Daten eingeschränkt, dürfen diese nur noch gespeichert werden. Eine weitergehende Verarbeitung darf nur mit Ihrer Einwilligung, zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen, zum Schutz der Rechte einer anderen Person oder aus Gründen eines wichtigen öffentlichen Interesses erfolgen. Im Falle einer Aufhebung der Einschränkung werden Sie benachrichtigt.",

  imprintPrivacySubTitle8Four: "Recht auf Datenübertragbarkeit",
  imprintPrivacySubTitle8FourContentOne:
    "Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf einer Einwilligung gemäss Artikel 6 Absatz 1 lit. a oder Artikel 9 Absatz 2 lit. a DSGVO oder auf einem Vertrag gemäss Artikel 6 Absatz 1 lit. b DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt. Sie haben ferner das Recht, zu verlangen, dass die personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.",

  imprintPrivacySubTitle8Five: "Widerspruchsrecht",
  imprintPrivacySubTitle8FiveContentOne:
    "Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie den Widerspruch gegenüber uns ausgesprochen haben.",
  imprintPrivacySubTitle8FiveContentTwo:
    "Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.",
  imprintPrivacySubTitle8FiveContentOneThree:
    "Gegen die Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und Datenanalyse können Sie jederzeit widersprechen. Über Ihren Werbewiderspruch können Sie uns unter den in dieser Datenschutzerklärung angegebenen Kontaktdaten kontaktieren.",

  imprintPrivacySubTitle8Six: "Beschwerderecht",
  imprintPrivacySubTitle8SixContentOne:
    "Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.",

  imprintPrivacyContentTitle9: "7. Allgemeine Grundsätze",

  imprintPrivacySubTitle9One: "Welche Daten verarbeiten wir von Ihnen und von wem erhalten wir diese Daten?",
  imprintPrivacySubTitle9OneContentOne:
    "In erster Linie verarbeiten wir personenbezogene Daten, die Sie uns übermitteln oder die wir beim Betrieb unserer Website erheben. Unter Umständen erhalten wir personenbezogene Daten über Sie auch von Dritten. Das können folgende Kategorien sein:",
  imprintPrivacy9ListOne: "Personenstammdaten (Name, Adresse, Geburtsdaten, etc.);",
  imprintPrivacy9ListTwo: "Kontaktdaten (Handynummer, E-Mailadresse, etc.);",
  imprintPrivacy9ListThree: "Finanzdaten (bspw. Kontoangaben);",
  imprintPrivacy9ListFour: "Onlinekennungen (bspw. Cookie-Kennung, IP-Adressen);",
  imprintPrivacy9ListFive: "Standort- und Verkehrsdaten;",

  imprintPrivacySubTitle9OneContentTwo: "Diese Daten können aus den folgenden Quellen stammen:",
  imprintPrivacy9ListSix: "Angaben aus öffentlich zugänglichen Quellen (z.B. Medien, Internet);",
  imprintPrivacy9ListSeven: "Angaben aus öffentlichen Registern (z.B. Handelsregister, Betreibungsregister, Grundbuch);",
  imprintPrivacy9ListEight: "Angaben im Zusammenhang mit behördlichen oder gerichtlichen Verfahren;",
  imprintPrivacy9ListNine: "Angaben betreffend Ihrer beruflichen Funktionen und Aktivitäten (bspw. berufliche Netzwerke);",
  imprintPrivacy9ListTen: "Angaben über Sie in Korrespondenz und Besprechungen mit Dritten;",
  imprintPrivacy9ListEleven: "Bonitätsauskünfte (soweit wir mit Ihnen persönliche Geschäfte abwickeln);",
  imprintPrivacy9ListTwelve:
    "Angaben über Sie, die uns Personen aus Ihrem Umfeld geben, damit wir Verträge mit Ihnen abschliessen oder abwickeln können;",
  imprintPrivacy9ListThirteen: "Daten im Zusammenhang mit der Benutzung der Webseite.",

  imprintPrivacySubTitle9Two: "Unter welchen Voraussetzungen verarbeiten wir Ihre Daten?",
  imprintPrivacySubTitle9TwoContentOne:
    "Wir verarbeiten Ihre Daten im Einklang mit den geltenden Datenschutzgesetzen, insbesondere der DSGVO. Die Verarbeitung erfolgt jeweils zu den in dieser Datenschutzerklärung festgelegten Zwecken. Wir achten dabei auf Transparenz und Verhältnismässigkeit.",
  imprintPrivacySubTitle9TwoContentTwo:
    "Die Verarbeitung Ihrer Daten ist rechtmässig, sofern ein Erlaubnistatbestand der DSGVO erfüllt ist. Als Erlaubnistatbestände kommen namentlich in Frage:",
  imprintPrivacy9ListFourteen: "Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO);",
  imprintPrivacy9ListFifteen: "Die Erfüllung eines Vertrages oder vorvertraglicher Massnahmen (Art. 6 Abs. 1 lit. b DSGVO);",
  imprintPrivacy9ListSixteen: "Die Erfüllung rechtlicher Verpflichtungen, der wir unterliegen (Art. 6 Abs. 1 lit. c DSGVO);",
  imprintPrivacy9ListSeventeen:
    "Der Schutz lebenswichtiger Interessen der betroffenen Person oder einer anderen natürlichen Person (Art. 6 Abs. 1 lit. d DSGVO);",
  imprintPrivacy9ListEighteen:
    "Die Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder die in Ausübung öffentlichen Gewalt, welche dem Verantwortlichen übertragen wurde, erfolgt (Art. 6 Abs. 1 lit. e DSGVO);",
  imprintPrivacy9ListNineteen: "Unsere berechtigten Interessen, sofern Ihre Interessen nicht überwiegen (Art. 6 Abs. 1 lit. f DSGVO).",

  imprintPrivacySubTitle9TwoContentThree:
    "Unter Umständen ist es erforderlich, dass Sie uns gewisse personenbezogene Daten bekanntgeben, damit vertragliche Pflichten erfüllt werden können. Ohne solche Daten sind wir normalerweise nicht in der Lage, einen Vertrag abzuwickeln.",
  imprintPrivacySubTitle9TwoContentFour:
    "Die Webseite kann im Normalfall ebenfalls nicht genutzt werden, wenn gewisse Angaben zur Sicherstellung des Datenverkehrs, wie Ihre IP-Adresse, nicht offengelegt werden.",

  imprintPrivacySubTitle9Three: "In welchen Fällen können wir Ihre Daten an Dritte weitergeben?",
  imprintPrivacyTitleA: "a. Grundsatz",
  imprintPrivacySubTitle9ThreeContentOneA: `Wir sind unter Umständen darauf angewiesen, die Dienste Dritter oder von verbundenen Unternehmen in Anspruch zu nehmen und diese mit der Verarbeitung Ihrer Daten zu beauftragen (sog. Auftragsverarbeiter). Kategorien der Empfänger sind namentlich:`,

  imprintPrivacy9ListTwenty: "Buchhaltung, Treuhand und Revisionsunternehmen;",
  imprintPrivacy9ListTwentyOne: "Beratungsunternehmen (Rechtsberatung, Steuern, etc.);",
  imprintPrivacy9ListTwentyTwo: "IT-Dienstleister (Webhosting, Support, Clouddienste, Webseitengestaltung, etc.);",
  imprintPrivacy9ListTwentyThree: "Zahlungsdienstleister;",
  imprintPrivacy9ListTwentyFour: "Anbieter von Tracking-, Conversion- und Werbedienstleistungen.",

  imprintPrivacySubTitle9ThreeContentTwoA:
    "Wir stellen sicher, dass diese Dritten und unsere verbundenen Unternehmen die Voraussetzungen des Datenschutzes einhalten und Ihre personenbezogenen Daten vertraulich behandeln. Unter Umständen sind wir auch verpflichtet, Ihre personenbezogenen Daten an Behörden bekanntzugeben.",
  imprintPrivacyTitleB: "b. Weitergabe an Partner und Kooperationsunternehmen",
  imprintPrivacySubTitle9ThreeContentThreeB:
    "Wir arbeiten teils mit unterschiedlichen Unternehmen und Partnern zusammen, die Ihre Angebote auf unserer Website aufschalten. Es ist für Sie erkennbar, dass es sich um ein Drittangebot (als «Werbung» gekennzeichnet) handelt.",
  imprintPrivacySubTitle9ThreeContentFourB:
    "Wenn Sie ein solches Angebot in Anspruch nehmen, übermitteln wir Ihre personenbezogenen Daten an den entsprechenden Partner oder das Kooperationsunternehmen (z.B. Name, Funktion, Kommunikation etc.), dessen Angebot Sie wahrnehmen wollen. Diese Partner und Kooperationsunternehmen sind eigenständig für die empfangenen personenbezogenen Daten verantwortlich. Nach der Übermittlung der Daten gelten die Datenschutzbestimmungen des jeweiligen Partners.",
  imprintPrivacyTitleC: "c. Weitergabe ins Ausland",
  imprintPrivacySubTitle9ThreeContentFiveC:
    "Unter Umständen kann es im Rahmen der Auftragsbearbeitung zur Übermittlung Ihrer personenbezogenen Daten an Unternehmen im Ausland kommen. Diese Unternehmen sind im gleichen Umfang zum Datenschutz verpflichtet, wie wir selber. Die Übermittlung kann weltweit stattfinden.",
  imprintPrivacySubTitle9ThreeContentSixC:
    "Entspricht das Datenschutzniveau nicht demjenigen des EWR-Raums, so nehmen wir eine vorgängige Risikoeinschätzung vor und stellen vertraglich sicher, dass der gleiche Schutz wie im EWR-Raum garantiert wird (bspw. mittels der Standardvertragsklauseln der EU-Kommission oder anderen, gesetzlich vorgegebenen Massnahmen). Sollte unsere Risikoeinschätzung negativ ausfallen, ergreifen wir zusätzliche technische Massnahmen zum Schutz Ihrer Daten. Sie können die Standardvertragsklauseln der EU-Kommission abrufen unter folgendem Link.",

  imprintPrivacySubTitle9Four: "Wie lange bewahren wir Ihre Daten auf?",
  imprintPrivacySubTitle9FourContentOne:
    "Wir speichern personenbezogene Daten nur so lange, wie dies erforderlich ist, um die einzelnen Zwecke, zu denen die Daten erhoben wurden, zu erfüllen.",
  imprintPrivacySubTitle9FourContentTwo:
    "Vertragsdaten speichern wir länger, da wir dazu durch gesetzliche Vorschriften verpflichtet sind. Wir müssen insbesondere geschäftliche Kommunikation, geschlossene Verträge und Buchungsbelege bis zu 10 Jahren aufbewahren. Soweit wir solche Daten von Ihnen nicht mehr zur Durchführung der Dienstleistungen benötigen, werden die Daten für die weitere Verarbeitung eingeschränkt und wir verwenden diese nur noch für Zwecke der Rechnungslegung und für Steuerzwecke.",

  imprintPrivacyContentTitle10: "8. Einzelne Datenverarbeitungsvorgänge",

  imprintPrivacySubTitle10One: "Bereitstellen der Webseite und Erstellung von Logfiles",
  imprintPrivacySubTitle10OneContentOne:
    "Indem Sie www.squib.app lediglich besuchen, sich also nicht registrieren oder anderweitige Informationen preisgeben, werden nur die Daten erhoben, die Ihr Browser an unseren Server automatisiert übermittelt. Die Daten sind für den Betrieb der Website technisch erforderlich.",
  imprintPrivacy10QuestionOne: "Welche Daten verarbeiten wir?",
  imprintPrivacySubTitle10OneContentTwo:
    "Zur Bereitstellung der Webseite und bei der Erstellung von Logfiles werden insbesondere folgende Daten bearbeitet:",
  impringPrivacyUlText10One: "Name des Internetserviceproviders",
  impringPrivacyUlText10Two: "IP-Adresse",
  impringPrivacyUlText10Three: "Technische Informationen wie Browser, Betriebssystem oder Bildschirmauflösung",
  impringPrivacyUlText10Four: "das Datum und die Uhrzeit des Zugriffs",
  impringPrivacyUlText10Five: "Referrer-URL",

  imprintPrivacySubTitle10OneContentThree:
    "Diese Daten können keiner bestimmten Person zugeordnet werden und es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt.",
  imprintPrivacy10QuestionTwo: "Zu welchem Zweck verarbeiten wir die Daten?",
  imprintPrivacySubTitle10OneContentFour:
    "Die Verarbeitung der Logfiles erfolgt, um die Funktionsfähigkeit der Webseite zu garantieren und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme.",
  imprintPrivacy10QuestionThree: "Auf welcher Grundlage verarbeiten wir die Daten?",
  imprintPrivacySubTitle10OneContentFive:
    "Die Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO. Worin unsere berechtigten Interessen liegen, ergibt sich aus dem Zweck der Datenverarbeitung.",
  imprintPrivacy10QuestionFour: "Wie können Sie die Datenverarbeitung verhindern?",
  imprintPrivacySubTitle10OneContentSix:
    "Die Daten werden nur so lang gespeichert, wie dies zur Erreichung des Zweckes ihrer Erhebung notwendig ist. Dementsprechend werden die Daten nach Beendigung jeder Sitzung gelöscht. Die Speicherung der Logfiles ist für den Betrieb der Webseite zwingend notwendig, Sie haben daher keine Möglichkeit, dagegen Widerspruch zu erheben, es denn Sie besuchen unsere Webseite nicht.",

  imprintPrivacySubTitle10Two: "Kontakt",
  imprintPrivacySubTitle10TwoContentOne:
    "Sie können auf mehrere Arten mit uns in Kontakt treten. Wenn Sie mit uns in Kontakt treten und personenbezogene Daten angeben, kommt es zu einer Datenverarbeitung durch uns. Gemeint ist hier jede mündliche, schriftliche und jede weitere Form der Kontaktausnahme mit uns.",
  imprintPrivacy10QuestionFive: "Welche Daten verarbeiten wir?",
  imprintPrivacySubTitle10TwoContentTwo:
    "Wenn Sie mit uns in Kontakt treten, verarbeiten wir alle Daten, die Sie uns überlassen. Dazu zählen insbesondere:",
  impringPrivacyUlText10Six: "Name",
  impringPrivacyUlText10Seven: "E-Mail-Adresse",
  impringPrivacyUlText10Eight: "Inhalt und Zeitpunkt Ihrer Kontaktaufnahme",
  impringPrivacyUlText10Nine: "Kontaktdaten",

  imprintPrivacySubTitle10TwoContentThree:
    "Es kann vorkommen, dass Sie bestimmte Daten zwingend angeben müssen, um mit uns in Kontakt treten können, beispielsweise bei der Kontaktaufnahme über ein Kontaktformular oder wenn Sie einen Rückruf verlangen.",
  imprintPrivacy10QuestionSix: "Zu welchem Zweck verarbeiten wir die Daten?",
  imprintPrivacySubTitle10TwoContentFour:
    "Der Zweck, für den wir die Daten verwenden ergibt sich jeweils aus der Natur der Kontaktaufnahme. Wir verwenden die Daten allerdings in keinem Fall nicht für unvorhersehbare oder nicht zu erwartende Zwecke. Die häufigsten Zwecke sind Kommunikation und Rückmeldung, Kundenservice und die Bearbeitung geschäftlicher Anfragen.",
  imprintPrivacy10QuestionSeven: "Auf welcher Grundlage verarbeiten wir die Daten?",
  imprintPrivacySubTitle10TwoContentFive:
    "Die Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO. Worin unsere berechtigten Interessen liegen, ergibt sich aus dem Zweck der Datenverarbeitung.",
  imprintPrivacy10QuestionEight: "An wen geben wir die Daten weiter?",
  imprintPrivacySubTitle10TwoContentSix:
    "Die Weitergabe der Daten durch uns richtet sich nach unseren Ausführungen zur Datenweitergabe. Erfordert der Zweck Ihrer Kontaktaufnahme die Weitergabe Ihrer Daten an Dritte, so geben wir die Daten im erforderlichen Mass weiter.",
  imprintPrivacy10QuestionNine: "Wie können Sie die Datenverarbeitung verhindern?",
  imprintPrivacySubTitle10TwoContentSeven:
    "Sofern Sie mit uns in Kontakt treten, ist eine Datenverarbeitung nicht verhinderbar. Folglich müssen Sie auf eine Kontaktaufnahme mit uns verzichten, wenn Sie nicht möchten, dass Ihre Daten verarbeitet werden.",

  imprintPrivacySubTitle10Three: "Cookies",
  imprintPrivacySubTitle10ThreeContentOne:
    "Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die mit Hilfe des Browsers auf dem Betriebssystem Ihres Gerätes abgelegt werden, wenn Sie unsere Webseite aufrufen. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Teils sind Cookies technisch notwendig, damit die Webseite funktionieren kann. Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen oder ihre Laufzeit abläuft.",
  imprintPrivacy10QuestionTen: "Zu welchem Zweck verarbeiten wir die Daten?",
  imprintPrivacySubTitle10ThreeContentTwo:
    "Wir verwenden Cookies, damit wir anhand der erhobenen Daten unsere Webseite benutzerfreundlicher, effektiver und sicherer gestalten können. Insbesondere verwenden wir die Cookies für das Speichern Ihrer Präferenzen (beispielsweise Sprach- und Ortseinstellungen), die schnelle Bereitstellung und attraktive Darstellung der Websiteinhalte (beispielsweise durch die Verwendung von Fonts und Content Delivery Networks) sowie für die Analyse der Nutzung dieser Website zur statistischen Auswertung sowie für kontinuierliche Verbesserungen (in der Regel mittels Third Party Cookies). Für welche Zwecke wir die (technisch nicht notwendigen) Cookies im Einzelnen verwenden, ergibt sich aus den nachfolgenden Ausführungen in dieser Datenschutzerklärung.",
  imprintPrivacy10QuestionEleven: "Auf welcher Rechtsgrundlage verarbeiten wir die Daten?",
  imprintPrivacySubTitle10ThreeContentThree:
    "Die Rechtsgrundlage für diese Verarbeitung ist Ihre Einwilligung nach Art. 6 Abs. 1 lit a DSGVO. Soweit es sich um technisch notwendige Cookies handelt, bildet unser berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO die Rechtsgrundlage.",
  imprintPrivacy10QuestionTwelve: "An wen geben wir die Daten weiter?",
  imprintPrivacySubTitle10ThreeContentFour:
    "Die Weitergabe der Daten durch uns richtet sich nach unseren Ausführungen zur Datenweitergabe. Zudem sind die nachfolgenden Ausführungen zu den einzelnen Datenbearbeitungen in dieser Datenschutzerklärung zu beachten.",
  imprintPrivacy10QuestionThirteen: "Wie können Sie die Datenverarbeitung verhindern?",
  imprintPrivacySubTitle10ThreeContentFive:
    "Beim Aufruf der Webseite wird ein Cookie-Banner angezeigt. Cookies die Ihre Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO erfordern, werden erst aktiviert, wenn Sie Ihre Einwilligung erteilen. Verweigern Sie die Einwilligung, findet keine Datenerfassung durch die einwilligungspflichtigen Cookies statt.",
  imprintPrivacySubTitle10ThreeContentSix:
    "Die Datenerfassung durch Cookies, die wir auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO einsetzen, können sie durch den Cookie-Banner nicht verhindern. Diese für den Betrieb der Website technisch notwendigen Cookies werden auf Ihrem Rechner gespeichert. Sie können diese vollständig löschen oder durch Änderung der Einstellungen in Ihrem Browser die Übertragung deaktivieren oder einschränken. Bei Deaktivierung dieser Cookies kann die Funktionalität einzelner Dienste eingeschränkt sein.",
  imprintPrivacy10UlHeader: "Anleitungen zu den gängigsten Browsern finden Sie hier:",
  impringPrivacyUlText10Ten: "Für Chrome von Google",
  impringPrivacyUlText10Eleven: "Für Safari von Apple",
  impringPrivacyUlText10Twelve: "Für Edge von Microsoft",
  impringPrivacyUlText10Thirteen: "Für Firefox von Mozilla",

  imprintPrivacySubTitle10ThreeContentSeven:
    "Bei Cookies, die für die Erfolgs- und Reichweitenmessung oder für Werbung verwendet werden, ist für zahlreiche Dienste ein allgemeiner Widerspruch («Opt-out») über die Network Advertising Initiative (NAI), YourAdChoices (Digital Advertising Alliance) oder Your Online Choices (European Interactive Digital Advertising Alliance, EDAA) möglich.",

  imprintPrivacySubTitle10Four: "Zählpixel",
  imprintPrivacySubTitle10FourContentOne:
    "Wir können Zählpixel auf unserer Website oder in unseren E-Mails verwenden. Zählpixel werden auch als Web-Beacons bezeichnet. Bei Zählpixeln handelt es sich um kleine, üblicherweise nicht sichtbare Bilder, die beim Besuch unserer Website oder beim Öffnen unserer E-Mails automatisch abgerufen werden.",
  imprintPrivacy10QuestionFourteen: "Welche Daten verarbeiten wir?",
  imprintPrivacySubTitle10FourContentTwo:
    "Mit Zählpixeln können die gleichen Angaben wie in Logfiles erfasst werden. Zudem können Bewegungsprofile der gesamten Sitzung erhoben werden. Insbesondere werden Zählpixel durch Dritte, deren Dienste wir nutzen, eingesetzt. Über diese Dienste von Dritten wird in dieser Erklärung nachfolgend im Einzelnen informiert.",

  imprintPrivacy10QuestionFifteen: "Zu welchem Zweck verarbeiten wir die Daten?",
  imprintPrivacySubTitle10FourContentThree:
    "Zählpixel werden von verschiedenen Trackingdiensten verwendet, um die Nutzung dieser Website zu analysieren und zur statistischen Auswertung sowie für kontinuierliche Verbesserungen. Zudem können Zählpixel für E-Mail-Tracking verwendet werden.",

  imprintPrivacy10QuestionSixteen: "Auf welcher Rechtsgrundlage verarbeiten wir die Daten?",
  imprintPrivacySubTitle10FourContentFour: "Die Rechtsgrundlage für die Verbreitung ist Ihre Einwilligung nach Art. 6 Abs. 1 lit a DSGVO.",

  imprintPrivacy10QuestionSeventeen: "An wen geben wir die Daten weiter?",
  imprintPrivacySubTitle10FourContentFive:
    "Die Weitergabe der Daten durch uns richtet sich nach unseren Ausführungen zur Datenweitergabe. Beachten Sie zudem die Ausführungen in dieser Datenschutzerklärung zu den einzelnen Trackingdiensten.",

  imprintPrivacy10QuestionEighteen: "Wie können Sie die Datenverarbeitung verhindern?",
  imprintPrivacySubTitle10FourContentSix:
    "Um die Datenverarbeitung mittels Zählpixeln zu verhindern, können Sie geeignete Browsererweiterungen wie uBlockOrigin installieren und in Ihrem E-Mail-Programm externe Grafiken blockieren.",

  imprintPrivacySubTitle10Five: "Google Analytics",
  imprintPrivacySubTitle10FiveContentOne:
    "Wir verwenden auf unserer Webseite Google Analytics, eine Dienstleistung des Anbieters Google Ire-land Ltd., Google Building Gordon House, Barrow St, Dublin 4, Irland mit Hauptsitz bei der Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, beide zusammen «Google».",
  imprintPrivacy10QuestionNineteen: "Welche Daten verarbeiten wir?",
  imprintPrivacySubTitle10FiveContentTwo:
    "Google Analytics ist ein Analysedienst für Webseiten, um Informationen über die Nutzung der Webseite zu erfassen. Insbesondere können folgende Informationen erfasst werden:",
  impringPrivacyUlText10Fourteen: "IP-Adresse",
  impringPrivacyUlText10Fifteen: "Technische Informationen wie Browser, Betriebssystem oder Bildschirmauflösung",
  impringPrivacyUlText10Sixteen: "Interaktionen auf der Webseite",
  impringPrivacyUlText10Seventeen: "Dauer des Besuchs",
  impringPrivacyUlText10Eighteen: "Zeit und Datum des Webseitenaufrufs",
  impringPrivacyUlText10Nineteen: "Referrer-URL",

  imprintPrivacySubTitle10FiveContentThree:
    "Die IP-Adresse wird durch Google Analytics anonymisiert, sodass ein Personenbezug nicht mehr mög-lich ist. Wenn ein Besucher unsere Webseite zum ersten Mal besucht, kann Google Analytics eine Kennung generieren, um den Besucher bei einem erneuten Webseitenbesuch wiederzuerkennen. Falls Sie mit Ihrem Google-Konto angemeldet sind, kann die Datenverarbeitung auch geräteübergreifend erfolgen.",

  imprintPrivacy10QuestionTwenty: "Zu welchem Zweck verarbeiten wir die Daten?",
  imprintPrivacySubTitle10FiveContentFour:
    "Ihre IP-Adresse wird verwendet, um ihren ungefähren Standort zu ermitteln. Aus den daraus gewonnen Informationen können wir die Relevanz unserer Angebote in verschiedenen Regionen messen. Die IP-Adresse verwenden wir zudem, um zu ermitteln, woher die Webseitenbesucher auf unsere Webseite gelangt sind. Die technischen Informationen werden verarbeitet, damit die Webseite auf jedem Gerät zufriedenstellend angezeigt werden kann. Die Interaktionen, Dauer, Zeit und Datum werden erhoben, damit wir anhand dieser Daten unsere Marketingkampagnen und Angebote auswerten und optimieren können. Zudem können wir anhand dieser Daten ermitteln, wie die Besucher auf unserer Webseite interagieren, also welche Inhalte bei welchen Besuchern beliebt sind. Die Verarbeitung der Referrer-URL erfolgt zum Zweck der Messung der Effektivität und Analyse verschiedener Marketingkanäle.",
  imprintPrivacy10QuestionTwentyOne: "Wem geben wir die Daten weiter?",
  imprintPrivacySubTitle10FiveContentFive:
    "Die Weitergabe der Daten durch uns richtet sich nach unseren Ausführungen zur Datenweitergabe. Da es sich bei Google um ein transnationales Unternehmen handelt, können Ihre Daten von Google in die gesamte Welt übertragen werden. Insbesondere können Sie in die USA zum Hauptsitz von Google übertragen werden. Ein Land in dem die Gesetzgebung keinen angemessenen Schutz der Daten gewährleistet.",

  imprintPrivacySubTitle10Six: "Google Maps",
  imprintPrivacySubTitle10SixContentOne:
    "Wir verwenden auf unserer Webseite Google Maps, eine Dienstleistung des Anbieters Google Ireland Ltd., Google Building Gordon House, Barrow St, Dublin 4, Irland mit Hauptsitz bei der Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, beide zusammen «Google».",
  imprintPrivacy10QuestionTwentyTwo: "Welche Daten verarbeiten wir?",
  imprintPrivacySubTitle10SixContentTwo:
    "Google Maps ist ein Kartendienst für Webseiten, um Google Maps einbinden zu können. Insbesondere können folgende Informationen erfasst werden:",
  impringPrivacyUlText10Twenty: "IP-Adresse",
  impringPrivacyUlText10TwentyOne: "Technische Informationen wie Browser, Betriebssystem oder Bildschirmauflösung",
  impringPrivacyUlText10TwentyTwo: "Interaktionen auf der Karte",

  imprintPrivacySubTitle10SixContentThree:
    "Die IP-Adresse wird durch Google Maps anonymisiert, sodass ein Personenbezug nicht mehr möglich ist. Sofern Sie ein Google-Konto besitzen und angemeldet sind, die Datenverarbeitung durch Google Maps auch geräteübergreifend erfolgen.",

  imprintPrivacy10QuestionTwentyThree: "Zu welchem Zweck verarbeiten wir die Daten?",
  imprintPrivacySubTitle10SixContentFour:
    "Die IP-Adresse wird verarbeitet, um den groben Standort des Besuchers zu analysieren, damit beim Webseitenaufruf der richtige Abschnitt angezeigt wird. Die technischen Informationen werden verarbeitet, damit die Karte auf allen Geräten passend angezeigt werden kann. Ihre Interaktionsdaten werden erhoben, damit die Karte interaktiv genützt werden kann und damit wir die Kartennutzung verbessern können. Sofern Sie mit ihrem Google-Konto angemeldet sind, werden die Daten zwischen Ihren Geräten synchronisiert. Beispielsweise werden Markierungen und geplante Routen zwischen den Geräten synchronisiert.",

  imprintPrivacy10QuestionTwentyFour: "An wen geben wir die Daten weiter?",
  imprintPrivacySubTitle10SixContentFive:
    "Die Weitergabe der Daten durch uns richtet sich nach unseren Ausführungen zur Datenweitergabe. Da es sich bei Google um ein transnationales Unternehmen handelt, können Ihre Daten von Google in die gesamte Welt übertragen werden. Insbesondere können Sie in die USA zum Hauptsitz von Google übertragen werden. Ein Land in dem die Gesetzgebung keinen angemessenen Schutz der Daten gewährleistet.",

  imprintPrivacySubTitle10Seven: "PrivacyBee",
  imprintPrivacySubTitle10SevenContentOne:
    "PrivacyBee wird auf Webseiten verwendet, um alle datenschutzrelevanten Dienste zu erkennen und daraus eine individuelle Datenschutzerklärung zu erzeugen.",
  imprintPrivacy10QuestionTwentyFive: "Bleibt unsere Datenschutzerklärung immer gleich?",
  imprintPrivacySubTitle10SevenContentTwo:
    "Wir können diese Datenschutzerklärung jederzeit ändern. Die Änderungen werden auf www.squib.app veröffentlicht. Sie werden nicht gesondert informiert.",

  // Terms of Use Content
  termsOfUseTitle: "Nutzungsbedingungen",
  termsOfUseMobileTitle: "Nutzungs-\nbedingungen",
  termsOfUseLastUpdated: "Letzte Änderung: 15. März 2024",
  termsOfUseContent1One:
    "Diese Nutzungsbedingungen gelten für Ihren Zugriff auf die und Ihre Nutzung der Dienstleistungen, Websites und Apps von Squib, die Sie als «Service(s)» auf den Websites von Squib kaufen oder abonnieren.",
  termsOfUseContent1Two:
    "Überall dort, wo Sie in diesen Nutzungsbedingungen «Sie», «Ihnen», «Benutzer» oder «Kunde» sehen, bezieht sich diese Bezeichnung auf Sie, die Nutzerin oder den Nutzer der Squib Services. Ausserdem beziehen sich die Begriffe «Wir», «Uns» und «Squib» auf die Squib Ltd.",
  termsOfUseContentTitle1: "Inhalt",
  termsOfUseContentList1: "1. Vertrag und Vertragswiderruf",
  termsOfUseContentList2: "2. Gebühren und Zahlungen",
  termsOfUseContentList3: "3. Datenschutz",
  termsOfUseContentList4: "4. Ihre Inhalte",
  termsOfUseContentList5: "5. Geistiges Eigentum von Squib",
  termsOfUseContentList6: "6. Ressourcen Dritter",
  termsOfUseContentList7: "7. Kontoverwaltung",
  termsOfUseContentList8: "8. Aussetzung und Beendigung von Services",
  termsOfUseContentList9: "9. Änderungen und Aktualisierungen",
  termsOfUseContentList10: "10. Haftungsausschlüsse und Entschädigung",
  termsOfUseContentList11: "11. Sonstige Bedingungen",

  termsOfUseContentTitle2: "1. Vertrag und Vertragswiderruf",
  termsOfUseContent2One:
    "Der Vertrag über die Services tritt in Kraft, sobald der Benutzer unsere Nutzungsbedingungen akzeptiert und bei kostenpflichtigen Services diese bezahlt. Der Vertrag wird automatisch um einen weiteren monatlichen oder jährlichen Abrechnungszeitraum verlängert – bis zum Moment, wann der Benuzter dieses automatische Abonnement aufhebt. Diese Aufhebung kann jederzeit auf der Kontoverwaltungsseite durchgeführt werden (weitere Informationen in Abschnitt «Gebühren und Zahlungen» sowie «Aussetzung und Beendigung von Services»).",

  termsOfUseContentTitle3: "2. Gebühren und Zahlungen",
  termsOfUseSubTitle3One: "Servicegebühren",
  termsOfUseContent3One:
    "Sie willigen ein, sämtliche Gebühren für alle von Ihnen erworbenen oder verwendeten kostenpflichtigen Services gemäss den Preisen und Zahlungsbedingungen, die Ihnen von Squib oder dem externen Zahlungsabwickler «Stripe Payments Europe Limited» (https://stripe.com/de-ch) für die jeweiligen Services zum Zeitpunkt des Erwerbs durch Sie vorgelegt wurden und die gelegentlich gemäss Abschnitt «Preisanpassungen» unten aktualisiert werden können, zu entrichten. Sofern anwendbar, erfolgt die Abrechnung unter Verwendung der Abrechnungsmethode, die Sie auf Ihrer Kontoverwaltungsseite ausgewählt haben. Wenn Sie entschieden haben, die Gebühr per Kreditkarte zu begleichen, versichern und gewährleisten Sie, dass die von Ihnen angegebenen Kreditkartendaten korrekt sind und Sie «Stripe Payments Europe Limited» über etwaige Änderungen unverzüglich in Kenntnis setzen werden. Sofern nicht in den vorliegenden Nutzungsbedingungen so vorgesehen oder gesetzlich vorgeschrieben, sind von Ihnen gezahlte Gebühren nicht erstattungsfähig.",
  termsOfUseContent3Two:
    "Wir sind nicht verantwortlich für Leistung und Sicherheit von «Stripe Payments Europe Limited» und wir haben keine Kontrolle darüber. Sie stimmen zu, alle Gebühren zu bezahlen, die für jeden Kauf im Einklang mit den geltenden Zahlungsbedingungen berechtigt sind.",
  termsOfUseSubTitle3Two: "Abonnements",
  termsOfUseContent3Three:
    "Einige unserer Services werden auf Abonnementbasis abgerechnet (diese nennen wir «Abonnements» oder «Preispläne»). Es gibt jährliche und monatliche Abrechnungszeiträume, je nachdem, für welchen Abonnementtarif Sie sich beim Kauf eines Abonnements entscheiden. Ihr Abonnement verlängert sich bei Ablauf eines Abrechnungszeitraums automatisch, es sei denn, Sie kündigen Ihr Abonnement über Ihre Kontoverwaltungsseite; in diesem Fall gilt Ihr Abonnement bis zum Ablauf des vor der Kündigung begonnenen Abrechnungszeitraums.",
  termsOfUseContent3Four:
    "Wenn Sie Ihr Abonnement kündigen, können Sie bis zum Ende des betreffenden Abrechnungszyklus auf den Service zugreifen und Ihre Daten exportieren. Danach haben Sie für dieses Abonnement keinen Zugriff mehr auf den Service. Wenn Sie kein aktives Abonnement mehr haben, wird Ihr Konto auf den FREE-Tarif von Squib (kostenlos) umgestellt. Um Ihr Konto zu schliessen und Ihren Vertrag mit uns zu kündigen, lesen Sie bitte Abschnitt «Aussetzung und Beendigung von Services» unten.",
  termsOfUseContent3Five:
    "Sie sind für alle Steuern verantwortlich. Sie haften selbst für die Abfuhr von Steuern in Verbindung mit Ihrem Kauf und die Richtigkeit Ihrer Rechnungsinformationen.",

  termsOfUseSubTitle3Three: "Preisanpassungen",
  termsOfUseContent3Six:
    "Squib ist berechtigt, die für Sie für die Services erhobenen Gebühren jederzeit zu ändern, sofern die Änderung bei Services, die auf Abonnementbasis abgerechnet werden, erst zum Ende des laufenden Abrechnungszyklus Ihres Abonnements in Kraft tritt. Squib wird Sie vorab über Preisanpassungen informieren. Falls Sie der Gebührenänderung nicht zustimmen, können Sie Ihr Abonnement kündigen, bevor die Änderung in Kraft tritt.",

  termsOfUseSubTitle3Four: "Beantwortungs-Überschreitungsgebühren",
  termsOfUseContent3Seven:
    "Für jedes Abonnement gilt ein festes Limit für Antworten. Für das kostenlose Abonnement FREE ist das Limit 50 Teilnehmende pro Umfrage, bei den kostenpflichtigen PRO 1'000 Teilnehmende pro Monat und bei BUSINESS 50'000 Teilnehmende pro Monat. Falls Sie das Teilnehmenden-Limit für Ihr kostenpflichtiges Abonnement in einem Abrechnungszyklus überschreiten, kommt es zu zusätzlichen Kosten pro Teilnehmende («Überschreitungsgebühren»).",
  termsOfUseContent3Eight:
    "Sie willigen ein, dass nicht ausgeschöpftes Teilnehmenden-Limit nicht automatisch auf den nächsten Abrechnungszyklus angewendet werden. Soweit nicht anders angegeben, werden Ihnen anfallende Überschreitungsgebühren nachträglich über die hinterlegte Zahlungsmethode zu Beginn Ihres nächsten Abrechnungszyklus in Rechnung gestellt. Bei Nichtzahlung von Überschreitungsgebühren innerhalb von 30 Tagen nach Rechnungslegung tritt Verzug ein. Bei Nichtzahlung der Überschreitungsgebühren kann der jeweilige Service (vorbehaltlich geltender gesetzlicher Anforderungen) eingeschränkt, ausgesetzt oder gekündigt werden, was je nach geltendem Recht zu einem Verlust der mit jenem Service verbundenen Daten führen kann.",

  termsOfUseContentTitle4: "3. Datenschutz",
  termsOfUseSubTitle4One: "Datenschutz",
  termsOfUseContent4One:
    "Uns ist bewusst, dass die Übermittlung Ihrer Inhalte (wie unten definiert) an uns mit dem Vertrauen in uns verbunden ist, mit den Inhalten angemessen umzugehen. Die Datenschutzerklärung von Squib und seiner Services geht darauf ein, wie wir mit Ihren Inhalten umgehen, die als personenbezogene Daten betrachtet werden können (gemäss Definition in unserer Datenverarbeitungsvereinbarung), und wir stimmen zu, uns an diese Datenschutzerklärungen von Squib zu halten. Sie stimmen wiederum zu, dass Squib Ihre Inhalte gemäss der Squib-Datenschutzerklärung und den geltenden gesetzlichen Datenschutzbestimmungen nutzen und freigeben darf. Sie stimmen ferner zu, dass Sie für die Benachrichtigung Ihrer Befragten über die Datenschutzerklärungen von Squib verantwortlich sind. Im Falle eines Widerspruchs zwischen der Datenverarbeitungsvereinbarung und diesen Bedingungen hat die Datenverarbeitungsvereinbarung Vorrang, mit Ausnahme des Haftungsausschlusses und der Haftungsbeschränkung, für die die vorliegenden Bedingungen massgeblich sind.",
  termsOfUseSubTitle4Two: "Vertraulichkeit",
  termsOfUseContent4Two:
    "Squib behandelt Ihre Inhalte als vertrauliche Informationen und wird diese nur gemäss den vorliegenden Nutzungsbedingungen (einschliesslich der Squib-Datenschutzerklärungen) nutzen und offenlegen. Ihre Inhalte gelten allerdings nicht als vertrauliche Information, wenn: a) sie ohne Verstoss gegen diese Bedingungen seitens Squib öffentlich bekannt werden oder wurden, b) sie Squib über rechtmässige Wege bereits bekannt waren, bevor sie von Ihnen unterbreitet wurden, c) Squib die Inhalte von Dritten erhält, ohne dabei Kenntnis von Pflichtverletzungen Ihnen gegenüber zu haben, d) Ihre Inhalte bei der Migration Ihres Kontos in ein Enterprise-Konto einer Organisation freigegeben werden, wenn Ihr Konto mit einer Arbeits-E-Mail-Adresse von dieser Organisation registriert wurde oder e) Sie ohne Bezugnahme auf Ihre Inhalte von Squib eigenständig entwickelt wurden. Squib ist berechtigt, Ihre Inhalte im Rahmen rechtlicher oder gesetzlicher Pflichten offenzulegen, jedoch erst, nachdem Squib im Rahmen gesetzlicher Vorgaben wirtschaftlich angemessene Massnahmen unternommen hat, um Sie in Kenntnis zu setzen und es Ihnen so zu ermöglichen, die Pflicht zur Offenlegung anzufechten.",
  termsOfUseSubTitle4Three: "Sicherheit",
  termsOfUseContent4Three:
    "Squib speichert und verarbeitet Ihre Inhalte auf eine den allgemeinen Sicherheitsstandards konforme Weise. Squib hat geeignete technische, organisatorische und administrative Systeme, Richtlinien und Verfahren implementiert.",
  termsOfUseContent4Four:
    "Wenn Squib von Zugriff auf oder von Erwerb, Nutzen, Veränderung, Offenlegung oder Vernichtung personenbezogener Daten (Definition gemäss unserer Vereinbarung zur Datenverarbeitung) in Bezug auf Ihr Konto, die auf unbefugte oder widerrechtliche Weise erfolgen, Kenntnis erhält («Sicherheitsvorfall»), ergreift Squib angemessene Massnahmen, um Sie unverzüglich davon zu unterrichten. Eine solche Benachrichtigung darf nicht als Zugeben von Fehlern oder Übernahme von Haftung durch Squib interpretiert oder ausgelegt werden. Ein Sicherheitsvorfall umfasst keine erfolglosen Anmeldeversuche, Pings, Port-Scans, Denial-of-Service-Angriffe oder anderen Netzwerkangriffe auf Firewalls oder vernetzte Systeme. Squib wird auch im angemessenen Umfang mit Ihnen bei möglichen Untersuchungen zu Sicherheitsvorfällen kooperieren, die erforderlichen Mitteilungen und Informationen im angemessenen Umfang nach Anfrage von Ihnen bereitstellen, sofern sie uns in Bezug auf einen Sicherheitsvorfall vorliegen, wenn solche Informationen Ihnen nicht direkt in Ihrem Konto oder online mittels von Squib bereitgestellten Updates zur Verfügung stehen.",

  termsOfUseContentTitle5: "4. Ihre Inhalte",
  termsOfUseSubTitle5One: "Eigentum Ihrer Inhalte",
  termsOfUseContent5One:
    "Im Laufe der Nutzung der Services können Sie Inhalte an Squib übermitteln (einschliesslich personenbezogener Daten von Ihnen und anderen) und Dritte können über die Services an Sie Inhalte übermitteln (alles vorstehend Genannte wird als Ihre «Inhalte» bezeichnet). Sie behalten das Eigentum an allen Urheberrechten an Ihren Inhalten. Squib macht bezüglich Ihrer Inhalte keine Eigentumsrechte geltend. Die vorliegenden Nutzungsbedingungen gewähren uns keinerlei Lizenzen und Rechte an Ihren Inhalten ausser der eingeschränkten Lizenz, die in diesen Bedingungen festgelegt ist. Wenn Sie also unsere Services nutzen, behalten Sie, was Ihnen gehört, und erlauben uns, es nur so zu nutzen, wie es notwendig ist, um unsere Services weiterhin bereitzustellen und zu verbessern, wie in unseren Datenschutzerklärungen angegeben.",
  termsOfUseSubTitle5Two: "Zusicherungen und Garantien",
  termsOfUseContent5Two:
    "Sie sichern zu und garantieren, dass: (a) Sie die entsprechenden Rechte an Ihren Inhalten besitzen oder kontrollieren, einschliesslich des geistigen Eigentums Dritter; und (b) Sie keine Inhalte oder Materialien absenden, hochladen oder anderweitig über die Services zur Verfügung stellen werden, welche den allgemeingültigen Richtlinien zur zulässigen Nutzung verletzen. Dies beinhalten Hassreden, Mobbing, Diskriminierung jeglicher Minderheiten, Belästigung und kriminelle Aktivitäten, Pornografie, anstössige Bilder sowie Verletzungen des geistigen Eigentums.",

  termsOfUseSubTitle5Three: "Zuständigkeit für Ihre Inhalte",
  termsOfUseContent5Three:
    "Sie sind für Ihre Inhalte verantwortlich. Wir sind nicht dafür verantwortlich, was Sie mit Ihren Inhalten machen, und können uns weigern, Ihre Inhalte anzuzeigen, wenn wir der Auffassung sind, dass diese illegal sind oder unsere Bedingungen verletzen. Dem geltenden Recht entsprechend haftet Squib nicht für Ihre Inhalte, andere Inhalte oder Materialien von Dritten oder für Verluste oder Schäden, die sich aus der Verwendung dieser Inhalte oder anderer Inhalte oder Materialien von Dritten oder aus dem Vertrauen auf diesen ergeben.",

  termsOfUseContentTitle6: "5. Geistiges Eigentum von Squib",
  termsOfUseContent6One:
    "Weder durch diese Bedingungen noch durch die Nutzung der Services werden Ihnen Eigentumsrechte an den Services oder den Inhalten, auf die Sie über die Services zugreifen, eingeräumt. Davon ausgenommen sind Ihre eigenen Inhalte. Wenn Sie uns zu unseren Services Feedback oder Vorschläge zukommen lassen, sind wir berechtigt, diese ohne Vergütung an Sie und ohne Verpflichtung Ihnen gegenüber für beliebige Zwecke zu verwenden.",

  termsOfUseContentTitle7: "6. Ressourcen Dritter",
  termsOfUseContent7One:
    "Squib ist berechtigt, Links zu Internet-Websites, die von Dritten unterhalten werden, auf seinen Services zu veröffentlichen. Squib gewährleistet nicht, dass es solche Drittanbieter-Websites geprüft hat, und ist weder für sie noch für die dort erscheinenden Inhalte verantwortlich. Marken, die in Verbindung mit den Services angezeigt werden, sind Eigentum ihrer jeweiligen Inhaber.",

  termsOfUseContentTitle8: "7. Kontoverwaltung",
  termsOfUseSubTitle8One: "Passwort-, Email- und Kontodatensicherheit",
  termsOfUseSubTitle8OneMobile: "Passwort-, Email-\nund Kontodaten-\nSicherheit",
  termsOfUseContent8One:
    "Wir arbeiten kontinuierlich daran, die Sicherheit Ihres Kontos aufrechtzuerhalten. Sie müssen ein Kundenkonto mit einem sicheren Passwort erstellen, um unsere Services zu nutzen. Geben Sie Passwörter niemals weiter. Denn Sie und nicht Squib sind für alle in Ihrem Konto stattfindenden Aktivitäten (mit Ausnahme solcher Aktivitäten, für die Squib direkt verantwortlich ist und die nicht auf Ihre Anweisung hin ausgeführt werden) unabhängig davon verantwortlich, ob solche Aktivitäten von Ihnen genehmigt wurden oder nicht.",
  termsOfUseContent8Two:
    "Halten Sie auch Ihre E-Mail- und Kontodaten stets auf dem aktuellen Stand. Squib versendet bisweilen Hinweise an die für Ihr Konto registrierte E-Mail-Adresse. Es obliegt Ihnen, Ihre E-Mail-Adresse und ggf. weitere mit Ihrem Konto verknüpfte Kontakt- und Zahlungsdaten korrekt und auf dem aktuellen Stand zu halten. Sie gewährleisten, dass Ihre Kontodetails richtig sind.",
  termsOfUseSubTitle8Two: "Kontoinaktivität",
  termsOfUseContent8Three:
    "Squib ist berechtigt, Ihr Konto zu schliessen und alle darin enthaltenen Inhalte zu löschen, sofern für mehr als 12 Monate keinerlei Kontoaktivitäten (z. B. Anmeldeereignisse oder Zahlungen) stattgefunden haben. Wir werden, falls angemessen, vor dem Schliessen Ihres Kontos wegen Inaktivität versuchen, Sie per E-Mail vorab zu warnen, um Ihnen die Möglichkeit zu bieten, sich bei Ihrem Konto anzumelden, damit dieses aktiv bleibt.",

  termsOfUseContentTitle9: "8. Aussetzung und Beendigung von Services",
  termsOfUseSubTitle9One: "Durch Sie",
  termsOfUseContent9One:
    "Sie können Ihr Abonnement jederzeit über die Kontoverwaltungsseite kündigen. Wir bieten keine Rückerstattungen bereits getätigter Zahlungen. Die Ausnahme ist, dass die Kündigung auf einer wesentlichen Vertragsverletzung durch uns beruht oder eine Erstattung ist gesetzlich vorgeschrieben ist. Wir behalten uns das Recht vor, 30 Tage nach Beendigung Ihres Abonnements alle Ihre Daten inklusive aller Umfragen und Umfrageergebnisse ohne Ankündigung zu löschen.",
  termsOfUseSubTitle9Two: "Durch Squib",
  termsOfUseContent9Two:
    "Squib kann Ihr Abonnement ohne Rückerstattung für einen früheren Zeitraum mit Wirkung vom Ende eines Abrechnungszyklus kündigen, wobei Sie mindestens 30 Tage im Voraus schriftlich benachrichtigt werden.",
  termsOfUseContent9Three:
    "Squib ist berechtigt, Ihr Abonnement aus einem der folgenden Gründe zu beenden: I) Sie haben wesentlich gegen die vorliegenden Bedingungen verstossen, II) Sie zahlen Entgelte nicht spätestens 30 Tage nach Fälligkeitsdatum, III) Sie nutzen die Services auf eine Weise, aus der Haftungsansprüche gegen uns erwachsen oder die die Nutzung der Services durch Dritte unterbindet oder IV) wir sind durch geltendes Recht gezwungen, dies zu tun. Ferner werden wir uns, wenn wir Ihr Abonnement deaktivieren oder kündigen und/oder die Services einstellen, je nach Grund bemühen, Ihnen vorab eine entsprechende Mitteilung zukommen zu lassen und die Möglichkeit zu gewähren, eine Kopie Ihrer Inhalte aus diesem Service zu exportieren, Es können allerdings dringende Situationen eintreten, in denen Squib sich für unverzügliche Massnahmen ohne vorherige Benachrichtigung entscheidet.",
  termsOfUseContent9Four:
    "Die Umfragen und die Ergebnisse bleiben für PRO-Kunden zwei Monate und für BUSINESS-Kunden vier Jahre gespeichert und abrufbar. Im Falle einer Insolvenz von Squib Ltd. behalten wir uns das Recht vor, diese Zugriffsfristen anzupassen. In einem solchen Szenario verpflichtet sich Squib, betroffene Kunden mindestens 30 Tage im Voraus schriftlich zu informieren.",

  termsOfUseContentTitle10: "9. Änderungen und Aktualisierungen",
  termsOfUseSubTitle10One: "Änderungen an Bedingungen und Services",
  termsOfUseContent10One:
    "Squib kann diese Bedingungen jederzeit aus vielerlei Gründen ändern, z.B. um Änderungen der geltenden Gesetze oder Aktualisierungen der Services widerzuspiegeln und neue Services oder Funktionen zu berücksichtigen, aus Sicherheitsgründen oder zur Vermeidung von Missbrauch oder Schaden. Daher ist Squib berechtigt, einem Service, den es Ihnen bereitstellt, jederzeit und ohne vorherige Ankündigung Funktionen hinzuzufügen oder im Service vorhandene Funktionen zu ändern. Squib behält sich ferner das Recht vor, einen Service, den es Ihnen bereitstellt, nach eigenem Ermessen einzuschränken, auszusetzen oder einzustellen. Wenn Squib einen Service einstellt, werden wir Sie in angemessener Frist vorab darüber informieren, um Ihnen die Möglichkeit zu gewähren, eine Kopie Ihrer Inhalte aus diesem Service zu erhalten.",
  termsOfUseSubTitle10Two: "Herabstufungen",
  termsOfUseContent10Two:
    "Durch ein Downgrade Ihres Kontotarifs (Kündigen eines Abonnements oder Downgrade auf unseren kostenlosen Preisplan) kann es zum Verlust der Inhalte, Funktionen, Funktionalität oder Kapazität Ihres Kontos kommen.",

  termsOfUseContentTitle11: "10. Haftungsausschlüsse und Entschädigung",
  termsOfUseSubTitle11One: "Haftungsausschlüsse",
  termsOfUseContent11One:
    "Squib strebt danach, Ihnen stets ein optimales Nutzungserlebnis unserer Online-Services zu bieten, die allerdings aus verschiedenen Gründen temporär ausfallen können. Im Rahmen des geltenden Rechts und sofern nicht anders in diesen Bedingungen festgelegt, bieten wir die Services und zugehörige Beratungen ohne jegliche ausdrücklichen, stillschweigenden oder gesetzlichen Garantien, einschliesslich, aber nicht beschränkt auf, Garantien der Marktgängigkeit, Zweckeignung oder Nichtverletzung, sowie Verfügbarkeit, Zuverlässigkeit oder Genauigkeit. Innerhalb der gesetzlichen Zulässigkeit übernimmt Squib keine Haftung für indirekte, Folge-, spezielle, zufällige oder strafbewehrte Schäden oder Verluste, einschliesslich Nutzungsausfälle, Datenverlust, entgangene Geschäfte oder Gewinne, die durch die Nutzung der Services oder gemäss diesen Nutzungsbedingungen entstehen, unabhängig von der Rechtsgrundlage, selbst wenn auf die Möglichkeit solcher Schäden hingewiesen wurde.",
  termsOfUseSubTitle11Two: "Entschädigung",
  termsOfUseContent11Two:
    "Wenn es sich bei Ihnen um ein Unternehmen handelt, stellen Sie Squib (einschliesslich seiner und deren Führungskräfte, Vertretende und Mitarbeitende) von jeglicher Haftung, Schadensersatz und Kosten (einschliesslich Abwicklungskosten und zumutbarer Anwaltskosten) frei, die sich aus Ansprüchen Dritter in Bezug auf oder in Verbindung mit der Nutzung der Services oder durch einen Verstoss gegen die vorliegenden Nutzungsbedingungen durch Sie oder Ihre Endbenutzer ergeben, insoweit diese Haftungen, Schäden und Kosten durch Sie oder Ihre Endbenutzer verschuldet wurden.",

  termsOfUseContentTitle12: "11. Sonstige Bedingungen",
  termsOfUseSubTitle12One: "Abtretung",
  termsOfUseContent12One:
    "Eine Abtretung dieser Bedingungen ohne die vorherige schriftliche Zustimmung von Squib, die nach alleinigem Ermessen von Squib vorenthalten werden darf, ist Ihnen nicht gestattet. Squib kann diese Bedingungen jederzeit ohne Erteilung einer Nachricht an Sie abtreten.",

  termsOfUseSubTitle12Two: "Eigenständige Vertragsnehmende",
  termsOfUseContent12Two:
    "Sie und Squib führen das Verhältnis voneinander unabhängiger Vertragsnehmenden, und kein Vertragspartner hat den Status eines Teilhabers, Mitarbeiters oder Handlungsbevollmächtigten des jeweils anderen Vertragspartners.",

  termsOfUseSubTitle12Three: "Auslegung",
  termsOfUseContent12Three:
    "Die Begriffe «umfassen», «einschliesslich», «wie beispielsweise» und ähnliche Begriffe sind nicht als Einschränkung weiterer Inhalte auszulegen.",

  termsOfUseSubTitle12Four: "Sprache",
  termsOfUseContent12Four:
    "Die vorliegenden Bedingungen wurden in der deutschen Sprache ausgestaltet und verfasst. Soweit übersetzte Fassungen der deutschen Fassung widersprechen, ist die deutsche Fassung in dem nach geltendem Recht zulässigen Ausmass rechtskräftig.",

  termsOfUseSubTitle12Five: "Kein Verzicht",
  termsOfUseContent12Five:
    "Wenn ein Vertragspartner auf die Durchsetzung einer Bestimmung in diesen Bedingungen verzichtet oder sie mit zeitlicher Verzögerung ausübt, gilt dies nicht als Verzicht auf seine Rechte zur späteren Durchsetzung.",

  termsOfUseSubTitle12Six: "Salvatorische Klausel",
  termsOfUseContent12Six:
    "Wenn eine Regelung in diesen Bedingungen von einem zuständigen Gericht als nicht durchsetzbar erklärt wird, gilt die Regelung als aus diesen Bedingungen ausgegliedert und die anderen Regelungen bleiben vollständig bestandskräftig.",

  termsOfUseSubTitle12Seven: "Drittbegünstigte",
  termsOfUseContent12Seven: "Drittbegünstigte dieser Bedingungen existieren nicht.",

  termsOfUseSubTitle12Eight: "Beta-Services",
  termsOfUseContent12Eight:
    "Squib ist berechtigt, bestimmte Produkte oder Features als Test-, Alpha-, Pre-Beta- oder Beta-Version für den vorzeitigen Zugriff oder als Sammelbegriff Beta-Version («Beta-Service») zu gestalten oder anzubieten. Wenn Sie einen Beta-Service nutzen, gelten zusätzlich die folgenden Bedingungen: ",
  termsOfUseContent12Nine:
    "Ein Beta-Service wird zum Testen und zur Evaluierung angeboten. Er stellt nicht das endgültige Produkt oder den endgültigen Service dar und kann Programmfehler enthalten, die zu Systemausfällen oder sonstigen Störungen, Fehlern und Datenverlusten führen können. Squib entscheidet sich evtl. dazu, keine kommerzielle Version des Beta-Service freizugeben. Squib kann nach eigenem Ermessen und jederzeit den Beta-Service entfernen oder absetzen und eine kommerzielle Version des Beta-Service freigeben. Sie müssen die Nutzung des Beta-Service unverzüglich einstellen, wenn wir Sie dazu auffordern. Im Gegenzug für die Nutzung eines Beta-Service erklären Sie sich damit einverstanden, dass Squib Daten bezüglich Ihrer Nutzung des Beta-Service einschliesslich Feedback erfasst und Ihre Inhalte analysieren (einschliesslich manueller Überprüfungen) kann, um den Service zu verbessern und Fehler zu beheben. Falls Sie nicht möchten, dass dieser Nutzungs- oder Feedbackdaten erfasst oder der Inhalt analysiert wird, müssen Sie die Nutzung des Beta-Service unverzüglich einstellen.",
  termsOfUseContent12Ten:
    "Squib erteilt keinerlei Zusicherungen oder Garantien in Bezug auf Beta-Services, darunter keine Garantie, dass ein Beta-Service allgemein verfügbar sein wird, unterbrechungs- oder fehlerfrei funktionieren wird oder dass Ihre Inhalte sicher sind oder nicht verloren gehen oder nicht beschädigt werden. Squib übernimmt keine Haftung, die sich aus oder in Verbindung mit einem Beta-Service ergeben. Jegliche Nutzung eines Beta-Service durch Sie erfolgt auf Ihr eigenes Risiko und nach Ihrem eigenen Ermessen.",

  // Metadata Content
  metadataContentHome:
    "Squib - die einfache und zuverlässige Art, Feedback zu erhalten. Erstelle attraktive Umfragen, mit denen du garantiert und schnell Feedback erhältst. Einfach clever. Professionell. Mit deinem Logo. Jetzt kostenlos testen.",
  metadataContentExplore:
    "Weshalb Squib? Mit Squib holst du erfolgreicher Feedback ein als bisher. Dank einzigartiger Nutzerfreundlichkeit, welche die Zielgruppen zur Teilnahme motiviert.",
  metadataContentUseCases:
    "Squib - Use Cases Alles geht: Mitarbeitende miteinbeziehen, Kunden befragen, Schüler um Feedback bitten, Online-Community involvieren etc.",
  metadataContentContact:
    "Squib - Jetzt Kontakt aufnehmen. Kontaktformular für alle deine Fragen, für Feedback und für Stakeholder Management.",
  metadataContentImprintPrivacy:
    "Squib - Impressum und Datenschutz Wir nehmen den Schutz deiner persönlichen Daten sehr ernst! Hinweise zum Datenschutz, zu individuellen Rechten sowie Dienste von Drittanbietern.",
};
