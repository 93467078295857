import React from "react";
import { Helmet } from "react-helmet";
import MetadataContent from "./content";

interface MetadataProps {
  title?: string;
  german?: string;
  english?: string;
}

const MetaData = (props: MetadataProps & MetadataContent) => {
  return (
    <div className="metadata">
      <Helmet>
        <title>{`Squib - ${props.title}`}</title>
      </Helmet>
    </div>
  );
};

export default MetaData;
