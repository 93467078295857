import React from "react";
import "../../../../../resources/styles/components/useCases-components/competitive-feedback.css";
import CardUseCasesLayout from "../../common/card-layout/CardUseCasesLayout";
import images from "../../../../../resources/images";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

interface CompetitiveFeedbackProps {
  title: string;
  subTitle: string;
  brandHeader?: string;
  brandImage?: string;
}

const CompetitiveFeedback = (props: CompetitiveFeedbackProps) => {
  const { t } = useTranslation("translation");
  const cardThreeHeader = t("useCasesThirdCardHeader", { ns: "translation" });
  const cardOneText = "Top";
  const cardTwoText = t("cardThreeUseCasesText", { ns: "translation" });
  const spanTextTwo = "80/80";

  return (
    <div className="fading-scroll-animation">
      <div className="competitive-feedback-container fading-scroll-animation">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, delay: 3, ease: "easeInOut" }}
          className="competitive-feedback-wrapper"
        >
          <h1>{props.title}</h1>
          <p>{props.subTitle}</p>
          <p>{props.brandHeader}</p>
          <img src={props.brandImage} alt="brand" className="startup-nights-img" />
        </motion.div>
        <img src={images.example3} alt="mobile voting card three" className="mobile-voting-card-three" />
        {/*<CardUseCasesLayout
          id="competitiveFeedbackID"
          className="inner-card-one-cf-text"
          classNameTwo="inner-card-two-cf-text"
          headerTextStyles="card-header-cf-text"
          backgroundCardOne="cf-background"
          backgroundCardTwo="cf-background"
          thumbUpImage="thumb-up"
          thumbDownImage="thumb-down"
          numWrapper="num-wrapper-cf"
          cardHeaderText={cardThreeHeader}
          cardOneText={cardOneText}
          cardOneTextClass="card-one-cf-text"
          cardTwoText={cardTwoText}
          cardTwoTextClass="card-two-cf-text"
          spanTextTwo={spanTextTwo}
          funnyImgOneCardClassWrapper="funny-img-one-card-cf"
          funnyImgTwoCardClassWrapper="funny-img-two-card-cf"
          classBrandLogo="brand-logo-startup-nights"
          brandLogo={images.startUpNights}
          classLines={[
            "line-one-cf",
            "line-two-cf",
            "line-three-cf",
            "line-four-cf",
            "line-five-cf",
            "line-six-cf",
            "line-seven-cf",
            "line-eight-cf",
            "line-nine-cf",
            "line-ten-cf",
            "line-eleven-cf",
            "line-twelve-cf",
            "line-thirteen-cf",
            "line-fourteen-cf",
          ]}
        /> */}
      </div>
    </div>
  );
};

export default CompetitiveFeedback;
