import React from "react";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";
import "../../../../../resources/styles/components/success-stories-components/success-stories-header-text.css";
import { useTranslation } from "react-i18next";

const SuccessStoriesHeaderTitle = () => {
  const { t } = useTranslation("translation");
  const title = t("successStoriesTitle", { ns: "translation" });
  const subtitle = t("successStoriesSubTitle", { ns: "translation" });

  return (
    <div className="success-stories-header-container">
      <div className="success-stories-header-wrapper">
        <HeaderTitleComp title={title} subTitle={subtitle} />
      </div>
    </div>
  );
};

export default SuccessStoriesHeaderTitle;
