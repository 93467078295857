import React from "react";
import "../../../../../resources/styles/components/pricing-features-components/pricing-features-header-text.css";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";
import { useTranslation } from "react-i18next";

const PricingFeaturesHeaderText = () => {
  const { t } = useTranslation("translation");
  const pricingFeaturesTitle = t("pricingFeaturesTitle", { ns: "translation" });
  const pricingFeaturesSubTitle = t("pricingFeaturesSubTitle", { ns: "translation" });

  return (
    <div className="pricing-features-header-container">
      <div className="pricing-features-header-wrapper">
        <HeaderTitleComp title={pricingFeaturesTitle} subTitle={pricingFeaturesSubTitle} />
      </div>
    </div>
  );
};

export default PricingFeaturesHeaderText;
