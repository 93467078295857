import React from "react";
import { useTranslation } from "react-i18next";
import { TbWorld } from "react-icons/tb";
import "../../../resources/styles/components/translation-comps-mobile.css";

interface TranslationProps {
  className?: string;
}

const MobileTranslation = ({ className }: TranslationProps) => {
  const { i18n } = useTranslation();

  const clickLanguageChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const language = e.currentTarget.value;
    i18n.changeLanguage(language);
  };

  const changeColorDe = () => {
    return i18n.language === "de" ? (
      <h3 style={{ fontFamily: "PublicaSans-Bold", fontWeight: "400" }}>DE</h3>
    ) : (
      <h3 style={{ color: "gray", fontFamily: "PublicaSans-Regular", fontWeight: "400" }}>DE</h3>
    );
  };

  const changeColorEn = () => {
    return i18n.language === "en" ? (
      <h3 style={{ fontFamily: "PublicaSans-Bold", fontWeight: "400", background: "transparent" }}>EN</h3>
    ) : (
      <h3 style={{ color: "gray", fontFamily: "PublicaSans-Regular", fontWeight: "400", background: "transparent" }}>EN</h3>
    );
  };

  return (
    <div className={`translation-btn-mobile-wrapper ${className}`}>
      <TbWorld className="world-icon" />
      <button className="de-btn active" value="de" onClick={clickLanguageChange}>
        {changeColorDe()}
      </button>
      <div className="vertical-line"></div>
      <button className="en-btn" value="en" onClick={clickLanguageChange}>
        {changeColorEn()}
      </button>
    </div>
  );
};

export default MobileTranslation;
