import React from "react";
import IntegrationLayout from "../layout/IntegrationLayout";

const IntegrationThree = () => {
  return (
    <IntegrationLayout
      title="Embedding with iFrame"
      description="Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna."
    />
  );
};

export default IntegrationThree;
