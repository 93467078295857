import React from "react";
import "../../../../resources/styles/components/contact-components/contact-comp.css";
import ContactHeaderText from "./contact-header-text/ContactHeaderText";
import ContactForm from "./contact-form/ContactForm";
import Address from "./address/Address";
import { useTranslation } from "react-i18next";
import MetaData from "../common/metadata/MetaData";
import { metadataContent } from "../common/metadata/content";

interface ContactProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  contactHeader?: string;
  title: string;
  subTitle: string;
  startup: string;
  place: string;
  desc: string;
  street: string;
  zip: string;
  tel: string;
  email: string;
  country: string;
}

function ContactUs(props: ContactProps) {
  const { t } = useTranslation("translation");
  const mdText = t("contact", { ns: "translation" });

  return (
    <>
      <MetaData
        title={mdText}
        german={metadataContent.metadataGermanContentContact}
        english={metadataContent.metadataEnglishContentContact}
      />
      <div className="contact-wrapper">
        <ContactHeaderText />
        <Address
          contactHeader={props.contactHeader}
          startup={props.startup}
          place={props.place}
          desc={props.desc}
          street={props.street}
          zip={props.zip}
          country={props.country}
          title={""}
          subTitle={""}
        />
      </div>
    </>
  );
}

export default ContactUs;
