import React from "react";
import "../../../../../resources/styles/components/common/up-scroll-arrow.css";

interface UpScrollArrowProps {
  imgSrc?: string;
  className?: string;
}

const handleScrollToTop = (e: React.MouseEvent<HTMLDivElement>) => {
  e.preventDefault();
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const UpScrollArrow = (props: UpScrollArrowProps) => {
  return (
    <div className={`up-arrow-wrapper ${props.className}`} onClick={handleScrollToTop}>
      <img src={props.imgSrc} alt="chevron-up" className={`${props.className}`} width={45} />
    </div>
  );
};

export default UpScrollArrow;
