import React from "react";
import "../../../../resources/styles/components/home-components/home-cards-comp.css";

interface HomeCardProps {
  description: string;
  image: string;
}

const homeCards: HomeCardProps[] = [
  {
    description: "Create a new voting",
    image: "/images/char-03.png",
  },
  {
    description: "Manage existing votings",
    image: "/images/char-04.png",
  },
  {
    description: "Stats and analysis",
    image: "/images/char-07.png",
  },
];

const HomeComp = () => {
  return (
    <div className="home-cards-comp-container">
      <h1>Hi. What are you up to?</h1>
      <div className="home-cards-comp-wrapper">
        {homeCards.map((card, index) => (
          <div className="home-cards" key={index}>
            <img src={card.image} alt="card" />
            <div className="text-home-cards-wrapper">
              <p>{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeComp;
