import React from "react";
import "../../../../../resources/styles/components/common/common-header-title.css";

interface HeaderTitleCompProps {
  title: string;
  secondTitle?: string;
  secondTitleClass?: string;
  titleMobile?: string;
  subTitle?: string;
  className?: string;
}

const HeaderTitleComp = (props: HeaderTitleCompProps) => {
  return (
    <>
      <h1 className={`common-title ${props.className}`}>{props.title}</h1>
      <p className={`${props.secondTitleClass}`}>{props.secondTitle}</p>
      <h1 className="common-title-mobile">{props.titleMobile}</h1>
      <p className="common-subtitle">{props.subTitle}</p>
    </>
  );
};

export default HeaderTitleComp;
