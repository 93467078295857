import React from "react";
import TestimonialOne from "./testimonial-one/TestimonialOne";
import TestimonialTwo from "./testimonial-two/TestimonialTwo";
import "../../../../../resources/styles/components/home-components/home-testimonials.css";

const Testimonials = () => {
  return (
    <div className="members-home-container">
      <div className="members-home-wrapper">
        <TestimonialTwo />
        <TestimonialOne />
      </div>
    </div>
  );
};

export default Testimonials;
