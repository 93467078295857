import React from "react";
import GetIntouch from "../../common/get-in-touch/GetInTouch";

const GetInTouchFAQHelp = () => {
  return (
    <div className="getintouch-faqhelp-container">
      <div className="getintouch-faqhelp-wrapper">
        <GetIntouch title="Any questions or do you need help?" callToAction="Get in touch now!" />
      </div>
    </div>
  );
};

export default GetInTouchFAQHelp;
