import React from "react";
import ComparisonAreasLayout from "../layout/ComparisonAreasLayout";

const AreaThree = () => {
  const title = "Kompetitives Feedback";
  const description =
    "Mit Squib lassen sich Vergleiche ganz spielerisch tätigen. Produkte oder Aussagen können gegenübergestellt werden, um z.B. herauszufinden, was besser ankommt.";
  return <ComparisonAreasLayout title={title} description={description} />;
};

export default AreaThree;
