import React from "react";
import FAQHelpHeaderText from "./faqhelp-header-text/FAQHelpHeaderText";
import GetInTouchFAQHelp from "./getintouch-faqhelp/GetInTouchFAQHelp";
import "../../../../resources/styles/components/faqhelp-components/faqhelp.css";
import MetaData from "../common/metadata/MetaData";

const FAQHelp = () => {
  return (
    <>
      <MetaData title="FAQ & Help" />
      <FAQHelpHeaderText />
      <GetInTouchFAQHelp />
    </>
  );
};

export default FAQHelp;
