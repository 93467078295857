import React from "react";
import "../assets/voting-footer.css";
// import logo from "../assets/images/logo.svg";
const logo: string = require("../assets/images/logo.svg").default;

const VotingFooter = () => {
  return (
    <div className="voting-footer-wraper">
      <div className="voting-footer">
        <p className="footer-text">
          <span>made with</span>
        </p>
        <a href="https://squib.app/" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="squib-logo" />
        </a>
      </div>
    </div>
  );
};

export default VotingFooter;
