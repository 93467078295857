import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, useParams, Navigate } from "react-router-dom";
import "./resources/styles/main.css";

import Functionalities from "./pages/Functionalities";
import UseCases from "./pages/UseCasesPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import Contact from "./pages/Contact";

import Navbar from "./components/basicComponents/squib-2024/nav-header/NavHeader";
import Home from "./components/basicComponents/squib-2024/home/Home";
import HomeComp from "./components/basicComponents/squib-2024/home-comp/HomeComp";
import Comparison from "./components/basicComponents/squib-2024/comparison/Comparison";
import PricingFeatures from "./components/basicComponents/squib-2024/pricing-features/PricingFeatures";
import SuccessStories from "./components/basicComponents/squib-2024/success-stories/SuccessStories";
import Security from "./components/basicComponents/squib-2024/security/Security";
import Integrations from "./components/basicComponents/squib-2024/integrations/Integrations";
import Releases from "./components/basicComponents/squib-2024/releases/Releases";
import SquibApp from "./components/basicComponents/squib-2024/app/SquibApp";
import ImprintPrivacy from "./components/basicComponents/squib-2024/imprint-privacy/ImprintPrivacy";
import FAQHelp from "./components/basicComponents/squib-2024/faq-help/FAQHelp";
import Jobs from "./components/basicComponents/squib-2024/jobs/Jobs";
import Media from "./components/basicComponents/squib-2024/media/Media";
import Voting from "./components/basicComponents/squib-2024/voting/Voting";
import CreateVoting from "./components/basicComponents/squib-2024/create-voting/CreateVoting";
import VotingDeleted from "./components/basicComponents/squib-2024/voting-deleted/VotingDeleted";
import VotingClosed from "./components/basicComponents/squib-2024/voting-closed/VotingClosed";
import Footer from "./components/basicComponents/squib-2024/footer/NewFooter";
import CookiesConsent from "./components/basicComponents/squib-2024/common/cookies/CookiesConsent";

//Dashboard
import Signup from "./components/basicComponents/squib-2024/dashboard/authentication/signup/Signup";
import Login from "./components/basicComponents/squib-2024/dashboard/authentication/login/Login";
import ThanksSignUp from "./components/basicComponents/squib-2024/dashboard/authentication/signup/ThanksSignUp";
import LoggedOut from "./components/basicComponents/squib-2024/dashboard/authentication/logged-out/LoggedOut";

// Create a new voting
import CreateNVThree from "./components/basicComponents/squib-2024/create-new-voting-comps/create-new-voting-settings/CreateNVThree";

import TextForm from "./pages/TextForm";
import NavHeaderMobile from "./components/basicComponents/squib-2024/nav-header-mobile/NavHeaderMobile";
import DynamicVoting from "./pages/DynamicVotin";

const checkUrlPattern = (url: string): boolean => {
  const pattern = /\/v\/[a-zA-Z0-9]{10}$/;
  return pattern.test(url);
};

const Layout = ({ children }: any) => {
  return (
    <>
      <NavHeaderMobile />
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

function App() {
  const currentUrl = location.pathname;

  return (
    <div className={`App ${checkUrlPattern(currentUrl) && "votingapp"}`}>
      <Router>
        <Routes>
          <Route path="/create-nv-three" element={<CreateNVThree />} />
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/home-comp"
            element={
              <Layout>
                <HomeComp />
              </Layout>
            }
          />
          <Route
            path="/comparison"
            element={
              <Layout>
                <Comparison />
              </Layout>
            }
          />
          <Route
            path="/textform"
            element={
              <Layout>
                <TextForm />
              </Layout>
            }
          />
          <Route
            path="/erkunden"
            element={
              <Layout>
                <Functionalities />
              </Layout>
            }
          />
          <Route
            path="/preise-funktionen"
            element={
              <Layout>
                <PricingFeatures />
              </Layout>
            }
          />
          <Route
            path="/anwendungsbeispiele"
            element={
              <Layout>
                <UseCases />
              </Layout>
            }
          />
          <Route
            path="/erfolgsgeschichten"
            element={
              <Layout>
                <SuccessStories />
              </Layout>
            }
          />
          <Route
            path="/sicherheit"
            element={
              <Layout>
                <Security />
              </Layout>
            }
          />
          <Route
            path="/integrationen"
            element={
              <Layout>
                <Integrations />
              </Layout>
            }
          />
          <Route
            path="/releases"
            element={
              <Layout>
                <Releases />
              </Layout>
            }
          />
          <Route
            path="/app"
            element={
              <Layout>
                <SquibApp />
              </Layout>
            }
          />
          <Route
            path="/nutzungsbedingungen"
            element={
              <Layout>
                <TermsOfUsePage />
              </Layout>
            }
          />
          <Route
            path="/kontakt"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />
          <Route
            path="/impressum-datenschutz"
            element={
              <Layout>
                <ImprintPrivacy />
              </Layout>
            }
          />
          <Route
            path="/faq-hilfe"
            element={
              <Layout>
                <FAQHelp />
              </Layout>
            }
          />
          <Route
            path="/jobs"
            element={
              <Layout>
                <Jobs />
              </Layout>
            }
          />
          <Route
            path="/medien"
            element={
              <Layout>
                <Media />
              </Layout>
            }
          />
          <Route
            path="/erstellen-abstimmung"
            element={
              <Layout>
                <CreateVoting />
              </Layout>
            }
          />
          <Route
            path="/sign-up"
            element={
              <Layout>
                <Signup />
              </Layout>
            }
          />
          <Route
            path="/login"
            element={
              <Layout>
                <Login />
              </Layout>
            }
          />
          <Route
            path="/thanks-signup"
            element={
              <Layout>
                <ThanksSignUp />
              </Layout>
            }
          />
          <Route path="/v">
            <Route path=":votingId" element={<DynamicVoting />} />
          </Route>
          <Route path="/voting-deleted" element={<VotingDeleted />} />
          <Route path="/voting-closed" element={<VotingClosed />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
