import React from "react";
import FeedBackContent from "./content/FeedBackContent";
import "../../../../../resources/styles/components/functionalities-components/why-squib-feedback.css";
import { useTranslation } from "react-i18next";

const WhySquibFeedBack = () => {
  const { t } = useTranslation("translation");
  const title = t("whySquibFeedback", { ns: "translation" });
  const propertyOne = t("propertyOne", { ns: "translation" });
  const propertyTwo = t("propertyTwo", { ns: "translation" });
  const propertyThree = t("propertyThree", { ns: "translation" });
  const propertyFour = t("propertyFour", { ns: "translation" });
  const propertyOneSub = t("propertyOneSub", { ns: "translation" });
  const propertyTwoSub = t("propertyTwoSub", { ns: "translation" });
  const propertyThreeSub = t("propertyThreeSub", { ns: "translation" });
  const propertyFourSub = t("propertyFourSub", { ns: "translation" });

  return (
    <div className="why-squib-feedback-container">
      <div className="why-squib-feedback-wrapper">
        <h1 className="fb-title">{title}</h1>
        <FeedBackContent
          titleOne={propertyOne}
          titleTwo={propertyTwo}
          titleThree={propertyThree}
          titleFour={propertyFour}
          subTitleOne={propertyOneSub}
          subTitleTwo={propertyTwoSub}
          subTitleThree={propertyThreeSub}
          subTitleFour={propertyFourSub}
        />
      </div>
    </div>
  );
};

export default WhySquibFeedBack;
