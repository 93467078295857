import React from "react";
import "../../../../resources/styles/components/footer.css";
import { useTranslation } from "react-i18next";
import Contact from "./contact/Contact";
import FooterMenu from "./footer-menu/FooterMenu";
import Policy from "./policy/Policy";
import UpScrollArrow from "../common/up-scroll-arrow/UpScrollArrow";
import images from "../../../../resources/images";
import Translation from "../../translation-btns/translation";

const NewFooter = () => {
  const { t } = useTranslation("translation");
  const title = t("contactTitle", { ns: "translation" });
  const subTitle = t("contactSubTitle", { ns: "translation" });
  const startup = "Squib Ltd.";
  const place = t("contactPlace", { ns: "translation" });
  const desc = "Smart-up";
  const street = "Zentralstrasse 9";
  const zipCity = "CH-6002 Luzern";
  const tel = "+41 41 511 46 76";
  const email = "hello@squib.app";

  return (
    <>
      <footer className="footer-container">
        {/*<div className="footer-info-wrapper">
          <Contact
            title={title}
            subTitle={subTitle}
            startup={startup}
            place={place}
            desc={desc}
            street={street}
            zip={zipCity}
            tel={tel}
            email={email}
          />
          <FooterMenu />
  </div>*/}
        <div className="policy-translation-wrapper">
          <Policy />
          <Translation className="translation-footer" />
          {/*<UpScrollArrow imgSrc={images.upArrowSvg} /> */}
        </div>
      </footer>
    </>
  );
};

export default NewFooter;
