import React, { useState } from "react";
import "../../../../../resources/styles/pages/create.css";

function VotingCards() {
  const [filebase64, setFilebase64] = useState<string>("");
  const [filebase64_2, setFilebase64_2] = useState<string>("");

  const formSubmit = (e: any) => {
    e.preventDefault();
    console.log(filebase64);
    alert("Form submitted");
  };

  function convertFile(files: FileList | null) {
    if (files) {
      const fileRef = files[0] || "";
      const fileType: string = fileRef.type || "";
      console.log("This file upload is of type:", fileType);
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev: any) => {
        // convert it to base64
        setFilebase64(`data:${fileType};base64,${btoa(ev.target.result)}`);
      };
    }
  }
  function convertFile_2(files: FileList | null) {
    if (files) {
      const fileRef = files[0] || "";
      const fileType: string = fileRef.type || "";
      console.log("This file upload is of type:", fileType);
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev: any) => {
        setFilebase64_2(`data:${fileType};base64,${btoa(ev.target.result)}`);
      };
    }
  }
  return (
    <div className="choose-vote-wrapper" onSubmit={e => formSubmit(e)}>
      <div className="choose-wrapper">
        <div className="choose-one">{filebase64.indexOf("image/") > -1 && <img src={filebase64} className="choose-img" alt="file" />}</div>
        <label htmlFor="file1" className="choose-btn-wrapper">
          <img src="./images/A.png" alt="A-icon" className="choose-btn" />
        </label>
        <input type="file" onChange={e => convertFile(e.target.files)} id="file1" />
        <div className="plus-icon-wrapper"></div>
      </div>

      <div className="choose-wrapper">
        <label htmlFor="file2" className="choose-btn-wrapper">
          <img src="./images/B.png" alt="B-icon" className="choose-btn" />
        </label>
        <div className="choose-two">
          {filebase64_2.indexOf("image/") > -1 && <img src={filebase64_2} className="choose-img" alt="file" />}
        </div>
        <input type="file" onChange={e => convertFile_2(e.target.files)} id="file2" />
        <div className="plus-icon-wrapper"></div>
      </div>
    </div>
  );
}

export default VotingCards;
