import React from "react";
import FeatureOne from "./feature-one/FeatureOne";
import FeatureTwo from "./feature-two/FeatureTwo";
import FeatureThree from "./feature-three/FeatureThree";
import FeatureFour from "./feature-four/FeatureFour";

const ReleasesFeatures = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "6rem" }}>
      <FeatureOne />
      <FeatureTwo />
      <FeatureThree />
      <FeatureFour />
    </div>
  );
};

export default ReleasesFeatures;
