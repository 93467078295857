import {
  Voting,
  Question,
  QuestionOption,
  VotingAnswer,
  VotingAnswersResponse,
  GetVotingResponse,
  GetUserSubscriptionsResponse,
  CreateVotingResponse,
  GetUserVotingsResponse,
  GetUserVotingAnswerResponse,
  SetSessionResponse,
  UserProfile,
  AuthOptions,
  CreateVotingAnswerInput,
} from "./types";
import {
  AuthError,
  createClient,
  OAuthResponse,
  PostgrestError,
  Session,
  SupabaseClient,
  User,
  AuthChangeEvent,
  Subscription,
} from "@supabase/supabase-js";

let supabase: SupabaseClient;

export const createClientWithOptions = async (authOptions: AuthOptions) => {
  if (!supabase) {
    supabase = createClient(
      "https://eyujckvbojrobtdelowx.supabase.co",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImV5dWpja3Zib2pyb2J0ZGVsb3d4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzkyMjI5NDksImV4cCI6MTk5NDc5ODk0OX0.rGG2DqxVTG_qSDyvOP6ZDdqQ8ey2UaDqredFIsbjVIQ",
      {
        auth: authOptions,
      },
    );
  }
};

export const signInWithGoogle = async (): Promise<OAuthResponse> => {
  const signInResponse = await supabase.auth.signInWithOAuth({
    provider: "google",
  });
  return signInResponse;
};

export const setUserSession = async (accessToken: string, refreshToken: string): Promise<SetSessionResponse> => {
  const response = await supabase.auth.setSession({
    access_token: accessToken,
    refresh_token: refreshToken,
  });
  let user: UserProfile | null = null;
  if (response.data && response.error == null && response.data.user) {
    const dbUser = response.data.user as User;
    user = {
      id: dbUser.id,
    };
  }
  return { user, error: response.error };
};

export const getUserSession = async (): Promise<any> => {
  const res = await supabase.auth.getSession();
  return res;
};

export const listenOnAuthStateChanges = async (
  onAuthStateChange: (event: AuthChangeEvent, session: Session | null) => void,
): Promise<Subscription> => {
  return supabase.auth.onAuthStateChange(onAuthStateChange).data.subscription;
};

export const signInWithApple = async (): Promise<OAuthResponse> => {
  const signInResponse = await supabase.auth.signInWithOAuth({
    provider: "apple",
  });
  return signInResponse;
};

export const signout = async (): Promise<AuthError | null> => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    return error;
  }
  return null;
};

// TODO: return questions data
export const createQuestion = async (
  question: Partial<Question>,
  questionOptions: Partial<QuestionOption>[],
): Promise<number | PostgrestError | null> => {
  const { data, error } = await supabase.from("questions").insert(question).select("id");
  if (error) {
    return error;
  }
  data && (await createQuestionOptions(questionOptions, data[0].id));
  return data[0]?.id;
};

export const createVoting = async (voting: Partial<Voting>, questionIds: number[]): Promise<CreateVotingResponse> => {
  const { data, error } = await supabase.from("votings").insert(voting).select();

  if (questionIds && data) {
    for (const id of questionIds) {
      const qvData = await supabase.from("votings_questions").insert({
        question_id: id,
        voting_id: data[0].id,
      });
    }
    // get its questions and options
  }
  return { voting: data as Partial<Voting>, error: error };
};

export const createQuestionOptions = async (questionOptions: Partial<QuestionOption>[], questionId: number) => {
  try {
    for (const option of questionOptions) {
      const { data, error } = await supabase.from("question_options").insert({
        ...option,
        question_id: questionId,
      });
    }
  } catch (error) {
    return error;
  }
};

export const createVotingAnswers = async (
  votingAnswers: CreateVotingAnswerInput[],
): Promise<VotingAnswersResponse[] | null | PostgrestError> => {
  const result: VotingAnswersResponse[] = [];

  for (const answer of votingAnswers) {
    const { data, error } = await supabase.from("voting_answers").insert(answer.votingAnswer);

    if (!error && answer.votingAnswer.voting_id && answer.votingAnswer.question_option_id && answer.other_question_option_id) {
      const percentage = await getVotingAnswersPercentages(
        answer.votingAnswer.voting_id,
        answer.votingAnswer.question_option_id,
        answer.other_question_option_id,
      );

      if (percentage) {
        result.push(percentage[0], percentage[1]);
      }
    } else {
      return error;
    }
  }

  await supabase.from("user_voting_subscriptions").insert({
    voting_id: votingAnswers[0].votingAnswer.voting_id,
    user_id: votingAnswers[0].votingAnswer.user_id,
  });

  return result.length > 0 ? result : null;
};

// TODO this function shouldn't accept the other option id, instead it should query the db for it
export const getVotingAnswersPercentages = async (
  votingId: number,
  firstOptionId: number,
  secondOptionId: number,
): Promise<VotingAnswersResponse[] | null> => {
  // calculate each option's percentage from total number of votes
  const votingAnswers = await supabase
    .from("voting_answers")
    .select("*")
    .eq("voting_id", votingId)
    .in("question_option_id", [firstOptionId, secondOptionId]);

  if (votingAnswers.data && votingAnswers.data.length > 0) {
    const firstOptionAnswers = votingAnswers.data?.filter(element => element.question_option_id == firstOptionId);

    const firstOptionPercentage = Math.round((firstOptionAnswers.length / votingAnswers.data.length) * 100);
    const secondOptionCount = votingAnswers.data.length - firstOptionAnswers.length;
    const secondOptionPercentage = Math.round((secondOptionCount / votingAnswers.data.length) * 100);

    return [
      {
        question_option_id: firstOptionId,
        percentage: firstOptionPercentage,
      },
      {
        question_option_id: secondOptionId,
        percentage: secondOptionPercentage,
      },
    ];
  }
  // return percentage with 0 if there is no answer for those votes.
  return [
    {
      question_option_id: firstOptionId,
      percentage: 0,
    },
    {
      question_option_id: secondOptionId,
      percentage: 0,
    },
  ];
};

export const deleteVoting = async (votingId: number): Promise<PostgrestError | null> => {
  const { error } = await supabase.from("votings").delete().eq("id", votingId);
  if (error) {
    return error;
  }
  return null;
};

export const deleteQuestion = async (questionId: number): Promise<PostgrestError | null> => {
  const { error } = await supabase.from("questions").delete().eq("id", questionId);
  if (error) {
    return error;
  }
  return null;
};

export const unsubscribeUserVoting = async (votingId: number): Promise<PostgrestError | null> => {
  const { error } = await supabase.from("user_voting_subscriptions").delete().eq("voting_id", votingId);
  if (error) {
    return error;
  }
  return null;
};

export const updateVoting = async (voting: Partial<Voting>) => {
  const data = await supabase.from("votings").update(voting).eq("id", voting.id);
};

export const updateQuestion = async (question: Partial<Question>) => {
  const data = await supabase.from("questions").update(question).eq("id", question.id);
};

export const getUserVotings = async (userId: string): Promise<GetUserVotingsResponse> => {
  let response: GetUserVotingsResponse;
  const { data, error } = await supabase
    .from("votings")
    .select(
      `
            id,
            expiry_date,
            is_locked_voting,
            is_result_hidden,
            is_shuffle_enabled,
            is_thank_you_shown,
            hashtag,
            qr_codes (id, mapped_value, mapped_type),
            questions!votings_questions(
                id,
                body,
                order_in_voting,
                question_options!question_options_question_id_fkey (id, is_custom_answer ,body ,image ,next_question_id)
            )
        `,
    )
    .eq("user_id", userId);

  const votingsWithTotalVotes: Voting[] = [];
  if (data) {
    // get total votes percentage for each of the two options in each question in a voting
    for (const voting of data as unknown as Voting[]) {
      const questionsWithVotes: Question[] = [];
      for (const question of voting.questions) {
        if (question.question_options[0].id && question.question_options[1].id) {
          const answersPercentages = await getVotingAnswersPercentages(
            voting.id,
            question.question_options[0].id,
            question.question_options[1].id,
          );
          if (answersPercentages) {
            const optionsWithTotalVotes = [
              {
                ...question.question_options[0],
                total_votes_percentage: answersPercentages[0].percentage,
              },
              {
                ...question.question_options[1],
                total_votes_percentage: answersPercentages[1].percentage,
              },
            ];
            questionsWithVotes.push({
              ...question,
              question_options: optionsWithTotalVotes,
            });
          }
        }
      }
      votingsWithTotalVotes.push({
        ...voting,
        questions: questionsWithVotes,
      });
    }
  }
  return {
    votings: votingsWithTotalVotes,
    error: error,
  };
};

// TODO: remove test user id
export const getUserSubscribedVotings = async (userId: string): Promise<GetUserSubscriptionsResponse> => {
  const { data, error } = await supabase
    .from("user_voting_subscriptions")
    .select(
      `
            voting:votings(
                id,
                expiry_date,
                is_locked_voting,
                is_result_hidden,
                is_shuffle_enabled,
                is_thank_you_shown,
                hashtag,
                qr_codes (id, mapped_value, mapped_type),
                questions!votings_questions(
                    id,
                    body,
                    order_in_voting,
                    question_options!question_options_question_id_fkey (id, is_custom_answer, body, image, next_question_id)
                )
            )
        `,
    )
    .eq("user_id", userId);

  const votingsWithTotalVotes: Voting[] = [];
  if (data && data[0].voting) {
    for (const item of data) {
      const voting: Voting = item.voting as unknown as Voting;

      const questionsWithVotes: Question[] = [];
      for (const question of voting.questions) {
        if (question.question_options[0].id && question.question_options[1].id) {
          let answersPercentages = null;
          let usersVotingAnswer = null;

          if (question.question_options[0].is_custom_answer || question.question_options[1].is_custom_answer) {
            usersVotingAnswer = await getUserVotingAnswer(
              voting.id,
              question.question_options[0].id,
              question.question_options[1].id,
              userId,
            );
          }

          if (!voting.is_result_hidden) {
            answersPercentages = await getVotingAnswersPercentages(
              voting.id,
              question.question_options[0].id,
              question.question_options[1].id,
            );
          }

          const optionsWithTotalVotes = [
            {
              ...question.question_options[0],
              total_votes_percentage: answersPercentages ? answersPercentages[0].percentage : undefined,
              user_custom_answer_text:
                usersVotingAnswer &&
                usersVotingAnswer.votingAnswer &&
                usersVotingAnswer.votingAnswer.question_option_id == question.question_options[0].id
                  ? usersVotingAnswer.votingAnswer.custom_answer_text
                  : undefined,
            },
            {
              ...question.question_options[1],
              total_votes_percentage: answersPercentages ? answersPercentages[1].percentage : undefined,
              user_custom_answer_text:
                usersVotingAnswer &&
                usersVotingAnswer.votingAnswer &&
                usersVotingAnswer.votingAnswer.question_option_id == question.question_options[1].id
                  ? usersVotingAnswer.votingAnswer.custom_answer_text
                  : undefined,
            },
          ];

          questionsWithVotes.push({
            ...question,
            question_options: optionsWithTotalVotes,
          });
        }
      }
      votingsWithTotalVotes.push({
        ...voting,
        questions: questionsWithVotes,
      });
    }
  }
  return {
    votings: votingsWithTotalVotes,
    error: error,
  };
};

export const getUserVotingAnswer = async (
  votingId: number,
  firstOptionId: number,
  secondOptionId: number,
  userId: string,
): Promise<GetUserVotingAnswerResponse> => {
  const votingAnswers = await supabase
    .from("voting_answers")
    .select("*")
    .eq("voting_id", votingId)
    .eq("user_id", userId)
    .in("question_option_id", [firstOptionId, secondOptionId])
    .maybeSingle();
  return {
    votingAnswer: votingAnswers.data as unknown as VotingAnswer,
    error: votingAnswers.error,
  };
};

export const getVoting = async (votingId: string): Promise<GetVotingResponse> => {
  const { data, error } = await supabase
    .from("votings")
    .select(
      `
              id,
              expiry_date,
              is_locked_voting,
              is_result_hidden,
              is_shuffle_enabled,
              is_thank_you_shown,
              hashtag,
              qr_codes (id, mapped_value, mapped_type),
              shareable_id,
              questions!votings_questions(
                  id,
                  body,
                  order_in_voting,
                  question_options!question_options_question_id_fkey (id, is_custom_answer ,body ,image ,next_question_id, background_colour, font_colour)
              )
            `,
    )
    .eq("shareable_id", votingId)
    .maybeSingle();
  const votingsWithTotalVotes: Voting[] = [];

  if (data) {
    // get total votes percentage for each of the two options in each question in a voting
    const voting = data as Voting;
    const questionsWithVotes: Question[] = [];
    for (const question of voting.questions) {
      if (question.question_options[0].id && question.question_options[1].id) {
        const answersPercentages = await getVotingAnswersPercentages(
          voting.id,
          question.question_options[0].id,
          question.question_options[1].id,
        );
        if (answersPercentages) {
          const optionsWithTotalVotes = [
            {
              ...question.question_options[0],
              total_votes_percentage: answersPercentages[0].percentage,
            },
            {
              ...question.question_options[1],
              total_votes_percentage: answersPercentages[1].percentage,
            },
          ];
          questionsWithVotes.push({
            ...question,
            question_options: optionsWithTotalVotes,
          });
        }
      }
      votingsWithTotalVotes.push({
        ...voting,
        questions: questionsWithVotes,
      });
    }
  }
  return {
    voting: votingsWithTotalVotes[0],
    error: error,
  };
};
