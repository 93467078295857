import React, { useRef } from "react";
import TermsOfUseHeader from "./terms-of-use-header/TermsOfUseHeader";
import "../../../../resources/styles/pages/terms-of-use.css";
import GetInTouchTermsOfUse from "./getintouch-terms-of-use/GetInTouchTermsOfUse";
import MetaData from "../common/metadata/MetaData";
import { useTranslation } from "react-i18next";
import { nutzungsbedingungenTranslationVariables } from "../../../../helpers/translationVariables";

const TermsOfUse = () => {
  const { t } = useTranslation("translation");
  const sectionsRef = useRef<(HTMLDivElement | null)[]>([]);
  const fourthTermsFourTitle = "Beantwortungs-\nÜberschreitungs-\ngebühren";

  const translations = nutzungsbedingungenTranslationVariables({ t });

  const scrollToSection = (index: number, e: React.MouseEvent) => {
    e.preventDefault();
    const section = sectionsRef.current[index];
    if (section) {
      const yOffset = -200;
      const yPosition = section.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };

  return (
    <>
      <MetaData title="Nutzungsbedingungen" />
      <div className="terms-of-use-container">
        <div className="terms-of-use-header-wrapper">
          <TermsOfUseHeader />
        </div>
        <div className="terms-of-use-cont">
          <div className="first-terms-cont">
            <h4>{translations.lastUpdated}</h4>
            <p>{translations.touContent1One}</p>
            <p>{translations.touContent1Two}</p>
          </div>

          <div className="second-terms-cont">
            <h2>{translations.touContentTitle1}</h2>
            <ul>
              {translations.touInhaltList.map((item, index) => (
                <li key={item.id}>
                  <a href="#" onClick={e => scrollToSection(index, e)}>
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="third-terms-cont" id="1" ref={el => (sectionsRef.current[0] = el)}>
            <h2>{translations.touContentTitle2}</h2>
            <p>{translations.touContent2One}</p>
          </div>

          <div className="fourth-terms-cont" id="2" ref={el => (sectionsRef.current[1] = el)}>
            <h2>{translations.touContentTitle3}</h2>
            <div className="fourth-terms__one">
              <h3>{translations.touSub3One}</h3>
              <p>{translations.touContent3One}</p>

              <p>{translations.touContent3Two}</p>
            </div>

            <div className="fourth-terms__two">
              <h3>{translations.touSub3Two}</h3>
              <p>{translations.touContent3Three}</p>

              <p>{translations.touContent3Four}</p>

              <p>{translations.touContent3Five}</p>
            </div>

            <div className="fourth-terms__three">
              <h3>{translations.touSub3Three}</h3>
              <p>{translations.touContent3Six}</p>
            </div>

            <div className="fourth-terms__four">
              <h3 className="fourth-terms__four-title">{translations.touSub3Four}</h3>
              <h3 className="fourth-terms__four-title-mobile">{fourthTermsFourTitle}</h3>
              <p>{translations.touContent3Seven}</p>

              <p>{translations.touContent3Eight}</p>
            </div>
          </div>

          <div className="fifth-terms-cont" id="3" ref={el => (sectionsRef.current[2] = el)}>
            <h2>{translations.touContentTitle4}</h2>
            <div className="fifth-terms__one">
              <h3>{translations.touSub4One}</h3>
              <p>{translations.touContent4One}</p>
            </div>

            <div className="fifth-terms__two">
              <h3>{translations.touSub4Two}</h3>
              <p>{translations.touContent4Two}</p>
            </div>

            <div className="fifth-terms__three">
              <h3>{translations.touSub4Three}</h3>
              <p>{translations.touContent4Three}</p>
              <p>{translations.touContent4Four}</p>
            </div>
          </div>

          <div className="sixth-terms-cont" id="4" ref={el => (sectionsRef.current[3] = el)}>
            <h2>{translations.touContentTitle5}</h2>
            <div className="sixth-terms__one">
              <h3>{translations.touSub5One}</h3>
              <p>{translations.touContent5One}</p>
            </div>
            <div className="sixth-terms__two">
              <h3>{translations.touSub5Two}</h3>
              <p>{translations.touContent5Two}</p>
            </div>

            <div className="sixth-terms__three">
              <h3>{translations.touSub5Three}</h3>
              <p>{translations.touContent5Three}</p>
            </div>
          </div>

          <div className="seventh-terms-cont" id="5" ref={el => (sectionsRef.current[4] = el)}>
            <h2>{translations.touContentTitle6}</h2>

            <p>{translations.touContent6One}</p>
          </div>

          <div className="eighth-terms-cont" id="6" ref={el => (sectionsRef.current[5] = el)}>
            <h2>{translations.touContentTitle7}</h2>
            <p>{translations.touContent7One}</p>
          </div>

          <div className="ninth-terms-cont" id="7" ref={el => (sectionsRef.current[6] = el)}>
            <h2>{translations.touContentTitle8}</h2>
            <div className="ninth-terms__one">
              <h3 className="ninth-terms__one-title">{translations.touSub8One}</h3>
              {/*<h3 className="ninth-terms__one-title-mobile">{translations.touSub8OneMobile}</h3>*/}
              <p>{translations.touContent8One}</p>
              <p>{translations.touContent8Two}</p>
            </div>

            <div className="ninth-terms__two">
              <h3>{translations.touSub8Two}</h3>
              <p>{translations.touContent8Three}</p>
            </div>
          </div>

          <div className="tenth-terms-cont" id="8" ref={el => (sectionsRef.current[7] = el)}>
            <h2>{translations.touContentTitle9}</h2>
            <div className="tenth-terms__one">
              <h3>{translations.touSub9One}</h3>
              <p>{translations.touContent9One}</p>
            </div>

            <div className="tenth-terms__two">
              <h3>{translations.touSub9Two}</h3>
              <ul>
                <li>
                  <div className="tenth-tems__list-item list-item-1">
                    <span>1.</span>
                    <p>{translations.touContent9Two}</p>
                  </div>
                </li>
                <li>
                  <div className="tenth-tems__list-item list-item-2">
                    <span>2.</span>
                    <p>{translations.touContent9Three}</p>
                  </div>
                </li>
                <li>
                  <div className="tenth-tems__list-item list-item-3">
                    <span>3.</span>
                    <p>{translations.touContent9Four}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="eleventh-terms-cont" id="9" ref={el => (sectionsRef.current[8] = el)}>
            <h2>{translations.touContentTitle10}</h2>
            <div className="eleventh-terms__one">
              <h3>{translations.touSub10One}</h3>
              <p>{translations.touContent10One}</p>
            </div>

            <div className="eleventh-terms__two">
              <h3>{translations.touSub10Two}</h3>
              <p>{translations.touContent10Two}</p>
            </div>
          </div>

          <div className="twelfth-terms-cont" id="10" ref={el => (sectionsRef.current[9] = el)}>
            <h2>{translations.touContentTitle11}</h2>
            <div className="twelfth-terms__one">
              <h3>{translations.touSub11One}</h3>
              <p>{translations.touContent11One}</p>
            </div>

            <div className="twelfth-terms__two">
              <h3>{translations.touSub11Two}</h3>
              <p>{translations.touContent11Two}</p>
            </div>
          </div>

          <div className="thirteenth-terms-cont" id="11" ref={el => (sectionsRef.current[10] = el)}>
            <h2>{translations.touContentTitle12}</h2>
            <div className="thirteenth-terms__one">
              <h3>{translations.touSub12One}</h3>
              <p>{translations.touContent12One}</p>
            </div>

            <div className="thirteenth-terms__two">
              <h3>{translations.touSub12Two}</h3>
              <p>{translations.touContent12Two}</p>
            </div>

            <div className="thirteenth-terms__three">
              <h3>{translations.touSub12Three}</h3>
              <p>{translations.touContent12Three}</p>
            </div>

            <div className="thirteenth-terms__four">
              <h3>{translations.touSub12Four}</h3>
              <p>{translations.touContent12Four}</p>
            </div>

            <div className="thirteenth-terms__five">
              <h3>{translations.touSub12Five}</h3>
              <p>{translations.touContent12Five}</p>
            </div>

            <div className="thirteenth-terms__six">
              <h3>{translations.touSub12Six}</h3>
              <p>{translations.touContent12Six}</p>
            </div>

            <div className="thirteenth-terms__seven">
              <h3>{translations.touSub12Seven}</h3>
              <p>{translations.touContent12Seven}</p>
            </div>

            <div className="thirteenth-terms__eight">
              <h3>{translations.touSub12Eight}</h3>
              <p>{translations.touContent12Eight}</p>
              <p>{translations.touContent12Nine}</p>
              <p>{translations.touContent12Ten}</p>
            </div>
          </div>
        </div>

        <GetInTouchTermsOfUse />
      </div>
    </>
  );
};

export default TermsOfUse;
