import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { english } from "./locales2/english";
import { german } from "./locales2/german";

export const resources = {
  en: {
    translation: english,
  },
  de: {
    translation: german,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "de",
});

export default i18next;
