import React from "react";
import AreaOne from "./area-one/AreaOne";
import AreaTwo from "./area-two/AreaTwo";
import AreaThree from "./area-three/AreaThree";

const ComparisonAreas = () => {
  const areasHeader = "Squib unterscheidet sich vor allem in den folgenden drei Bereichen:";
  return (
    <div className="comparison-areas-container">
      <h2>{areasHeader}</h2>
      <div className="areas-wrapper">
        <AreaOne />
        <AreaTwo />
        <AreaThree />
      </div>
    </div>
  );
};

export default ComparisonAreas;
