import { LottiePlayer } from "lottie-web";
import React, { useRef, useState, useEffect } from "react";

type LottieAnimType = {
  // index?: number;
  name: string;
  speed: number;
  delay?: number;
  fontSize?: number;
  // play?: boolean;
};
const Lottie = (props: LottieAnimType) => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottieAnim, setLottieAnim] = useState<LottiePlayer | null>(null);
  const [path, setPath] = useState<string>("");
  const [delay, setDelay] = useState<number>(0);

  useEffect(() => {
    import("lottie-web").then(Lottie => setLottieAnim(Lottie.default));
  }, []);

  useEffect(() => {
    if (props.name == "explosion") {
      setPath("/BoomN.json");
      setDelay(500);
    } else {
      // setPath(props.index == 0 ? "/magentaTick.json" : "/cyanTick.json");
      setPath("/Tick.json");
      setDelay(0);
    }
  }, [props.name]);

  useEffect(() => {
    if (lottieAnim && ref.current) {
      const animation = lottieAnim.loadAnimation({
        container: ref.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: path,
      });
      animation.setSpeed(props.speed);

      setTimeout(() => animation.play(), delay);

      return () => {
        animation.destroy();
      };
    }
  }, [lottieAnim]);

  return (
    <div
      ref={ref}
      style={props.fontSize ? { height: `${props.fontSize * 1.7812510555}px`, width: `${props.fontSize * 1.7812510555}px` } : {}}
      className={`${props.name == "tick" ? "tick" : "lottie-div"}`}
    />
  );
};
export default Lottie;
