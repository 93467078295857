import React from "react";
import ComparisonAreasLayout from "../layout/ComparisonAreasLayout";

const AreaTwo = () => {
  const title = "Visuelles Feedback";
  const description =
    "Squib basiert auf grafischen Antwort-möglichkeiten. Visuelle Antworten sind attraktiver und einfacher verständlich, als oftmals unnötig lange und komplizierte Sätze.";
  return <ComparisonAreasLayout title={title} description={description} />;
};

export default AreaTwo;
