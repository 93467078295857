import React, { useEffect } from "react";
import "../assets/result.css";
import { CountUp } from "use-count-up";
const user: string = require("../assets/images/user.svg").default;

interface ResultProps {
  percentage: number;
  fontSize?: number;
}

const Result = (props: ResultProps) => {
  const size = props.fontSize || 18;
  const imgHeight = size * 1.090888889;
  // 0-30 => 50, 31-50 => 0, 51-70 => 100, 71-100 => 50
  let startValue = 0;
  if ((props.percentage >= 0 && props.percentage <= 30) || (props.percentage > 70 && props.percentage <= 100)) startValue = 50;
  if (props.percentage > 50 && props.percentage <= 70) startValue = 100;

  return (
    <div className="voting-result">
      <img style={{ height: `${imgHeight}px`, width: `${imgHeight}px` }} src={user} alt="user icon" />
      <span style={{ fontSize: `${size}px`, width: `${size * 2.611729912}px` }}>
        <CountUp isCounting end={props.percentage} start={startValue} duration={1} />%
      </span>
    </div>
  );
};

export default Result;
