import React, { useRef, useState, useEffect } from "react";
import "../assets/voting.css";
import Slider, { Settings } from "react-slick";
import VotingCard from "./voting";
import { Voting, ExtendedQuestion } from "../services/types";
import FinalSlide from "./FinalSlide";
import Header from "./Header";

interface CarouselSettings extends Settings {
  onTouchStart?: (event: React.TouchEvent<HTMLDivElement>) => void;
  onTouchMove?: (event: React.TouchEvent<HTMLDivElement>) => void;
  onTouchEnd?: () => void;
  style?: React.CSSProperties;
}

interface CarouselProps extends CarouselSettings {
  questions: ExtendedQuestion[];
  voting: Voting;
  updateQuestions: (q: ExtendedQuestion) => void;
  isDev?: boolean;
  parentWidth?: number;
  isFooterhidden?: boolean;
  isDetailShown?: boolean;
  isFullScreen?: boolean;
}

const Carousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [width, setWidth] = useState<number>(525);
  const [showButton, setShowButton] = useState(false);
  const [showFinalAnim, setShowFinalAnim] = useState<boolean>(false);
  // const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const sliderRef = useRef<Slider>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  /** 
    /**this effect gets the width of the carousel and sets the width state <which represents the width of the tab wrapper>
     *  according to  the width of the carousel and so we can adjust the width of each tab accordingly   
 **/
  useEffect(() => {
    const handleResize = () => {
      const carouselWidth = containerRef.current?.offsetWidth;
      if (carouselWidth && carouselWidth < 525) {
        setWidth(carouselWidth);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const reDoMargin = () => {
    const carouselHeight = wrapperRef.current?.offsetHeight;
    if (carouselHeight) {
      const margin = window.innerHeight - carouselHeight;
      document.documentElement.style.setProperty("--mobileMargin", `${margin}px`);
    }
  };

  useEffect(() => {
    reDoMargin();
    setTimeout(reDoMargin, 50);
  }, [wrapperRef.current?.offsetHeight, window.innerHeight]);

  useEffect(() => {
    if (props.questions.length > 0 && props.questions[props.questions.length - 1].voted) {
      setTimeout(() => {
        setShowFinalAnim(true);
        //setCookie(location.pathname, VOTINGCOOKIENAME, VOTINGDURATIONDAYS);
      }, 0);
      // setIsScrollable(true);
    } else {
      setShowFinalAnim(false);
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab < props.questions.length) {
      if (props.questions.length > 0 && props.questions[activeTab].voted) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
  }, [activeTab, props.questions]);

  const handleBeforeChange = (currentSlide: number, nextSlide: number) => {
    setActiveTab(nextSlide);
  };

  const renderTabs = () => {
    if (props.questions.length <= 1) return <div />;
    return props.questions?.map((_q, index) => (
      <div
        key={index}
        className={`tab ${activeTab === index ? "active" : ""}`}
        style={{ width: `${(props.parentWidth || width) / props.questions.length - 5}px` }}
      ></div>
    ));
  };

  const goToSlideOne = () => {
    if (sliderRef.current) {
      sliderRef.current?.slickGoTo(0);
    }
  };
  const goToNextItem = () => {
    if (sliderRef.current) {
      setTimeout(() => {
        sliderRef.current?.slickNext();
      }, 1200);
    }
  };
  const goBack = () => {
    if (sliderRef.current) {
      sliderRef.current?.slickPrev();
    }
  };
  const goNextWithoutDelay = () => {
    if (sliderRef.current) {
      sliderRef.current?.slickNext();
    }
  };
  const PrevArrow = ({ id }: { id: string }) => {
    const isVisible = activeTab !== 0;
    const handleClick = () => {
      if (sliderRef.current && isVisible) {
        sliderRef.current.slickPrev();
      }
    };
    return (
      <button onClick={handleClick} className={`${isVisible && "visible"} arrow-wrapper`} id={id}>
        <div className="arrow"></div>
      </button>
    );
  };

  const NextArrow = ({ id }: { id: string }) => {
    const isVisible = activeTab !== props.questions.length - 1 && activeTab !== props.questions.length && showButton;
    const handleClick = () => {
      if (sliderRef.current && isVisible) {
        sliderRef.current.slickNext();
      }
    };
    return (
      <button onClick={handleClick} className={`${isVisible && "visible"} arrow-wrapper`} id={id}>
        <div className="arrow forward-arrow"></div>
      </button>
    );
  };

  const settings: CarouselSettings = {
    infinite: false,
    speed: activeTab === props.questions.length ? 0 : 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: false,
    draggable: false,
    swipeToSlide: false,
    arrows: false,
    beforeChange: handleBeforeChange,
  };
  return (
    <div
      className={`carousel-container ${props.isFullScreen && "fullscreen"}`}
      style={props.parentWidth ? ({ gridTemplateColumns: `1fr ${props.parentWidth || "0"}px 1fr` } as any) : {}}
    >
      <div className="multi-vote-back-arrow-area">
        <PrevArrow id="prev" />
      </div>
      <div className="multi-vote-carousel-area" ref={containerRef}>
        <div className="tab-wrapper">{renderTabs()}</div>
        {props.isFullScreen && <Header />}
        <div className="sliderWrapper" ref={wrapperRef}>
          <Slider ref={sliderRef} {...settings} className="multi-vote-carousel-area">
            {props.questions.map(q => (
              // voting card is a question
              <VotingCard
                key={q.id}
                question={q}
                goToNextItem={goToNextItem}
                areaWidth={props.parentWidth || width}
                title={props.voting.hashtag}
                voting={props.voting}
                updateQuestions={props.updateQuestions}
                goBack={goBack}
                goNext={goNextWithoutDelay}
                isDev={props.isDev}
                isRandomOrder={props.voting.is_shuffle_enabled}
              />
            ))}
            {props.voting && props.voting.is_thank_you_shown && (
              <FinalSlide
                areaWidth={props.parentWidth}
                showFinalAnim={showFinalAnim}
                goBack={goBack}
                goNext={goNextWithoutDelay}
                goToSlideOne={goToSlideOne}
              />
            )}
          </Slider>
        </div>
      </div>
      <div className="multi-vote-forward-arrow-area">
        <NextArrow id="next" />
      </div>
    </div>
  );
};

export default Carousel;
