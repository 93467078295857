import React from "react";
import "../../../../resources/styles/pages/imprint-privacy.css";
import ImprintPrivacyHeader from "./Imprint-privacy-header/ImprintPrivacyHeader";
import GetInTouchImprintPrivacy from "./getintouch-imprint-privacy/GetInTouchImprintPrivacy";
import Address from "./Address/Address";
import MetaData from "../common/metadata/MetaData";
import { metadataContent } from "../common/metadata/content";
import { useTranslation } from "react-i18next";
import { imprintPrivacyTranslationVariables } from "../../../../helpers/translationVariables";

const ImprintPrivacy = () => {
  const { t } = useTranslation();

  const translations = imprintPrivacyTranslationVariables({ t });

  return (
    <>
      <MetaData
        title={translations.mdText}
        german={metadataContent.metadataGermanContentImprintPrivacy}
        english={metadataContent.metadataEnglishContentImprintPrivacy}
      />
      <div className="imprintPrivacy-comp-container">
        <div className="imprintPrivacy-header-wrapper">
          <ImprintPrivacyHeader />
        </div>
        <div className="address-info-wrapper">
          <p className="header-contact-info">{translations.headerContactInfo}</p>
          <Address
            startUp={"Squib Ltd."}
            place={"c/o Hochschule Luzern - Wirtschaft"}
            desc={"Smart-up"}
            street={"Zentralstrasse 9"}
            zip={"6002 Luzern"}
            country={"Schweiz"}
            dataProtectorOfficer={"Datenschutzbeauftragter"}
            dataProtectorName={"Marco Eichenberger"}
          />
        </div>
        <div className="imprintPrivacy-main-wrapper">
          <div className="privacy-policy-wrapper">
            {/* First block - small Squib intro*/}
            <div className="first-block">
              <p className="text-2">{translations.content1One}</p>
              <p className="text-3">{translations.content1Two}</p>
              <p className="text-4">{translations.content1Three}</p>
            </div>

            {/* Second block - Inhalt */}
            <div className="second-block">
              <h2 className="text-5">{translations.title2}</h2>
              <ul className="text-6">
                {translations.inhaltList.map(item => (
                  <li key={item.id}>{item.title}</li>
                ))}
              </ul>
            </div>

            {/* Third block - Was tun wir? */}
            <div className="third-block">
              <h2 className="text-7">{translations.title3}</h2>
              <p className="text-8">{translations.content3One}</p>
              <p className="text-9">{translations.content3Two}</p>
            </div>

            {/* Fourth block - Über was informieren wir? */}
            <div className="fourth-block">
              <h2 className="text-10">{translations.title4}</h2>
              <ul className="text-11">
                {translations.uberInformierenList.map(item => (
                  <li key={item.id}>{item.title}</li>
                ))}
              </ul>
            </div>

            {/* Fifth block - Begriffsdefinitionen */}
            <div className="fifth-block">
              <h2 className="text-12">{translations.title5}</h2>
              <h3 className="text-13">{translations.subContentTitle5One}</h3>
              <p className="text-14">{translations.content5ParagOne}</p>
              <h3 className="text-15">{translations.subContentTitle5Two}</h3>
              <p className="text-16">{translations.content5ParagTwo}</p>
              <ul className="text-17">
                {translations.content5List.map(item => (
                  <li key={item.id}>{item.content}</li>
                ))}
              </ul>
              <p className="text-18">{translations.content5ParagThree}</p>
              <h3 className="text-19">{translations.subContentTitle5Three}</h3>
              <p className="text-20">{translations.content5ParagFour}</p>
              <h3 className="text-21">{translations.subContentTitle5Four}</h3>
              <p className="text-22">{translations.content5ParagFive}</p>
            </div>

            {/* Sixth block - Kontakt */}
            <div className="sixth-block">
              <h2 className="text-23">{translations.title6}</h2>
              <p className="text-24">
                {translations.content6 ? <p dangerouslySetInnerHTML={{ __html: translations.content6 }}></p> : translations.content6}
              </p>
            </div>

            {/* Seventh block - Datensicherheit */}
            <div className="seventh-block">
              <h2 className="text-25">{translations.title7}</h2>
              <p className="text-26">{translations.content7One}</p>
            </div>

            {/* Eighth block - Betroffenenrechte */}
            <div className="eighth-block">
              <h2 className="text-27">{translations.title8}</h2>
              <h3 className="text-28">{translations.subContentTitle8One}</h3>
              <p className="text-29">
                {translations.paragOne8One ? (
                  <p dangerouslySetInnerHTML={{ __html: translations.paragOne8One }}></p>
                ) : (
                  translations.paragOne8One
                )}
              </p>
              <p className="text-30">{translations.paragOne8Two}</p>
              <p className="text-31">{translations.paragOne8Three}</p>
              <p className="text-32">{translations.paragOne8Four}</p>
              <p className="text-33">{translations.paragOne8Five}</p>

              <h3 className="text-34">{translations.subContentTitle8Two}</h3>
              <p className="text-35">{translations.paragTwo8Six}</p>
              <p className="text-36">{translations.paragTwo8Seven}</p>

              <h3 className="text-37">{translations.subContentTitle8Three}</h3>
              <p className="text-38">{translations.paragTwo8Eght}</p>
              <p className="text-39">{translations.paragTwo8Nine}</p>

              <h3 className="text-40">{translations.subContentTitle8Four}</h3>
              <p className="text-41">{translations.paragTwo8Ten}</p>

              <h3 className="text-42">{translations.subContentTitle8Five}</h3>
              <p className="text-43">{translations.paragTwo8Eleven}</p>
              <p className="text-44">{translations.paragTwo8Twelve}</p>
              <p className="text-45">{translations.paragTwo8Thirteen}</p>

              <h3 className="text-46">{translations.subContentTitle8Six}</h3>
              <p className="text-47">{translations.paragTwo8Fourteen}</p>
            </div>

            {/* Ninth block - Allgemeine Grundsätze */}
            <div className="ninth-block">
              <h2 className="text-48">{translations.title9}</h2>
              <h3 className="text-49">{translations.subContentTitle9One}</h3>
              <p className="text-50">{translations.paragOne9One}</p>
              <ul className="text-51">
                {translations.allgaumeineGrundsatzeList1.map(item => (
                  <li key={item.id}>{item.content}</li>
                ))}
              </ul>
              <p className="text-52">{translations.paragOne9Two}</p>
              <ul className="text-53">
                {translations.allgaumeineGrundsatzeList2.map(item => (
                  <li key={item.id}>{item.content}</li>
                ))}
              </ul>
              <h3 className="text-54">{translations.subContentTitle9Two}</h3>
              <p className="text-55">{translations.paragTwo9Three}</p>
              <p className="text-56">{translations.paragTwo9Four}</p>
              <ul className="text-57">
                {translations.allgaumeineGrundsatzeList3.map(item => (
                  <li key={item.id}>{item.content}</li>
                ))}
              </ul>
              <p className="text-58">{translations.paragTwo9Five}</p>
              <p className="text-59">{translations.paragTwo9Six}</p>
              <h3 className="text-60">{translations.subContentTitle9Three}</h3>
              <div className="paar-a">
                <p className="text-61">{translations.textA}</p>
                <p className="text-61">{translations.paragTwo9Seven}</p>
              </div>
              <ul>
                {translations.allgaumeineGrundsatzeList4.map(item => (
                  <li key={item.id}>{item.content}</li>
                ))}
              </ul>
              <p className="text-62">{translations.paragTwo9Eight}</p>
              <div className="paar-b">
                <p className="text-63">{translations.textB}</p>
                <p className="text-63">{translations.paragTwo9Nine}</p>
              </div>
              <p className="text-64">{translations.paragTwo9Ten}</p>
              <div className="paar-c">
                <p className="text-65">{translations.textC}</p>
                <p className="text-65">{translations.paragTwo9Eleven}</p>
              </div>
              <p className="text-66">{translations.paragTwo9Twelve}</p>
              <h3 className="text-67">{translations.subContentTitle9Four}</h3>
              <p className="text-68">{translations.paragTwo9Thirteen}</p>
              <p className="text-69">{translations.paragTwo9Fourteen}</p>
            </div>

            {/* Tenth block - Einzelne Datenverarbeitungsvorgänge */}
            <div className="tenth-block">
              <h2 className="text-70">{translations.title10}</h2>
              <h3 className="text-71">{translations.subContentTitle10One}</h3>
              <p className="text-72">{translations.paragOne10One}</p>
              <p className="text-73">{translations.question10One}</p>
              <p className="text-74">{translations.paragOne10Two}</p>
              <ul className="text-75">
                {translations.topic8List1.map(item => (
                  <li key={item.id}>{item.content}</li>
                ))}
              </ul>
              <p className="text-76">{translations.paragOne10Three}</p>
              <p className="text-77">{translations.question10Two}</p>
              <p className="text-78">{translations.paragOne10Four}</p>
              <p className="text-79">{translations.question10Three}</p>
              <p className="text-80">{translations.paragOne10Five}</p>
              <p className="text-81">{translations.question10Four}</p>
              <p className="text-82">{translations.paragOne10Six}</p>

              <h3 className="text-83">{translations.subContentTitle10Two}</h3>
              <p className="text-84">{translations.paragOne10Seven}</p>
              <p className="text-85">{translations.question10Five}</p>
              <p className="text-86">{translations.paragOne10Eight}</p>
              <ul className="text-87">
                {translations.topic8List2.map(item => (
                  <li key={item.id}>{item.content}</li>
                ))}
              </ul>
              <p className="text-88">{translations.paragOne10Nine}</p>
              <p className="text-89">{translations.question10Six}</p>
              <p className="text-90">{translations.paragOne10Ten}</p>
              <p className="text-91">{translations.question10Seven}</p>
              <p className="text-92">{translations.paragOne10Eleven}</p>
              <p className="text-93">{translations.question10Eight}</p>
              <p className="text-94">{translations.paragOne10Twelve}</p>
              <p className="text-95">{translations.question10Nine}</p>
              <p className="text-96">{translations.paragOne10Thirteen}</p>

              <h3 className="text-97">{translations.subContentTitle10Three}</h3>
              <p className="text-98">{translations.paragOne10Fourteen}</p>
              <p className="text-99">{translations.question10Ten}</p>
              <p className="text-100">{translations.paragOne10Fifteen}</p>
              <p className="text-101">{translations.question10Eleven}</p>
              <p className="text-102">{translations.paragOne10Sixteen}</p>
              <p className="text-103">{translations.question10Twelve}</p>
              <p className="text-104">{translations.paragOne10Seventeen}</p>
              <p className="text-105">{translations.question10Thirteen}</p>
              <p className="text-106">{translations.paragOne10Eighteen}</p>
              <p className="text-107">{translations.paragOne10Nineteen}</p>
              <div>
                <p className="text-108">{translations.ul10Header}</p>
                <ul className="text-109">
                  {translations.topic8List3.map(item => (
                    <li key={item.id}>{item.content}</li>
                  ))}
                </ul>
              </div>
              <p className="text-110">{translations.paragOne10Twenty}</p>

              <h3 className="text-111">{translations.subContentTitle10Four}</h3>
              <p className="text-112">{translations.paragOne10TwentyOne}</p>
              <p className="text-113">{translations.question10Fourteen}</p>
              <p className="text-114">{translations.paragOne10TwentyTwo}</p>
              <p className="text-115">{translations.question10Fifteen}</p>
              <p className="text-116">{translations.paragOne10TwentyThree}</p>
              <p className="text-117">{translations.question10Sixteen}</p>
              <p className="text-118">{translations.paragOne10TwentyFour}</p>
              <p className="text-119">{translations.question10Seventeen}</p>
              <p className="text-120">{translations.paragOne10TwentyFive}</p>
              <p className="text-121">{translations.question10Eighteen}</p>
              <p className="text-122">{translations.paragOne10TwentySix}</p>

              <h3 className="text-123">{translations.subContentTitle10Five}</h3>
              <p className="text-124">{translations.paragOne10TwentySeven}</p>
              <p className="text-125">{translations.question10Nineteen}</p>
              <p className="text-126">{translations.paragOne10TwentyEight}</p>
              <ul className="text-127">
                {translations.topic8List4.map(item => (
                  <li key={item.id}>{item.content}</li>
                ))}
              </ul>
              <p className="text-128">{translations.paragOne10TwentyNine}</p>
              <p className="text-129">{translations.question10Twenty}</p>
              <p className="text-130">{translations.paragOne10Thirty}</p>
              <p className="text-131">{translations.question10TwentyOne}</p>
              <p className="text-132">{translations.paragOne10ThirtyOne}</p>

              <h3 className="text-133">{translations.subContentTitle10Six}</h3>
              <p className="text-134">{translations.paragOne10ThirtyTwo}</p>
              <p className="text-135">{translations.question10TwentyTwo}</p>
              <p className="text-136">{translations.paragOne10ThirtyThree}</p>
              <ul className="text-137">
                {translations.topic8List5.map(item => (
                  <li key={item.id}>{item.content}</li>
                ))}
              </ul>
              <p className="text-138">{translations.paragOne10ThirtyFour}</p>
              <p className="text-139">{translations.question10TwentyThree}</p>
              <p className="text-140">{translations.paragOne10ThirtyFive}</p>
              <p className="text-141">{translations.question10TwentyFour}</p>
              <p className="text-142">{translations.paragOne10ThirtySix}</p>

              <h3 className="text-143">{translations.subContentTitle10Seven}</h3>
              <p className="text-144">{translations.paragOne10ThirtySeven}</p>
              <p className="text-145">{translations.question10TwentyFive}</p>
              <p className="text-146">{translations.paragOne10ThirtyEight}</p>
            </div>
          </div>
        </div>
        <GetInTouchImprintPrivacy />
      </div>
    </>
  );
};

export default ImprintPrivacy;
