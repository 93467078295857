export const setCookies = (vId: number, qId: number, optionId: number, path: string): void => {
  const date = new Date();
  date.setDate(date.getDate() + 365);
  const ts = date.toUTCString();
  document.cookie = `${vId.toString() + qId.toString()}=${optionId}; Expires=${ts}; Secure; Path=${path}`;
};

export const readCookies = (vId: number, qId: number, cb?: (b: string) => void): boolean => {
  const cookie = getCookie(vId.toString() + qId.toString());
  const result = cookie === "";
  if (cb) cb(cookie);
  return !result;
};

export const readCookieContent = (vId: number, qId: number): number => {
  const cookie = getCookie(vId.toString() + qId.toString());
  return parseInt(cookie);
};

const getCookie = (cname: string): string => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
