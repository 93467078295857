import React from "react";
import "../../../../../../resources/styles/components/comparison-components/functions-model-table.css";
import excl from "../../../../../../resources/images/excl.png";
import check from "../../../../../../resources/images/check.png";

const PricingModelTable = () => {
  return (
    <div className="functions-model-table-container">
      <div className="table-wrapper">
        <div className="first-col">
          <div className="row first-row">
            <div className="option-title"></div>
            <div className="app-versions">
              <div className="lite">
                <span>
                  <div className="opt-versions-wrapper">
                    <strong>Squib</strong>
                  </div>
                </span>
              </div>
              <div className="pro">
                <span>
                  <strong>Typeform</strong>
                </span>
              </div>
              <div className="pro-plus">
                <span>
                  <strong>Google Forms</strong>
                </span>
              </div>
            </div>
          </div>

          <div className="row second-row">
            <div className="option-title">
              <p>Registration required</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row third-row">
            <div className="option-title">
              <p>Create simple votings™</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
              <div className="check-two">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row fourth-row">
            <div className="option-title">
              <p>View number of participants</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row fifth-row">
            <div className="option-title">
              <p>Participation only possible when logge in</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row sixth-row">
            <div className="option-title">
              <p>Public stream</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row seventh-row">
            <div className="option-title">
              <p>Detailed analisis</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span></span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row eighth-row">
            <div className="option-title">
              <p>Multi Votings (with follow-up questions)</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span></span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row ninth-row">
            <div className="option-title">
              <p>Create votings with Custom Answer option</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span>{""}</span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row tenth-row">
            <div className="option-title">
              <p>Shuffle Voting Cards randomly</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span>{""}</span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row eleventh-row">
            <div className="option-title">
              <p>Hide voting results</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span>{""}</span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row twelfth-row">
            <div className="option-title">
              <p>Protect voting by passcode</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span>{""}</span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row thirteenth-row">
            <div className="option-title">
              <p>Dynamic votings with OR code</p>
              <img src={excl} alt="excl" width={32} />
            </div>
            <div className="app-versions">
              <div className="check-one">
                <span>{""}</span>
              </div>
              <div className="check-two">
                <span>{""}</span>
              </div>
              <div className="check-three">
                <span>
                  <img src={check} alt="check" width={48} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModelTable;
