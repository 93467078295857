import React from "react";
import TestimonialsSuccessStoriesLayout from "../../../common/testimonials-layout-comp/TestimonialsLayout";
import images from "../../../../../../resources/images";

const TestimonialThree = () => {
  return (
    <TestimonialsSuccessStoriesLayout
      brandLogo={images.twiny}
      description="«Von unserer Kundschaft wollten wir wissen, ob sie ein grösseres veganes Angebot wünschen. Während der Zubereitungszeit der Speisen, konnten unsere Gäste die Frage mittels QR-Code direkt und ganz einfach auf ihrem Smartphone beantworten.»"
      avatar={images.nadia}
      authorName="Nadia"
      authorTitle="Geschäftsführerin"
    />
  );
};

export default TestimonialThree;
