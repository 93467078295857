import React from "react";
import GetIntouch from "../../common/get-in-touch/GetInTouch";

const getIntouchPricingFeatures = [
  {
    title: "Noch Fragen oder Unklarheiten?",
    callToAction: "Jetzt Kontakt aufnehmen!",
  },
];

const GetInTouchPricingFeatures = () => {
  return (
    <div className="get-in-touch-container">
      <GetIntouch title={getIntouchPricingFeatures[0].title} callToAction={getIntouchPricingFeatures[0].callToAction} />
    </div>
  );
};

export default GetInTouchPricingFeatures;
