import React from "react";
import { NavMenuOptions } from "../../../../../helpers/types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Nav: NavMenuOptions[] = [
  {
    blog: "Blog",
    explore: "Explore",
    pricingFeatures: "Pricing & Features",
    useCases: "Use Cases",
    successStories: "Success Stories",
    contact: "Contact",
  },
];
const NavMenu = () => {
  const { t } = useTranslation("translation");
  const explore = t("explore", { ns: "translation" });
  const useCases = t("useCases", { ns: "translation" });
  const successStories = t("successStories", { ns: "translation" });
  const pricingFeatures = t("pricingFeatures", { ns: "translation" });
  const contact = t("contact", { ns: "translation" });

  const navTranslation = Nav.map(nav => {
    nav.explore = explore;
    nav.useCases = useCases;
    nav.successStories = successStories;
    nav.pricingFeatures = pricingFeatures;
    nav.contact = contact;

    return nav;
  });

  return (
    <>
      <ul className="nav-menu">
        <li>
          <Link to="/erkunden">{navTranslation[0].explore}</Link>
        </li>
        <li>
          <Link to="/anwendungsbeispiele">{navTranslation[0].useCases}</Link>
        </li>
        {/*<li>
          <Link to="/erfolgsgeschichten">{navTranslation[0].successStories}</Link>
        </li>
        <li>
          <Link to="/preise-funktionen">{navTranslation[0].pricingFeatures}</Link>
  </li>*/}
        <li>
          <Link to="/kontakt">{navTranslation[0].contact}</Link>
        </li>
      </ul>
    </>
  );
};

export default NavMenu;
