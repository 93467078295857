import React from "react";
import TableFunctions from "./table-functions/TableFunctions";

const IndividualFunctions = () => {
  return (
    <div style={{ marginTop: "12rem" }}>
      <h1
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
          fontFamily: "PublicaSans-Bold",
          width: "30rem",
          margin: "0 auto",
        }}
      >
        Vergleich der einzelnen Funktionen
      </h1>
      <TableFunctions />
    </div>
  );
};

export default IndividualFunctions;
