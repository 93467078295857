import React, { useEffect, useState } from "react";
import "../assets/voting.css";

type voteHederData = {
  hashtag: string;
  question: string;
  width: number;
};

const VoteCardHeader = ({ hashtag, question, width }: voteHederData) => {
  const [fontSize, setFontSize] = useState<number>(20);

  useEffect(() => {
    setFontSize(width / 17.5);
  }, [width]);

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: `${3 * 1.2 * fontSize + fontSize * 1.2}px` }}>
      {hashtag !== "" && (
        <span className="hashtag" style={{ fontSize: `${fontSize}px`, marginTop: "10px", marginBottom: "5px" }}>
          {hashtag}
        </span>
      )}
      <div className="votingQuestionConatiner" style={{ height: `${3 * 1.2 * fontSize}px`, padding: `0 ${width / 45.6}px` }}>
        <h2 className="question" style={{ fontSize: `${fontSize}px` }}>
          {question}
        </h2>
      </div>
    </div>
  );
};

export default VoteCardHeader;
