import React from "react";

interface AppLayoutProps {
  title: string;
  description: string;
}

const AppLayout = (props: AppLayoutProps) => {
  return (
    <div className="app-layout-container">
      <div className="app-text-wrapper">
        <h2>{props.title}</h2>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default AppLayout;
