import React from "react";
import MetaData from "../common/metadata/MetaData";
import ReleaseHeader from "./release-header/ReleaseHeader";
import ReleasesFeatures from "./releases-features/ReleasesFeatures";
import GetInTouchRealeases from "./getintouch-releases/GetInTouchRealeases";
import "../../../../resources/styles/components/releases-components/releases.css";

const Releases = () => {
  return (
    <>
      <MetaData title="Releases" />
      <ReleaseHeader />
      <ReleasesFeatures />
      <div style={{ marginTop: "10rem" }}>
        <GetInTouchRealeases />
      </div>
    </>
  );
};

export default Releases;
