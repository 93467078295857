import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import images from "../../../../../resources/images";
import "../../../../../resources/styles/components/functionalities-components/faq.css";

const FAQ = () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number | null>(null);
  const contentRef = useRef<HTMLDivElement[]>([]);

  const { t } = useTranslation("translation");
  const faqTitle = t("faqTitle", { ns: "translation" });
  const faqQuestionOne = t("faqQuestionOne", { ns: "translation" });
  const faqAnswerOne = t("faqAnswerOne", { ns: "translation" });
  const faqQuestionTwo = t("faqQuestionTwo", { ns: "translation" });
  const faqAnswerTwo = t("faqAnswerTwo", { ns: "translation" });
  const faqQuestionThree = t("faqQuestionThree", { ns: "translation" });
  const faqAnswerThree = t("faqAnswerThree", { ns: "translation" });
  const faqQuestionFour = t("faqQuestionFour", { ns: "translation" });
  const faqAnswerFour = t("faqAnswerFour", { ns: "translation" });
  const faqQuestionFive = t("faqQuestionFive", { ns: "translation" });
  const faqAnswerFive = t("faqAnswerFive", { ns: "translation" });
  const faqQuestionSix = t("faqQuestionSix", { ns: "translation" });
  const faqAnswerSix = t("faqAnswerSix", { ns: "translation" });
  const faqQuestionSeven = t("faqQuestionSeven", { ns: "translation" });
  const faqAnswerSeven = t("faqAnswerSeven", { ns: "translation" });
  const faqQuestionEight = t("faqQuestionEight", { ns: "translation" });
  const faqAnswerEight = t("faqAnswerEight", { ns: "translation" });
  //const faqQuestionNine = t("faqQuestionNine", { ns: "translation" });
  //const faqAnswerNine = t("faqAnswerNine", { ns: "translation" });

  const faqArray = [
    {
      question: faqQuestionOne,
      answer: faqAnswerOne,
      icon: images.iconDark,
      isHtml: false,
    },
    {
      question: faqQuestionTwo,
      answer: faqAnswerTwo,
      icon: images.iconDark,
      isHtml: false,
    },
    {
      question: faqQuestionThree,
      answer: faqAnswerThree,
      icon: images.iconDark,
      isHtml: false,
    },
    {
      question: faqQuestionFour,
      answer: faqAnswerFour,
      icon: images.iconDark,
      isHtml: false,
    },
    {
      question: faqQuestionFive,
      answer: faqAnswerFive,
      icon: images.iconDark,
      isHtml: false,
    },
    {
      question: faqQuestionSix,
      answer: faqAnswerSix,
      icon: images.iconDark,
      isHtml: false,
    },
    {
      question: faqQuestionSeven,
      answer: faqAnswerSeven,
      icon: images.iconDark,
      isHtml: true,
    },
    {
      question: faqQuestionEight,
      answer: faqAnswerEight,
      icon: images.iconDark,
      isHtml: true,
    },
    //{
    //  question: faqQuestionNine,
    //  answer: faqAnswerNine,
    //  icon: images.iconDark,
    //  isHtml: false,
    //},
  ];

  const handleArrowClick = (index: number) => () => {
    setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-wrapper">
        <h2 className="faq-title">{faqTitle}</h2>
        <div className="questions-cont">
          <div className="border-top"></div>
          {faqArray.map((faq, index) => {
            return (
              <div key={index} className="questions-wrapper">
                <div className="questions-icon-wrapper">
                  <div className="faq-question">
                    <h3>{faq.question}</h3>
                  </div>
                  <img
                    className={activeQuestionIndex === index ? "arrow-up" : "arrow-down"}
                    onClick={handleArrowClick(index)}
                    src={faq.icon}
                    alt="icon"
                  />
                </div>
                <div
                  ref={el => (contentRef.current[index] = el as HTMLDivElement)}
                  className={`faq-answer`}
                  style={{
                    maxHeight: activeQuestionIndex === index ? `${contentRef.current[index]?.scrollHeight}px` : "0px",
                    transition: "max-height 0.5s ease",
                    overflow: "hidden",
                  }}
                >
                  {faq.isHtml ? <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p> : <p>{faq.answer}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
