import React from "react";
import AppVersionsComp from "./app-versions-comp/AppVersionsComp";
import "../../../../../resources/styles/components/home-components/app-versions-home.css";
import Reveal from "../../common/reveal-animation/Reveal";
import { useTranslation } from "react-i18next";

const AppVersions = () => {
  const { t } = useTranslation("translation");
  const titleVersions = t("versionsTitle", { ns: "translation" });
  const descVersions = t("versionsDescHome", { ns: "translation" });

  return (
    <div id="user-waiting-list">
      <div className="waitinglist-home-container">
        <div className="waitinglist-home-wrapper">
          <Reveal>
            <AppVersionsComp title={titleVersions} description={descVersions} />
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default AppVersions;
