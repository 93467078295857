import React from "react";
import PricingFeaturesPlanLayout from "../layout/PricingFeaturesPlanLayout";

const PlanTwo = () => {
  return (
    <>
      <PricingFeaturesPlanLayout
        headerPlanVersions={["Pro"]}
        headerPlanPrice={["$49"]}
        planOptions={[
          "All in Lite",
          "Unlimited Votings",
          "Multi Votings",
          "Custom Answers ",
          "Hide votings results",
          "Votings archive\n(2 months)",
          "Protect by passcode",
          "Own Logo upload",
          "Detailed analysis",
        ]}
        btnText="sign-up now"
        posibleAccess="Dashboard access"
      />
    </>
  );
};

export default PlanTwo;
