import React from "react";

interface IntegrationLayoutProps {
  title: string;
  description: string;
}

const IntegrationLayout = (props: IntegrationLayoutProps) => {
  return (
    <div className="integration-common-layout__container">
      <div className="integration-common-layout__box"></div>
      <div className="integration-layout__header">
        <h2 className="integration-layout__header-title">{props.title}</h2>
        <p className="integration-layout__header-description">{props.description}</p>
      </div>
    </div>
  );
};

export default IntegrationLayout;
