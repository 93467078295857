import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavMenu from "./nav-menu/NavMenu";
import CustomButtonTwo from "../../CustomButtonTwo";
import "../../../../resources/styles/components/nav-header.css";
import { useTranslation } from "react-i18next";
import images from "../../../../resources/images";
import { handleBtnScrollVoting } from "../home/Home";

const NavHeader = () => {
  const [shouldScroll, setShouldScroll] = useState(false);
  const { t } = useTranslation("translation");
  const waitingListBtnText = t("waitingListBtnText", { ns: "translation" });
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setShouldScroll(true);
    navigate("/");
  };

  useEffect(() => {
    if (shouldScroll) {
      handleBtnScrollVoting();
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  return (
    <div className="nav-container">
      <div className="nav-wrapper">
        <Link to="/" className="squib-logo-wrapper">
          <img src={images.squibLogoBlack} className="logo" alt="logo" />
        </Link>

        <NavMenu />
        <div onClick={handleButtonClick} className="create-voting-wrapper">
          <CustomButtonTwo btnText={waitingListBtnText} className="btn-cb" />
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
