import React, { TouchEvent, useEffect, useState } from "react";
import "../assets/voting.css";
import Card from "./Card";
import { Voting, Question, ExtendedQuestion } from "../services/types";
import { sendResult } from "../services/voteService";
import VoteCardHeader from "./voteCardHeader";
import { nanoid } from "nanoid";
import { setCookies } from "../services/cookies";

// import VotingFooter from "./VotingFooter";
// import Header from "./Header";

type VotingType = {
  question: ExtendedQuestion;
  goToNextItem: () => void;
  areaWidth: number;
  title: string;
  voting: Voting;
  updateQuestions: (q: ExtendedQuestion) => void;
  goBack: () => void;
  goNext: () => void;
  isDev?: boolean;
  isRandomOrder?: boolean;
};

const VotingCard = (props: VotingType) => {
  const [showResult, setShowResult] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [doesQuestionHaveCustomAnswer, setDoesQuestionHaveCustomAnswer] = useState<boolean>(false);
  const [startPosition, setStartPosition] = useState<number | null>(null);
  const [endPosition, setEndPosition] = useState<number | null>(null);
  const [rowDirection, setRowDirection] = useState<"row" | "row-reverse">("row");
  const [participantId, setParticipantID] = useState<string>();
  const [timeStarted, setTimeStarted] = useState<Date>();

  useEffect(() => {
    if (props.isRandomOrder && Math.random() >= 0.5) {
      setRowDirection("row-reverse");
    } else {
      setRowDirection("row");
    }
    if (!participantId) {
      setParticipantID(nanoid());
    }
    setTimeStarted(new Date());
  }, [props.isRandomOrder, participantId]);

  useEffect(() => {
    setShowResult(props.question.voted);
  }, [props.question]);

  const minSwipeDistance = 50;
  const onDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
    setEndPosition(null);
    setStartPosition(e.clientX);
  };
  let isLeftDrag: boolean;
  let isRightDrag: boolean;
  const onDragMove = (e: React.MouseEvent<HTMLDivElement>) => {
    setEndPosition(e.clientX);
    if (!startPosition || !endPosition) return;
    const distance = startPosition - endPosition;
    isLeftDrag = distance > 10;
    isRightDrag = distance < -10;
  };
  const onDragEnd = () => {
    if (isLeftDrag && props.question.voted) {
      props.goNext();
    }
    if (isRightDrag) {
      props.goBack();
    }
  };
  const onTouchStart = (e: TouchEvent) => {
    setEndPosition(null);
    setStartPosition(e.targetTouches[0].clientX);
  };
  const onTouchMove = (e: TouchEvent) => {
    setEndPosition(e.targetTouches[0].clientX);
    if (!startPosition || !endPosition) return;
    const distance = startPosition - endPosition;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe && props.question.voted) {
      props.goNext();
    }
    if (isRightSwipe) {
      props.goBack();
    }
  };

  const onVote = (optionId: number, otherId: number, questionId: number) => {
    const questionOptionOfCustomAnswer = props.question.question_options.find(option => option.is_custom_answer === true);

    const isCustomAnswerOptionChoice = optionId === questionOptionOfCustomAnswer?.id;
    setDisabled(!disabled);

    const customAnswer = isCustomAnswerOptionChoice ? userInput : undefined;
    props.updateQuestions({ ...props.question });
    setShowResult(true);
    const timeSpentOnVote = new Date().getTime() - (timeStarted?.getTime() || 0);
    if (!props.isDev) {
      sendResult(optionId, otherId, participantId || "", timeSpentOnVote, props.voting, customAnswer);
      setCookies(props.voting.id, questionId, optionId, window.location.pathname);
    }
    props.goToNextItem();
    setTimeStarted(new Date());
  };

  // check if this question has custom answer option
  const doesQuestionHaveCustomAnswerOption = (question: Question): boolean => {
    return question.question_options.some(option => option.is_custom_answer === true);
  };

  useEffect(() => {
    if (props.question && props.question.question_options) {
      setDoesQuestionHaveCustomAnswer(doesQuestionHaveCustomAnswerOption(props.question));
    }
  }, [props.question]);

  return (
    <div
      className="slide-wraper"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onMouseDown={onDragStart}
      onMouseMove={onDragMove}
      onMouseUp={onDragEnd}
    >
      {/* <Header /> */}
      <div className="voting-container">
        <VoteCardHeader hashtag={props.voting.hashtag} question={props.question.body} width={props.areaWidth} />
        <div className="custom-vote-wrapper" style={{ flexDirection: rowDirection }}>
          {props.question.question_options.map((option, index) => (
            <Card
              key={index}
              index={index}
              classN={`index-${index}`}
              option={option}
              optionsIds={props.question.question_options.map(option => option.id)}
              hideImg={option.is_custom_answer ? true : false}
              onVote={onVote}
              // showThumpsUp={true}
              showResult={showResult}
              resultHidden={props.voting.is_result_hidden}
              image={option.image}
              backgroundColour={option.background_colour}
              fontColour={option.font_colour}
              sibling={doesQuestionHaveCustomAnswer}
              question={props.question}
              isDisabled={disabled}
              onUpdateUserInput={setUserInput}
            />
          ))}
        </div>
      </div>
      {/* <VotingFooter /> */}
    </div>
  );
};

export default VotingCard;
