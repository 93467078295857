import React from "react";
import PricingFeaturesPlanLayout from "../layout/PricingFeaturesPlanLayout";

const PlanThree = () => {
  return (
    <>
      <PricingFeaturesPlanLayout
        headerPlanVersions={["Pro+"]}
        headerPlanPrice={["$69"]}
        planOptions={["All in Pro", "Dynamic Votings", "Votings archive(unlimited)", "Priority support(tbd)", "(Multiple Accounts)"]}
        btnText="sign-up now"
        posibleAccess="Dashboard access"
      />
    </>
  );
};

export default PlanThree;
