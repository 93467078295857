import React from "react";
import GetIntouch from "../../common/get-in-touch/GetInTouch";
import "../../../../../resources/styles/components/useCases-components/getInTouch-use-cases.css";
import { useTranslation } from "react-i18next";

const GetInTouchTermsOfUse = () => {
  const { t } = useTranslation("translation");
  const question = t("getInTouchUseCasesHeader", { ns: "translation" });
  const callToActBtn = t("getInTouchUseCasesBtn", { ns: "translation" });

  return (
    <div className="getintouch-use-cases-container">
      <div className="getintouch-use-cases-wrapper">
        <GetIntouch headerOwnStyle={"use-cases-header-style"} title={question} callToAction={callToActBtn} />
      </div>
    </div>
  );
};

export default GetInTouchTermsOfUse;
