import React from "react";
import SecurityOne from "./security-one/SecurityOne";
import SecurityTwo from "./security-two/SecurityTwo";
import SecurityThree from "./security-three/SecurityThree";

const SecurityOptions = () => {
  return (
    <div className="security-option-container">
      <SecurityOne />
      <SecurityTwo />
      <SecurityThree />
    </div>
  );
};

export default SecurityOptions;
