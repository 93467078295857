import React, { useEffect } from "react";
import "../assets/header.css";
import logo from "../assets/images/startupNight-logo.png";

const Header = (props: { isShown?: boolean }) => {
  useEffect(() => console.log(props.isShown), []);

  return (
    <div className={`header-wraper ${!props.isShown && "isAbsolute"}`}>
      {props.isShown && <img src={logo} alt="company-logo" className="company-logo" />}
      {/* <div className="buttons">
        <a href="https://squib.app" target="_blank" rel="noreferrer" className="squib-button">
          <button className="secondarySquibButton">Registrieren</button>
        </a>
        <a className="squib-button">
          <button>Login</button>
        </a>
      </div> */}
    </div>
  );
};

export default Header;
