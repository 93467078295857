import React from "react";
import { Link } from "react-router-dom";
import "../../../../../resources/styles/components/home-components/group-one.css";
import CustomButtonTwo from "../../../CustomButtonTwo";
import { resources } from "../../../../../i18n";
import { useTranslation } from "react-i18next";

interface IGroupOneProps {
  title: string | undefined;
  description: string | undefined;
  title2: string | undefined;
  description2: string | undefined;
}

const GroupOne = (props: IGroupOneProps) => {
  const { t } = useTranslation("translation");
  const btnGroupOne = t("btnGroupOne1", { ns: "translation" });
  const btnGroupTwo = t("btnGroupOne2", { ns: "translation" });

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="group-one-container">
      <div className="groups-wrapper">
        <div className="group-item-one">
          <div className="group-item-one-wrapper">
            <div className="content-group-one">
              <div className="group-one-header">
                <p>{props.title}</p>
                <p>{props.description}</p>
              </div>
              <div className="btn-cb-wrapper">
                <Link to="/erkunden" onClick={handleScrollToTop}>
                  <CustomButtonTwo btnText={btnGroupOne} className="btn-cb mobile-inactive" />
                </Link>
              </div>
            </div>
            <div className="img-group-one-wrapper">
              <img src="/images/char-squib1.png" alt="char-01" width={230} />
            </div>
          </div>
        </div>
        <div className="group-item-two">
          <div className="group-item-two-wrapper">
            <div className={`img-group-two-wrapper ${resources.en.translation && "image-up-two"}`}>
              <img src="/images/char-squib2.png" alt="char-02" width={250} />
            </div>
            <div className="content-group-two">
              <p>{props.title2}</p>
              <p>{props.description2}</p>
              <div className="btn-cb-wrapper">
                <Link to="/anwendungsbeispiele" onClick={handleScrollToTop}>
                  <CustomButtonTwo btnText={btnGroupTwo} className="btn-cb" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupOne;
