import React from "react";
import "../../../../resources/styles/pages/use-cases.css";
import CustomersFeedback from "./Customers-feedback/CustomersFeedback";
import UseCasesHeader from "./use-cases-header/UseCasesHeader";
import SurveyEmployees from "./survey-employees/SurveyEmployees";
import CompetitiveFeedback from "./competitive-feedback/CompetitiveFeedback";
import VotingEducationPolitics from "./voting-education-politics/VotingEducationPolitics";
import GetInTouchUseCases from "./getintouch-use-cases/GetInTouchUseCases";
import { useTranslation } from "react-i18next";
import MetaData from "../common/metadata/MetaData";
import Reveal from "../common/reveal-animation/Reveal";
import images from "../../../../resources/images";
import { metadataContent } from "../common/metadata/content";

const UseCases = () => {
  const { t } = useTranslation("translation");
  const useCasesTitleOne = t("useCasesTitleOne", { ns: "translation" });
  const useCasesTitleTwo = t("useCasesTitleTwo", { ns: "translation" });
  const useCasesTitleThree = t("useCasesTitleThree", { ns: "translation" });
  const useCasesTitleFour = t("useCasesTitleFour", { ns: "translation" });

  const useCasesDescOne = t("useCasesDescOne", { ns: "translation" });
  const useCasesDescTwo = t("useCasesDescTwo", { ns: "translation" });
  const useCasesDescThree = t("useCasesDescThree", { ns: "translation" });
  const useCasesDescFour = t("useCasesDescFour", { ns: "translation" });

  const brandHeader = t("brandHeader", { ns: "translation" });
  const mdText = t("useCases", { ns: "translation" });

  return (
    <div id="top">
      <MetaData
        title={mdText}
        german={metadataContent.metadataGermanContentUseCases}
        english={metadataContent.metadataEnglishContentUseCases}
      />
      <div className="use-cases-container">
        <UseCasesHeader />
        <CustomersFeedback title={useCasesTitleOne} subTitle={useCasesDescOne} brandHeader={brandHeader} brandImage={images.benedict} />
        <Reveal>
          <SurveyEmployees title={useCasesTitleTwo} subTitle={useCasesDescTwo} />
        </Reveal>

        <Reveal>
          <CompetitiveFeedback
            title={useCasesTitleThree}
            subTitle={useCasesDescThree}
            brandHeader={brandHeader}
            brandImage={images.startUpNights}
          />
        </Reveal>

        <Reveal>
          <VotingEducationPolitics
            title={useCasesTitleFour}
            subTitle={useCasesDescFour}
            brandHeader={brandHeader}
            brandImage={images.skinHarmonyPng}
          />
        </Reveal>

        <Reveal>
          <GetInTouchUseCases />
        </Reveal>
      </div>
    </div>
  );
};

export default UseCases;
