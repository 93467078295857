import React, { useState } from "react";
import "../../../../../../resources/styles/components/home-components/app-versions.css";
import "../../../../../../resources/styles/components/button.css";
import { useTranslation } from "react-i18next";
import CustomButtonThree from "../../../../CustomButtonThree";
import CommonButton from "../../../common/CommonButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import images from "../../../../../../resources/images";

interface IAppVersionsCompProps {
  title: string;
  description: string;
}

const style = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "23rem", sm: "40rem", md: "40rem", lg: "40rem", xl: "45rem" },
  height: 305,
  bgcolor: "#fff",
  borderRadius: "1.8rem",
  boxShadow: 24,
  p: 4,
};

const AppVersionsComp = (props: IAppVersionsCompProps) => {
  const { t } = useTranslation("translation");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const inputWaitinglist = t("inputWaitingList", { ns: "translation" });
  const btnWaitinglist = t("btnWaitingList", { ns: "translation" });
  const waitingListConfirm = t("waitingListConfirm", { ns: "translation" });
  const waitingListConfirmSubText = t("waitingListConfirmSubText", { ns: "translation" });
  const errorWaitingListMessage = t("errorWaitingListMessage", { ns: "translation" });
  const [open, setOpen] = React.useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    const isValidEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}(\.[a-z]+)?$/.test(e.target.value);
    setEmailError(buttonClicked && !isValidEmail ? "Bitte eine korrekte E-Mail-Adresse eingeben" : "");

    if (!value.trim()) {
      setEmailError("");
      return;
    }

    if (!isValidEmail) {
      setEmailError(errorWaitingListMessage);
    } else {
      setEmailError("");
    }
  };

  const handleSubscription = (e: React.FormEvent<HTMLFormElement>) => {
    setButtonClicked(true);

    if (!email.trim()) {
      setEmailError("");
      setEmailError(errorWaitingListMessage);
      setEmail("");
      e.preventDefault();
    } else if (!emailError) {
      setOpen(true);
      setEmail("");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="waitinglist-comp-container">
      <div className="waitinglist-comp-wrapper">
        <h2>{props.title}</h2>
        <p className="waitinglist-desc">{props.description}</p>
        {/* <form
          onSubmit={handleSubscription}
          action="https://app.us20.list-manage.com/subscribe/post"
          method="POST"
          target="_blank"
          className="waintinglist-subscribe"
        > */}
        <div className="waitinglist-wrapper">
          <div className="waitinglist-content-wrapper">
            <a
              href="https://web.swissnewsletter.ch/e/abaf877307b76a46/de/form/a54eeb37-e7f3-4f8f-9dd0-a55b6625d660.html"
              target="_blank"
              rel="noreferrer"
            >
              <CustomButtonThree type="button" btnText={btnWaitinglist} className="waitinglist-btn" />
            </a>
            {buttonClicked && emailError && <span className="error-message">{emailError}</span>}
          </div>
        </div>
        {/* </form> */}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="msg-modal-waitinglist-wrapper"
      >
        <Box sx={style}>
          <>
            <div className="modal-msg-waitinglist-wrapper">
              <h1>{waitingListConfirm}</h1>
              <p>{waitingListConfirmSubText}</p>
              <div className="btn-form-modal-waitinglist-wrapper">
                <CommonButton onClick={handleClose} textOne={"Okay"} className="btn-form-waitinglist-modal" />
              </div>
              <div className="close-icon-waitinglist-wrapper" onClick={handleClose}>
                <img src={images.closeIcon} alt="contact us" />
              </div>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default AppVersionsComp;
