import React from "react";
import AppHeader from "./app-header/AppHeader";
import GetInTouchApp from "./getintouch-app/GetInTouchApp";
import MetaData from "../common/metadata/MetaData";
import VotingOptions from "./voting-options/VotingOptions";
import "../../../../resources/styles/components/squib-app-components/squib-app.css";

const SquibApp = () => {
  return (
    <>
      <MetaData title="App" />
      <AppHeader />
      <VotingOptions />
      <GetInTouchApp />
    </>
  );
};

export default SquibApp;
