import React from "react";
import "../../../../../resources/styles/components/functionalities-components/functionalities-header-text.css";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";
import { useTranslation } from "react-i18next";

const FuntionalitiesHeaderText = () => {
  const { t } = useTranslation("translation");
  const exploreHeaderTitle = t("exploreHeaderTitle", { ns: "translation" });
  const exploreHeaderDesc = t("exploreHeaderDesc", { ns: "translation" });

  return (
    <div className="funtionalities-header-container">
      <div className="funtionalities-header-wrapper">
        <HeaderTitleComp title={exploreHeaderTitle} subTitle={exploreHeaderDesc} />
      </div>
    </div>
  );
};

export default FuntionalitiesHeaderText;
