import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import MultiVoting from "../squip-lite/components/MultiVoting";
import { init } from "../squip-lite/services/voteService";
import { Voting } from "../squip-lite/services/types";

function DynamicVoting() {
  const [voting, setVoting] = useState<Voting>();
  const [redirect, setRedirect] = useState<boolean>(false);

  const { votingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (votingId && votingId.length === 10) {
      const votingData = init(votingId);
      votingData.then(data => {
        if (data.voting && data.voting.questions) {
          const isInActive = new Date(data.voting.expiry_date).getTime() < new Date().getTime();
          if (isInActive) navigate("/voting-closed");
          setVoting(data.voting);
        } else {
          setRedirect(true);
        }
      });
    } else {
      setRedirect(true);
    }
  }, []);

  if (redirect) {
    return <Navigate to="/voting-deleted" />;
  }

  return (
    <div className="outerwrapper" style={{ display: "flex", justifyContent: "center" }}>
      {voting && <MultiVoting voting={voting} isFullScreen />}
    </div>
  );
}

export default DynamicVoting;
