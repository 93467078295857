import React from "react";
import SuccessStoriesHeaderTitle from "./success-stories-header-title/SuccessStoriesHeaderTitle";
import StartupNightsVideo from "./startup-nights-video/StartupNightsVideo";
import GetInTouchSuccessStories from "./getintouch-success-stories/GetInTouchSuccessStories";
import Testimonials from "./testimonials/Testimonials";
import "../../../../resources/styles/pages/success-stories.css";
import { useTranslation } from "react-i18next";
import MetaData from "../common/metadata/MetaData";

const SuccessStories = () => {
  const { t } = useTranslation("translation");
  const mdText = t("successStories", { ns: "translation" });

  return (
    <>
      <MetaData title={mdText} />
      <div className="success-stories-comp-container">
        <SuccessStoriesHeaderTitle />
        <StartupNightsVideo />
        <Testimonials />
        <div style={{ marginTop: "5rem" }}>
          <GetInTouchSuccessStories />
        </div>
      </div>
    </>
  );
};

export default SuccessStories;
