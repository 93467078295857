import { GetVotingResponse, VotingAnswersResponse, Voting } from "./types";
import { getVoting, createVotingAnswers, createClientWithOptions } from "./index";

export const init = async (id: string): Promise<GetVotingResponse> => {
  createClientWithOptions({
    storage: localStorage as unknown,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  });
  const getVotingData = await getVoting(id);
  return getVotingData;
};

// send vote for backend
export const sendResult = async (
  selectedOption: number | undefined,
  other_option_ID: number,
  participant_id: string,
  timeSpent: number,
  vote: Voting | undefined,
  userData?: string,
): Promise<VotingAnswersResponse[]> => {
  if (vote) {
    const votingAnswerData = {
      votingAnswer: {
        used_platform: navigator.userAgent.startsWith("Win") ? "Win" : "mac",
        seconds_spent_to_vote: timeSpent,
        voting_id: vote.id,
        question_option_id: selectedOption,
        custom_answer_text: userData,
        user_id: undefined,
        participant_id: participant_id,
      },
      other_question_option_id: other_option_ID,
    };

    const res = await createVotingAnswers([votingAnswerData]);

    if (res != null && Array.isArray(res)) {
      const updatedVote = { ...vote };

      updatedVote.questions.forEach(question => {
        question.question_options.forEach(option => {
          const matchingRes = res.find((item: { question_option_id: number }) => item.question_option_id === option.id);
          if (matchingRes) {
            option.total_votes_percentage = matchingRes.percentage;
          }
          if (option.is_custom_answer) {
            option.user_custom_answer_text = userData;
          }
        });
      });
    }
  } else {
    return [];
  }

  return [];
};
