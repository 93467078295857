import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../../resources/styles/components/nav-header-mobile.css";
import Policy from "../footer/policy/Policy";
import { useTranslation } from "react-i18next";
import images from "../../../../resources/images";
import CustomButtonTwo from "../../CustomButtonTwo";
import { handleBtnScrollVoting } from "../home/Home";
import "../../../../resources/styles/components/button.css";

const NavHeaderMobile = () => {
  const [click, setClick] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [isOpenProduct, setIsOpenProduct] = useState(false);
  const [isOpenDiscover, setIsOpenDiscover] = useState(false);
  const [isOpenResources, setIsOpenResources] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (click && window.innerWidth <= 1285) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [click]);

  const handleDropdownProduct = (): void => {
    setIsOpenProduct(prevState => !prevState);
    setIsOpenDiscover(false);
    setIsOpenResources(false);
  };

  const handleDropdownDiscover = (): void => {
    setIsOpenProduct(false);
    setIsOpenDiscover(prevState => !prevState);
    setIsOpenResources(false);
  };

  const handleDropdownResources = (): void => {
    setIsOpenProduct(false);
    setIsOpenDiscover(false);
    setIsOpenResources(prevState => !prevState);
  };

  const { t } = useTranslation("translation");
  const menuOne = t("mobileMenuOne", { ns: "translation" });
  const menuTwo = t("mobileMenuTwo", { ns: "translation" });
  const menuThree = t("mobileMenuThree", { ns: "translation" });
  const mobileWaitlistBtn = t("waitingListBtnText", { ns: "translation" });
  const title = t("contactTitle", { ns: "translation" });
  const subTitle = t("contactSubTitle", { ns: "translation" });
  const startup = "Squib Ltd.";
  const place = t("contactPlace", { ns: "translation" });
  const desc = "Smart-up";
  const street = "Zentralstrasse 9";
  const zipCity = "CH-6002 Luzern";
  const tel = "+41 41 511 46 76";
  const email = "hello@squib.app";

  const explore = t("explore", { ns: "translation" });
  const pricingFeatures = t("pricingFeatures", { ns: "translation" });
  const integration = t("integration", { ns: "translation" });
  const releases = t("releases", { ns: "translation" });
  const product = t("product", { ns: "translation" });
  const app = "App ";

  const useCases = t("useCases", { ns: "translation" });
  const successStories = t("successStories", { ns: "translation" });
  const comparison = t("comparison", { ns: "translation" });
  const security = t("security", { ns: "translation" });
  const discover = t("discover", { ns: "translation" });

  const contact = t("contact", { ns: "translation" });
  const imprintPrivacy = t("imprintPrivacy", { ns: "translation" });
  const FAQHelp = t("FAQHelp", { ns: "translation" });
  const jobs = t("jobs", { ns: "translation" });
  const media = t("media", { ns: "translation" });
  const resources = t("resources", { ns: "translation" });

  const handleClick = () => setClick(!click);

  const myHamburgerIcon = () => {
    return (
      <>
        <div className="open-close-icon">
          {click ? <span className="open"></span> : <span></span>}
          {click ? <span className="open"></span> : <span></span>}
          {click ? <span className="open"></span> : <span></span>}
        </div>
      </>
    );
  };

  const handleButtonClick = () => {
    setClick(false);
    setShouldScroll(true);
    navigate("/");
  };

  useEffect(() => {
    if (shouldScroll) {
      handleBtnScrollVoting();
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  return (
    <div className="menu-header-container">
      <div className="nav-mobile-box">
        <Link to="/" className="squib-logo-mobile-wrapper" onClick={() => setClick(false)}>
          <img src={images.squibLogoBlack} className="squib-logo" alt="Squib logo" />
        </Link>
        <div className="hamburger-icon-wrapper" onClick={handleClick}>
          {myHamburgerIcon()}
        </div>
      </div>
      {click ? (
        <>
          <div className="nav-menu-mobile-wrapper">
            <div className="nav-menu-mobile-cont">
              <div className="nav-menu-mobile-box">
                <Link to="/" className="squib-logo-mobile-menu-wrapper" onClick={() => setClick(false)}>
                  <img src={images.squibLogoBlack} className="squib-logo" alt="Squib logo" />
                </Link>
              </div>
              <ul className="nav-menu-mobile">
                <Link to="/erkunden" className="nav-item-mobile" onClick={() => setClick(false)}>
                  <p className="nav-links-mobile">{menuOne}</p>
                  {/*{isOpenProduct && (
                    <div>
                      <ul>
                        <li>
                          <Link to="/erkunden" onClick={() => setClick(false)}>
                            {explore}
                          </Link>
                        </li>
                        <li>
                          <Link to="/preise-funktionen" onClick={() => setClick(false)}>
                            {pricingFeatures}
                          </Link>
                        </li>
                        <li>
                          <Link to="/integrationen" onClick={() => setClick(false)}>
                            {integration}
                          </Link>
                        </li>
                        <li>
                          <Link to="/releases" onClick={() => setClick(false)}>
                            {releases}
                          </Link>
                        </li>
                        <li>
                          <Link to="/#" onClick={() => setClick(false)}>
                            {app}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}*/}
                </Link>
                <Link to="/anwendungsbeispiele" className="nav-item-mobile">
                  <p className="nav-links-mobile active" onClick={() => setClick(false)}>
                    {menuTwo}
                  </p>
                  {/*{isOpenDiscover && (
                    <ul>
                      <li>
                        <Link to="/anwendungsfalle" onClick={() => setClick(false)}>
                          {useCases}
                        </Link>
                      </li>
                      <li>
                        <Link to="/erfolgsgeschichten" onClick={() => setClick(false)}>
                          {successStories}
                        </Link>
                      </li>
                      <li>
                        <Link to="/vergleich" onClick={() => setClick(false)}>
                          {comparison}
                        </Link>
                      </li>
                      <li>
                        <Link to="/sicherheit" onClick={() => setClick(false)}>
                          {security}
                        </Link>
                      </li>
                    </ul>
                  )}*/}
                </Link>
                <Link to="/kontakt" className="nav-item-mobile" onClick={() => setClick(false)}>
                  <p className="nav-links-mobile">{menuThree}</p>
                  {/*{isOpenResources && (
                    <ul>
                      <li>
                        <Link to="/kontakt" onClick={() => setClick(false)}>
                          {contact}
                        </Link>
                      </li>
                      <li>
                        <Link to="/impressum-datenschutz" onClick={() => setClick(false)}>
                          {imprintPrivacy}
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq-hilfe" onClick={() => setClick(false)}>
                          {FAQHelp}
                        </Link>
                      </li>
                      <li>
                        <Link to="/jobs" onClick={() => setClick(false)}>
                          {jobs}
                        </Link>
                      </li>
                      <li>
                        <Link to="/medien" onClick={() => setClick(false)}>
                          {media}
                        </Link>
                      </li>
                    </ul>
                  )}*/}
                </Link>

                <Link to="/" className="nav-menu-mobile-btn-wrapper" onClick={handleButtonClick}>
                  <CustomButtonTwo btnText={mobileWaitlistBtn} className="nav-menu-mobile-btn" />
                </Link>
              </ul>
            </div>
            {/*<div className="CV-mobile-btn-wrapper">
              <CommonButton textOne="Create a voting" className="CV-mobile-btn" />
                </div>*/}
            {/*<div className="footer-contact-mobile-wrapper">
              <ContactUs
                title={title}
                subTitle={subTitle}
                startup={startup}
                place={place}
                desc={desc}
                street={street}
                zip={zipCity}
                tel={tel}
                email={email}
              />
            </div>*/}
            <div className="mobile-translation-policy-wrapper">
              <div className="policy-mobile-btn-wrapper">
                <Policy className="policy-mobile-btn hidde" onClick={() => setClick(false)} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NavHeaderMobile;
