import React from "react";

const SquibIntegrate = () => {
  const title = "HOW TO INTEGRATE SQUIB.";
  const description =
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. (Single & Multi Votings)";

  return (
    <div className="squib-integrate-container">
      <div className="squib-integrate-wrapper">
        <h2 className="squib-integrate-title">{title}</h2>
        <p className="squib-integrate-description">{description}</p>
      </div>
      <div className="squib-integrate-image-wrapper"></div>
    </div>
  );
};

export default SquibIntegrate;
