import React from "react";

interface ComparisonLayoutProps {
  title: string;
  description: string;
}

const ComparisonAreasLayout = (props: ComparisonLayoutProps) => {
  return (
    <div className="comparison-layout-container">
      <div className="comparison-layout-wrapper">
        <p className="comparison-layout-title">{props.title}</p>
        <p className="comparison-layout-description">{props.description}</p>
      </div>
    </div>
  );
};

export default ComparisonAreasLayout;
