import React from "react";

interface MediaCompProps {
  className?: string;
}

const MediaComp = (props: MediaCompProps) => {
  return (
    <div className={`media-comp-container ${props.className}`}>
      <video className={`media-comp-video ${props.className}`} controls>
        <source src="./video/squib-pro-event.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default MediaComp;
