import React from "react";

interface SecurityLayoutProps {
  title: string;
  description: string;
}

const SecurityLayout = (props: SecurityLayoutProps) => {
  return (
    <div className="security-common-layout__container">
      <div className="security-common-layout__box"></div>
      <div className="security-layout__header">
        <h2 className="security-layout__header-title">{props.title}</h2>
        <p className="security-layout__header-description">{props.description}</p>
      </div>
    </div>
  );
};

export default SecurityLayout;
