import React from "react";

interface ReleasesLayoutProps {
  title: string;
  description: string;
  date: string;
  versions: string[];
}

const ReleasesLayout = (props: ReleasesLayoutProps) => {
  return (
    <div className="releases-common-layout__container">
      <div className="releases-common-layout__wrapper">
        <div className="releases-date-versions-wrapper">
          <div className="releases-date-wrapper">
            <p className="releases-date">{props.date}</p>
          </div>
          <div className="date-versions-separator"></div>
          {props.versions.map((version, index) => {
            return (
              <div key={index} className="releases-versions-wrapper">
                <p className="releases-versions">{version}</p>
              </div>
            );
          })}
        </div>
        <div className="releases-layout-header-wrapper">
          <h2 className="releases-layout__header-title">{props.title}</h2>
          <p className="releases-layout__header-description">{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ReleasesLayout;
