import React from "react";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";

const SecurityHeader = () => {
  const title = "Security";
  const useCasesSubTitle =
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.";
  return (
    <div className="security-header-container">
      <div className="security-header-wrapper">
        <HeaderTitleComp title={title} subTitle={useCasesSubTitle} />
      </div>
    </div>
  );
};

export default SecurityHeader;
