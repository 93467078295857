import React from "react";
import ComparisonAreasLayout from "../layout/ComparisonAreasLayout";

const AreaOne = () => {
  const title = "A/B-Methode";
  const description =
    "Das Prinzip von Squib ist immer gleich, A oder B. Deshalb ist es um so einfacher, daran teilzunehmen, statt sich auf einer Skala von 1 bis 10 eine passende Antwort überlegen zu müssen.";
  return <ComparisonAreasLayout title={title} description={description} />;
};

export default AreaOne;
