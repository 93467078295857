import React from "react";
import IngrationOne from "./integration-one/IntegrationOne";
import IntegrationTwo from "./integration-two/IntegrationTwo";
import IntegrationThree from "./integration-three/IntegrationThree";

const IntegrationOptions = () => {
  return (
    <div className="integration-option-container">
      <IngrationOne />
      <IntegrationTwo />
      <IntegrationThree />
    </div>
  );
};

export default IntegrationOptions;
