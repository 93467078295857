import React from "react";
import MetaData from "../common/metadata/MetaData";
import MediaHeaderText from "./media-header-text/MediaHeaderText";
import GetInTouchMedia from "./getintouch-media/GetInTouchMedia";
import "../../../../resources/styles/components/media-components/media.css";
import { useTranslation } from "react-i18next";

const Media = () => {
  const { t } = useTranslation("translation");
  const mdText = t("media", { ns: "translation" });

  const styleParagraph = {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center" as const,
    alignItems: "center" as const,
    margin: "5rem 0",
  };

  const paragraphStyle = {
    margin: "3.5rem 0",
    fontSize: "1.875rem",
    fontFamily: "PublicaSans-Regular",
    fontWeight: 700,
    color: "#2a2a2a",
  };

  return (
    <>
      <MetaData title={mdText} />
      <MediaHeaderText />
      <div style={styleParagraph}>
        <p style={paragraphStyle}>Press realeases</p>
        <p style={paragraphStyle}>Media coverage</p>
        <p style={paragraphStyle}>Downloads</p>
      </div>
      <GetInTouchMedia />
    </>
  );
};

export default Media;
