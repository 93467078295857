import React from "react";
import TestimonialsSuccessStoriesLayout from "../../../common/testimonials-layout-comp/TestimonialsLayout";
import images from "../../../../../../resources/images";

const TestimonialFour = () => {
  return (
    <TestimonialsSuccessStoriesLayout
      brandLogo={images.benedict}
      description="«Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.»"
      avatar={images.mike}
      authorName="Mike"
      authorTitle="Head Partnerships"
    />
  );
};

export default TestimonialFour;
