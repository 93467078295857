import React from "react";
import HeaderTitleComp from "../../common/header-title-comp/HeaderTitleComp";
import { useTranslation } from "react-i18next";

const ComparisonHeaderText = () => {
  const { t } = useTranslation("translation");
  const titleHeader = t("comparison", { ns: "translation" });
  const description =
    "Umfrage- und Voting-Tools gibt es viele, doch wie unterscheidet sich Squib von etablierten Tools wie Typeform, SurveyMonkey oder Mentimeter? Erfahre es hier.";

  return (
    <div className="comparison-header-container">
      <div className="comparison-header-wrapper">
        <HeaderTitleComp title={titleHeader} subTitle={description} />
      </div>
    </div>
  );
};

export default ComparisonHeaderText;
