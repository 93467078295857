import React from "react";
import TestimonialsSuccessStoriesLayout from "../../../common/testimonials-layout-comp/TestimonialsLayout";
import images from "../../../../../../resources/images";

const TestimonialTwo = () => {
  return (
    <TestimonialsSuccessStoriesLayout
      brandLogo={images.glore}
      description="«Mit Squib konnten wir direkt in unserem Store ermitteln, ob die Nachfrage nach einem grösseren Jeans-Sortiment besteht. Die Methode mit QR-Code erlaubte es uns, bei unserer Zielgruppe vor Ort Feedback einzuholen.»"
      avatar={images.rebekka}
      authorName="Rebekka"
      authorTitle="Geschäftsführerin"
    />
  );
};

export default TestimonialTwo;
