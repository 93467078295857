import React from "react";
import ComparisonHeaderText from "./comparison-header-text/ComparisonHeaderText";
import ComparisonAreas from "./comparison-areas/ComparisonAreas";
import MarketEnvPosition from "./market-env-position/MarketEnvPosition";
import IndividualFunctions from "./individual-functions/IndividualFunctions";
import GetInTouchComparison from "./getintouch-comparison/GetInTouchComparison";
import "../../../../resources/styles/components/comparison-components/comparison.css";
import MetaData from "../common/metadata/MetaData";
import { useTranslation } from "react-i18next";

const Comparison = () => {
  const { t } = useTranslation("translation");
  const mdText = t("comparison", { ns: "translation" });

  return (
    <>
      <MetaData title={mdText} />
      <ComparisonHeaderText />
      <ComparisonAreas />
      <MarketEnvPosition />
      <IndividualFunctions />
      <GetInTouchComparison />
    </>
  );
};

export default Comparison;
