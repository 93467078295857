import React from "react";
import "../../../../../resources/styles/components/useCases-components/survey-employees.css";
import CardUseCasesLayout from "../../common/card-layout/CardUseCasesLayout";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import images from "../../../../../resources/images";

interface SurveyEmployeesProps {
  title: string;
  subTitle: string;
}

const SurveyEmployees = (props: SurveyEmployeesProps) => {
  const { t } = useTranslation("translation");
  const cardTwoHeader = t("useCasesSecondCardHeader", { ns: "translation" });
  const cardOneText = "eher ja";
  const cardTwoText = "eher nein";
  const spanTextOne = "80/80";
  const spanTextTwo = "80/80";

  return (
    <div className="fading-scroll-animation">
      <div className="survey-employees-container fading-scroll-animation">
        <img src={images.example} alt="mobile voting card two" className="mobile-voting-card-two" />

        {/*<CardUseCasesLayout
          id="surveyEmployeesID"
          className="inner-card-one-se-text card-one-se-text"
          classNameTwo="inner-card-two-se-text"
          headerTextStyles="card-header-se-text"
          backgroundCardOne="card-one-se"
          backgroundCardTwo="card-two-se"
          thumbUpImage="thumb-up-se"
          thumbDownImage="thumb-down-se"
          cardHeaderText={cardTwoHeader}
          cardOneText={cardOneText}
          cardTwoText={cardTwoText}
          spanTextOne={spanTextOne}
          spanTextTwo={spanTextTwo}
          numWrapperOne="num-wrapper-one-se"
          numWrapper="num-wrapper-se"
          funnyImgOneCardClassWrapper="funny-img-one-card-se"
          funnyImgTwoCardClassWrapper="funny-img-two-card-se"
          classLines={["line-one-se", "line-two-se", "line-three-se", "line-four-se", "line-five-se"]}
        /> */}
        <motion.div
          initial={{ opacity: 0, y: 75 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2, delay: 2 }}
          className="survey-employees-wrapper"
        >
          <h1>{props.title}</h1>
          <p>{props.subTitle}</p>
        </motion.div>
      </div>
    </div>
  );
};

export default SurveyEmployees;
